import { AutoformatRule } from "@udecode/plate-autoformat"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import { ELEMENT_CODE_BLOCK, insertEmptyCodeBlock } from "@udecode/plate-code-block"
import { ELEMENT_DEFAULT, insertNodes, setNodes } from "@udecode/plate-common"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"

import { preFormat } from "./autoformatUtils"

export const autoformatBlocks: AutoformatRule[] = [
    {
        mode: "block",
        type: ELEMENT_H1,
        match: "# ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H2,
        match: "## ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H3,
        match: "### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H4,
        match: "#### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H5,
        match: "##### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H6,
        match: "###### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_BLOCKQUOTE,
        match: "> ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_CODE_BLOCK,
        match: "```",
        triggerAtBlockStart: false,
        preFormat,
        format: (editor) => {
            insertEmptyCodeBlock(editor, {
                defaultType: ELEMENT_DEFAULT,
                insertNodesOptions: { select: true },
            })
        },
    },
    {
        mode: "block",
        type: ELEMENT_HR,
        match: ["---", "—-", "___ "],
        format: (editor) => {
            setNodes(editor, { type: ELEMENT_HR })
            insertNodes(editor, {
                type: ELEMENT_DEFAULT,
                children: [{ text: "" }],
            })
        },
    },
]
