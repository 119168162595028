import { remarkDefaultTextRules, remarkTransformNode } from "@udecode/plate-serializer-md"
import markdown from "remark-parse"
import { unified } from "unified"
import { createPlatePlugins } from "../plugins"
import { createMyPlateEditor } from "../types"
import { remarkDefaultElementRules } from "./remarkElementRules"

export const deserializeMd = (data: string) => {
    const editor = createMyPlateEditor({ plugins: createPlatePlugins({}) })

    const tree: any = unified()
        .use(markdown)
        .use(remarkPlugin, {
            editor,
            elementRules: remarkDefaultElementRules,
            textRules: remarkDefaultTextRules,
        })
        .processSync(data)

    return tree.result
}

export function remarkPlugin(options) {
    const compiler = (node: { children }) => {
        return node.children.flatMap((child) => remarkTransformNode(child, options))
    }

    // @ts-ignore
    this.Compiler = compiler
}
