import { Box, Grid, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { useDatabase, withObservables } from "@nozbe/watermelondb/react"
import { OutgoingLinkIcon } from "components/shared/icons/LinkIcons/OutgoingLinkIcon"
import { compact, map, orderBy } from "lodash"
import { FC, memo, useEffect, useState } from "react"
import { ConnectionModel, ConnectionPropertyModel, ItemModel } from "storage/watermelon/models"
import { itemRepository } from "storage/watermelon/repository"
import { Link } from "./Link"

export const LINKS_DIV_ID = "id-links"

interface Props {
    itemId: string
    connections: ConnectionModel[]
    readOnly?: boolean
}

interface LinkItem {
    id: string
    item: ItemModel
    property: ConnectionPropertyModel | null
    connection: ConnectionModel
}

interface LinkGroup {
    key: string
    value: LinkItem[]
}

const LinksComp: FC<Props> = ({ connections, readOnly = false }) => {
    const db = useDatabase()

    const [groupedLinks, setGroupedLinks] = useState<{ key: string; value: LinkItem[] }[]>([])

    const getLinks = async () => {
        const itemIds = compact(map(connections, (connection) => connection.to.id))

        const [items] = await Promise.all([
            itemIds.length ? itemRepository.getByItemIds(db, itemIds) : [],
        ])

        const links = connections.map((connection) => {
            const link: LinkItem = { id: connection.id, item: null, property: null, connection }

            if (connection.to.id) {
                link.item = items.find((item) => item.id === connection.to.id) || null
            }

            return link
        })

        return compact(links)
    }

    const getGroupedLinks = async () => {
        const links = await getLinks()

        return await groupByTags(links)
    }

    const groupByTags = async (links: LinkItem[]): Promise<Record<string, LinkItem[]>> => {
        const linksTags = await Promise.all(links.map((link) => link.item.tags.fetch()))

        const groupedLinks: Record<string, LinkItem[]> = {}

        linksTags.flat().forEach((tag) => {
            groupedLinks[tag.name] = []
        })

        for (const [index, link] of links.entries()) {
            const tags = linksTags[index]

            if (!tags.length) {
                groupedLinks[""] = groupedLinks[""] || []
                groupedLinks[""].push(link)
                continue
            }

            for (const tag of tags) {
                groupedLinks[tag.name].push(link)
            }
        }

        return groupedLinks
    }

    const sortLinksByMentions = async (links: LinkItem[]) => {
        const linkMentionsCount = await Promise.all(
            links.map(async (link) => ({ link, count: await link.item.mentions.fetchCount() }))
        )

        return map(orderBy(linkMentionsCount, "count", "desc"), "link")
    }

    const sortGroupLinks = async (
        groupedLinks: Record<string, LinkItem[]>
    ): Promise<LinkGroup[]> => {
        return await Promise.all(
            Object.keys(groupedLinks).map(async (groupKey) => {
                const links = groupedLinks[groupKey]
                const orderedLinks = await sortLinksByMentions(links)

                return {
                    key: groupKey,
                    value: orderedLinks,
                }
            })
        )
    }

    const sortGroups = async (linkGroups: LinkGroup[]) => {
        return linkGroups.sort((a, b) => {
            if (a.key === "" || b.key === "") return a.key === "" ? -1 : 1

            const lengthCompare = b.value.length - a.value.length
            if (lengthCompare === 0) return a.key.localeCompare(b.key)

            return lengthCompare
        })
    }

    const setLinks = async () => {
        const groupedLinks = await getGroupedLinks()
        const sortedGroupLinks = await sortGroupLinks(groupedLinks)
        const sortedGroups = await sortGroups(sortedGroupLinks)
        setGroupedLinks(sortedGroups)
    }

    useEffect(() => {
        setLinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connections])

    if (!groupedLinks.length) return null

    return (
        <Grid xs={12} item sx={styles.root} alignItems={"center"}>
            <Box id={LINKS_DIV_ID} mb={1} display="flex" alignItems="center" gap={1}>
                <Tooltip
                    title={`This card links to ${connections.length} other card${
                        connections.length > 1 ? "s" : ""
                    }`}
                >
                    <Box>
                        <OutgoingLinkIcon count={connections.length} />
                    </Box>
                </Tooltip>
                <Tooltip
                    sx={{ cursor: "default" }}
                    title="These are the links from this card to other cards"
                >
                    <Typography variant="h5">Links</Typography>
                </Tooltip>
            </Box>
            <Grid container item spacing={0} sx={{ borderRadius: 1, overflow: "hidden" }}>
                {groupedLinks.map((sortedGroup) => (
                    <Grid container item sx={styles.linkGroup} key={sortedGroup.key}>
                        <Grid item xs={12}>
                            <Typography sx={styles.linkGroupTitle} variant="subtitle1">
                                {sortedGroup.key}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {sortedGroup.value.map((link: LinkItem) => (
                                <Link
                                    readOnly={readOnly}
                                    key={link.id}
                                    item={link.item}
                                    connection={link.connection}
                                />
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

const enhance = withObservables(["connections"], ({ connections }) => ({
    connections,
}))

export const Links = memo(enhance(LinksComp))

const styles: Record<string, SxProps<Theme>> = {
    root: {
        pt: 2,
    },
    select: {
        minWidth: "180px",
    },
    menu: {
        "&.MuiMenu-paper": {
            backgroundColor: "blue",
        },
        "& .MuiList-root": {
            pt: 0,
            pb: 0,
        },
    },
    linkGroupTitle: {
        mb: 1,
    },
    linkGroup: {
        mb: 0.2,
        backgroundColor: "background.default",
        p: (theme) => theme.spacing(0.5, 1),
    },
}
