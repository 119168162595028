import { Database, Q } from "@nozbe/watermelondb"
import { Item, ItemPartial, Source } from "@recall/common"
import { map } from "lodash"
import { sourceRepository, tagRepository } from "."
import { ItemModel } from "../models"
import { ITEMS } from "../schema"

interface SearchQueryParams {
    db: Database
    search: string
    includeRefs: boolean
    tags: string[]
    allNestChildTypeIds?: string[]
}

const observe = (db: Database) => {
    return db.collections.get<ItemModel>(ITEMS).query().observe()
}

const observeCount = (db: Database, isSaved = true, isReference?: boolean) => {
    if (isReference !== undefined) {
        return db.collections
            .get<ItemModel>(ITEMS)
            .query(Q.and(Q.where("is_saved", isSaved), Q.where("is_reference", isReference)))
            .observeCount()
    }

    return db.collections.get<ItemModel>(ITEMS).query(Q.where("is_saved", isSaved)).observeCount()
}

interface GetByTagsProps {
    db: Database
    includeRefs: boolean
    tags: string[]
}

const getByTags = async ({ db, includeRefs, tags }: GetByTagsProps) => {
    let query = db.collections
        .get<ItemModel>(ITEMS)
        .query(Q.where("is_saved", true), Q.sortBy("updated_at", "desc"))

    if (!includeRefs) {
        query = query.extend(Q.where("is_reference", false))
    }

    if (tags.length) {
        const itemTags = await tagRepository.getItemTagsByTagIds(db, tags)
        const itemIds = map(itemTags, (itemTag) => itemTag.item.id || null)
        query = query.extend(Q.where("id", Q.oneOf(itemIds)))
    }

    return query.fetch()
}

const getBySearchParams = async ({ db, search, includeRefs, tags }: SearchQueryParams) => {
    let query = db.collections
        .get<ItemModel>(ITEMS)
        .query(
            Q.where("name", Q.like(`%${Q.sanitizeLikeString(search)}%`)),
            Q.where("is_saved", true),
            Q.sortBy("updated_at", "desc")
        )

    if (!includeRefs) {
        query = query.extend(Q.where("is_reference", false))
    }

    if (tags.length) {
        const itemTags = await tagRepository.getItemTagsByTagIds(db, tags)
        const itemIds = map(itemTags, (itemTag) => itemTag.item.id || null)
        query = query.extend(Q.where("id", Q.oneOf(itemIds)))
    }

    return query.fetch()
}

const postFilterSearchItems = (items: ItemModel[], searchTerm: string, limit?: number) => {
    if (!searchTerm) {
        return items.sort((a, b) => b.createdAt - a.createdAt).slice(0, limit)
    }

    searchTerm = searchTerm.toLowerCase()

    const filteredItems = items.filter((item) => {
        const lowerCaseName = item.name.toLowerCase()

        return lowerCaseName.startsWith(searchTerm) || lowerCaseName.includes(` ${searchTerm}`)
    })

    return filteredItems.sort((a, b) => b.createdAt - a.createdAt).slice(0, limit)
}

const search = async (db: Database, search: string, includeRefs: boolean, limit?: number) => {
    let items: ItemModel[] = await getBySearchParams({
        db,
        search,
        includeRefs,
        tags: [],
    })

    items = postFilterSearchItems(items, search, limit)
    return items
}

const getByItemIds = (db: Database, itemIds: string[]) => {
    return db.collections
        .get<ItemModel>(ITEMS)
        .query(Q.where("id", Q.oneOf(itemIds)))
        .fetch()
}

const getBySources = async (
    db: Database,
    sources: Omit<Source, "id">[],
    onlySaved = false
): Promise<ItemModel | null> => {
    for (const source of sources) {
        const item = await getBySource({
            db,
            sourceIdentifier: source.identifier,
            sourceName: source.name,
        })
        if (!item || (onlySaved && !item?.isSaved)) continue

        return item
    }
    return null
}

const getItemsBySourceIdentifiers = async (db: Database, identifiers: string[]) => {
    const sources = await sourceRepository.getByIds(db, identifiers)
    return await Promise.all(sources.map((source) => source.item.fetch()))
}

interface GetBySourceProps {
    db: Database
    sourceIdentifier: string
    sourceName?: string | null
}

const getBySource = async ({ db, sourceIdentifier, sourceName }: GetBySourceProps) => {
    let source = await sourceRepository.get(db, sourceIdentifier, sourceName)

    if (!source) return null

    return source.item.fetch()
}

const getOrCreate = (db: Database, item: Item | ItemPartial): Promise<ItemModel> => {
    return db.write<ItemModel>(async (writer) => {
        for (const source of item.sources) {
            const itemModel = await getBySource({
                db,
                sourceIdentifier: source.identifier,
                sourceName: source.name,
            })

            if (!itemModel) continue

            return itemModel
        }

        const existingItem = await get(db, item.id)

        if (existingItem) return existingItem

        return await writer.callWriter(() => createFull(db, item))
    })
}

const createFull = (db: Database, item: Item | ItemPartial): Promise<ItemModel> => {
    return db.write<ItemModel>(async (writer) => {
        const itemModel = await writer.callWriter(() => create(db, item, item.isSaved))

        await writer.callWriter(() => itemModel.addEditorBlocks(item.editorBlocks, item.isSaved))
        await writer.callWriter(() => itemModel.addSources(item.sources, item.isSaved))

        return itemModel
    })
}

const create = async (db: Database, item: Partial<Item>, isSaved = false): Promise<ItemModel> => {
    return await db.write<ItemModel>(async () => {
        const itemModel = await db.collections.get<ItemModel>(ITEMS).create((record) => {
            record._raw.id = item.id
            record.name = item.name
            record.isReference = item.isReference
            record.isSaved = isSaved
            record.isExpanded = item.isExpanded || false
            record.language = item.language
            record.image = item.image
            record.description = item.description
        })
        return itemModel
    })
}

const observeById = (db: Database, itemId: string) => {
    return db.collections.get<ItemModel>(ITEMS).findAndObserve(itemId)
}

const get = async (db: Database, itemId: string) => {
    try {
        return await db.collections.get<ItemModel>(ITEMS).find(itemId)
    } catch (e) {
        return null
    }
}

const getByIds = async (db: Database, ids: string[]) => {
    return await db.collections
        .get<ItemModel>(ITEMS)
        .query(Q.where("id", Q.oneOf(ids)))
        .fetch()
}

const deleteUnsaved = async (db: Database) => {
    await db.write(async (writer) => {
        const unsavedItems = await db.collections
            .get<ItemModel>(ITEMS)
            .query(Q.where("is_saved", false))
            .fetch()

        let tasks = (
            await Promise.all(unsavedItems.map(async (i) => await i.prepareDelete()))
        ).flat()
        tasks = tasks.filter((a) => !!a)

        const uniqueTasks = Array.from(new Set(tasks.map((a) => a.id))).map((id) => {
            return tasks.find((a) => a.id === id)
        })

        await writer.batch(...uniqueTasks)
    })
}

const deleteStale = async (db: Database) => {
    let items = await db.collections
        .get<ItemModel>(ITEMS)
        .query(Q.where("is_reference", true))
        .fetch()

    for (const item of items) {
        const isStale = await item.isStale()
        if (!isStale) continue

        await item.delete()
    }
}

const getCountByTypeIds = async (db: Database, typeIds: string[], inlcudeReferences: boolean) => {
    let query = db.collections
        .get<ItemModel>(ITEMS)
        .query(Q.where("type_id", Q.oneOf(typeIds)), Q.where("is_saved", true))

    if (inlcudeReferences === false) {
        query = query.extend(Q.where("is_reference", false))
    }

    return await query.fetchCount()
}

const getQueryAll = (db: Database, includeReferences: boolean) => {
    let query = db.collections.get<ItemModel>(ITEMS).query()

    if (includeReferences) {
        query = query.extend(Q.where("is_reference", true))
    } else {
        query = query.extend(Q.where("is_reference", false))
    }

    return query
}

const getQuery = (db: Database, references: boolean) => {
    let query = db.collections.get<ItemModel>(ITEMS).query(Q.where("is_saved", true))

    if (references === true) {
        query = query.extend(Q.where("is_reference", true))
    } else {
        query = query.extend(Q.where("is_reference", false))
    }

    return query
}

const getOrdered = (db: Database, references: boolean) => {
    if (!references) {
        return db.collections
            .get<ItemModel>(ITEMS)
            .query(Q.and(Q.where("is_saved", true), Q.where("is_reference", false)))
            .fetch()
    } else {
        return db.collections
            .get<ItemModel>(ITEMS)
            .query(Q.and(Q.where("is_saved", true)))
            .fetch()
    }
}

const getCount = (db: Database, references: boolean) => {
    let query = getQuery(db, references)
    return query.fetchCount()
}

const getAll = async (db: Database) => {
    return await db.collections.get<ItemModel>(ITEMS).query().fetch()
}

const getUntaggedItems = async (db: Database): Promise<ItemModel[]> => {
    let query = db.collections.get<ItemModel>(ITEMS).query(Q.where("is_saved", true))

    const items = await query.fetch()

    const untaggedItems = await Promise.all(
        items.map(async (item) => {
            const tagsCount = await item.tags.count
            return tagsCount === 0 ? item : false
        })
    )

    return untaggedItems.filter(Boolean) as ItemModel[]
}

export const itemRepository = {
    observe,
    observeById,
    observeCount,
    get,
    getQuery,
    getQueryAll,
    getByItemIds,
    getBySearchParams,
    getBySources,
    getBySource,
    getByIds,
    getCount,
    getCountByTypeIds,
    search,
    getOrCreate,
    createFull,
    create,
    deleteUnsaved,
    deleteStale,
    getAll,
    getUntaggedItems,
    getItemsBySourceIdentifiers,
    getOrdered,
    getByTags,
}
