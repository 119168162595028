var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const QUESTIONS_PATH = "/questions/";
export class QuestionsApi {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    generateQuestions(text, existingQuestions, headers) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.httpClient.post(QUESTIONS_PATH, JSON.stringify({
                    text,
                    model: "gpt-4",
                    existing_questions: existingQuestions || null,
                }), headers);
                return yield response.json();
            }
            catch (_a) {
                return null;
            }
        });
    }
}
