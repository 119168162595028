import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, Typography } from "@mui/material";
import CountBadge from "./CountBadge";
export const TotalCountBadge = (props) => {
    let count = props.count;
    let tooltipTitle = (_jsxs(Typography, Object.assign({ variant: "body2" }, { children: [_jsx("b", { children: props.name }), " is mentioned by ", _jsx("b", { children: props.count }), " card", props.count > 1 ? "s" : ""] })));
    if (props.onlyOtherMentionCount) {
        count = props.count - 1;
        tooltipTitle = (_jsxs(Typography, Object.assign({ variant: "body2" }, { children: [_jsx("b", { children: props.name }), " is mentioned by ", _jsxs("b", { children: [count, " other "] }), " card", count > 1 ? "s" : ""] })));
    }
    if (count < 1) {
        return null;
    }
    return (_jsx(Tooltip, Object.assign({ title: tooltipTitle }, { children: _jsx("span", { children: _jsx(CountBadge, { count: count, color: "primary" }) }) })));
};
