import { AppBar, Box, SxProps, Theme, Toolbar, Typography } from "@mui/material"
import { HOME_PATH, KNOWLEDGE_GRAPH_PATH, SPACED_REPETITION } from "constants/routes"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsNative } from "hooks/useIsNative"
import { memo } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { RootState } from "storage/redux/rootReducer"
import AppMenu from "../AppMenu/AppMenu"

const navigation = [
    { label: "Home", path: HOME_PATH },
    { label: "Graph", path: KNOWLEDGE_GRAPH_PATH, isDesktopOnly: true },
    { label: "Review", path: SPACED_REPETITION },
]

export const Navigation = memo(() => {
    const history = useHistory()
    const location = useLocation()
    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)
    const isMobile = useIsMobile()
    const isNative = useIsNative()

    if (isAppLoading) return null

    return (
        <>
            <AppBar sx={styles.appBar} elevation={0}>
                <Toolbar sx={styles.toolbar}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        {navigation.map(({ label, path, isDesktopOnly }) => {
                            if (isDesktopOnly && (isMobile || isNative)) return null

                            return (
                                <Typography
                                    key={path}
                                    onClick={() => history.push(path)}
                                    noWrap
                                    component="div"
                                    variant="body2"
                                    sx={{
                                        ...styles.navItem,
                                        background: (theme) =>
                                            location.pathname.includes(path)
                                                ? theme.palette.action.disabledBackground
                                                : "",
                                    }}
                                >
                                    {label}
                                </Typography>
                            )
                        })}
                    </Box>
                    <AppMenu />
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ minHeight: "40px !important" }} />
        </>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    navItem: {
        p: 0.4,
        px: 0.8,
        cursor: "pointer",
        borderRadius: 0.5,
        color: (theme) => theme.palette.text.primary,
    },
    toolbar: {
        minHeight: "40px !important",
        display: "flex",
        justifyContent: "space-between",
        pr: "8px !important",
        pl: "12px !important",
    },
    appBar: {
        position: "fixed",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.background.default,
        borderBottom: (theme) => `1px solid ${theme.palette.action.disabledBackground}`,
    },
}
