import { Button, ButtonProps, CircularProgress, alpha } from "@mui/material"
import { getTheme } from "@recall/common"
import { FC, PropsWithChildren } from "react"

interface BoldButtonProps extends ButtonProps {
    loading?: boolean
    size?: "small"
}

export const BoldButton: FC<PropsWithChildren<BoldButtonProps>> = ({
    sx = {},
    size,
    loading,
    startIcon,
    children,
    ...props
}) => {
    const isSmall = size === "small"
    const styles = makeStyles(isSmall)

    return (
        <Button
            translate="no"
            variant="contained"
            sx={{
                ...sx,
                ...styles,
            }}
            {...props}
            disabled={loading}
            startIcon={!loading && startIcon}
        >
            {loading ? <CircularProgress size={27} color="inherit" /> : children}
        </Button>
    )
}

const darkTheme = getTheme("dark")

const makeStyles = (isSmall: boolean) => ({
    px: 4,
    fontSize: !isSmall ? "1rem" : undefined,
    backgroundColor: !isSmall ? alpha(darkTheme.palette.text.primary, 0.15) : undefined,
    color: alpha("#FFF", 0.8),
    "&:hover": {
        backgroundColor: alpha(darkTheme.palette.text.primary, 0.1),
    },
})
