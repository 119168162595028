import { Database, Q } from "@nozbe/watermelondb"
import { MyRootBlock } from "components/ItemPage/components/editor/types"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { EditorBlockModel } from "../models"
import { EDITOR_BLOCKS } from "../schema"

const getEditorBlocksByItemId = (db: Database, itemId: string) => {
    return db.collections
        .get<EditorBlockModel>(EDITOR_BLOCKS)
        .query(Q.where("item_id", itemId))
        .fetch()
}

const get = async (db: Database, id: string): Promise<EditorBlockModel | null> => {
    try {
        return await db.collections.get<EditorBlockModel>(EDITOR_BLOCKS).find(id)
    } catch {
        return null
    }
}

const remove = async (db: Database, id: string): Promise<void> => {
    return await db.write(async (action) => {
        const editorBlock = await get(db, id)
        if (!editorBlock) return

        await action.callWriter(() => editorBlock.delete())
    })
}

const upsert = async (db: Database, itemId: string, editorBlock: MyRootBlock, isSaved = true) => {
    return await db.write(async () => {
        const editorBlockModel = await get(db, editorBlock.id)
        const { id, type, children, options, ...rest } = editorBlock
        const newOptions = { ...rest }
        const text = EditorBlockData.getFormattedText([editorBlock])

        if (editorBlockModel) {
            return editorBlockModel.update((record) => {
                record.type = type
                record.children = children
                record.options = newOptions || {}
                record.text = text
            })
        } else {
            return db.collections.get<EditorBlockModel>(EDITOR_BLOCKS).create((record) => {
                record._raw.id = editorBlock.id
                record.item.id = itemId
                record.children = children
                record.isSaved = isSaved
                record.type = type
                record.options = newOptions || {}
                record.text = text
            })
        }
    })
}

export const editorBlockRepository = {
    getEditorBlocksByItemId,
    upsert,
    remove,
    get,
}
