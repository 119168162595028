import {
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignLeft,
    FormatAlignRight,
} from "@mui/icons-material"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { useAlignDropdownMenu, useAlignDropdownMenuState } from "@udecode/plate-alignment"
import { useState } from "react"

const items = [
    {
        value: "left",
        icon: <FormatAlignLeft />,
    },
    {
        value: "center",
        icon: <FormatAlignCenter />,
    },
    {
        value: "right",
        icon: <FormatAlignRight />,
    },
    {
        value: "justify",
        icon: <FormatAlignJustify />,
    },
]

export const AlignToolbarButton = () => {
    const [anchorEl, setAnchorEl] = useState(null)

    const state = useAlignDropdownMenuState()
    const { radioGroupProps } = useAlignDropdownMenu(state)

    const currentAlignment = items.find((item) => item.value === radioGroupProps.value)

    return (
        <>
            <Tooltip title="Align">
                <IconButton
                    size={"small"}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}
                    sx={{
                        color: (theme) =>
                            Boolean(anchorEl)
                                ? theme.palette.text.primary
                                : theme.palette.action.active,
                        backgroundColor: (theme) =>
                            Boolean(anchorEl) && theme.palette.action.selected,
                        ":hover": {
                            backgroundColor: (theme) =>
                                Boolean(anchorEl) && theme.palette.action.selected,
                        },
                    }}
                >
                    {currentAlignment.icon}
                </IconButton>
            </Tooltip>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {items.map((item) => (
                    <MenuItem
                        key={item.value}
                        onClick={() => radioGroupProps.onValueChange(item.value)}
                    >
                        {item.icon}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
