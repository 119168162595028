import { Clear, Search } from "@mui/icons-material"
import { Box, InputBase, SxProps, Theme, alpha } from "@mui/material"
import { UseEmojiPickerType } from "@udecode/plate-emoji"

export type EmojiPickerSearchBarProps = Pick<
    UseEmojiPickerType,
    "i18n" | "searchValue" | "setSearch" | "clearSearch"
>

export function EmojiPickerSearch({
    i18n,
    searchValue,
    setSearch,
    clearSearch,
}: EmojiPickerSearchBarProps) {
    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.icon}>
                <Search />
            </Box>
            <InputBase
                autoFocus
                value={searchValue}
                onChange={(e) => setSearch(e.target.value)}
                sx={styles.input}
                placeholder={i18n.search}
                inputProps={{ "aria-label": "search" }}
                endAdornment={searchValue && <Clear onClick={clearSearch} sx={styles.close} />}
            />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        border: (theme) =>
            theme.palette.mode === "light"
                ? `1px solid ${theme.palette.grey[400]}`
                : `1px solid ${alpha(theme.palette.grey[500], 0.3)}`,
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.common.white, 0.25),
        },
        mt: 0.8,
        mb: 1,
        flex: 1,
    },
    icon: {
        paddingLeft: 0.9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (theme) => theme.palette.grey[400],
    },
    input: {
        color: "inherit",
        paddingLeft: (theme) => `calc(1em + ${theme.spacing(2.3)})`,
        width: "100%",
    },
    close: {
        mr: 1,
        cursor: "pointer",
    },
}
