import { useHistory, useLocation } from "react-router"

const QUERY_PARAM = "onboardingOpen"

export const useOpenOnboarding = () => {
    const history = useHistory()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const isOnboardingOpen = queryParams.has(QUERY_PARAM)

    const openOnboarding = () => {
        queryParams.set(QUERY_PARAM, "true")
        history.push({ search: queryParams.toString() })
    }

    const closeOnboarding = () => {
        queryParams.delete(QUERY_PARAM)
        history.push({ search: queryParams.toString() })
    }

    return { isOnboardingOpen, openOnboarding, closeOnboarding }
}
