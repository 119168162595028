import AddIcon from "@mui/icons-material/Add"
import { Chip, SxProps, Theme } from "@mui/material"
import { FC } from "react"

interface Props {
    setIsHidden: (isVisible: boolean) => void
}

export const TAG_INPUT_DIV_ID = "id-tag-input"

export const TagInputChip: FC<Props> = ({ setIsHidden }) => {
    const handleClick = () => {
        setIsHidden(true)
    }

    return (
        <Chip
            size="medium"
            icon={<AddIcon />}
            sx={styles}
            variant="outlined"
            label="Add tag"
            onClick={handleClick}
            data-testid="card-tag-add"
            id={TAG_INPUT_DIV_ID}
        />
    )
}

const styles: SxProps<Theme> = {
    borderStyle: "dashed",
    opacity: 0.8,
    cursor: "pointer",
    mb: 0.5,

    ":hover": {
        opacity: 1,
    },
}
