import { TEditableProps } from "@udecode/plate-common"

import { EditorElements } from "./types"

export const editableProps: TEditableProps<EditorElements> = {
    spellCheck: false,
    autoFocus: false,
    placeholder: "Type…",
    style: {
        outline: "none",
    },
}
