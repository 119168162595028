var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { languages } from "../../constants";
export class SummaryOptionsService {
    constructor(userRepository) {
        this.getLanguageByCode = (languageCode) => { var _a; return (_a = languages === null || languages === void 0 ? void 0 : languages[languageCode]) === null || _a === void 0 ? void 0 : _a.label; };
        this.getDefaultSummaryOptions = () => {
            return {
                language: "auto",
                searchLanguage: "en",
            };
        };
        this.getSummaryOptions = (uid) => __awaiter(this, void 0, void 0, function* () {
            const user = yield this.userRepository.getUser(uid);
            return (user === null || user === void 0 ? void 0 : user.summaryOptions) || this.getDefaultSummaryOptions();
        });
        this.updateSummaryOptions = (uid, options) => __awaiter(this, void 0, void 0, function* () {
            yield this.userRepository.upsertUser(uid, { summaryOptions: options });
        });
        this.userRepository = userRepository;
    }
}
