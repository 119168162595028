import { ExpandLess, ExpandMore, Verified } from "@mui/icons-material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {
    Box,
    Button,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { Spinner } from "@recall/common"
import { FC, useState } from "react"
import { useDispatch } from "react-redux"
import { SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"

export interface FailedBookmark {
    name: string
    url: string
}

interface Props {
    isLoading: boolean
    created: number
    existing: number
    count: number
    failedBookmarks: FailedBookmark[]
}

export const BookmarksLoader: FC<Props> = ({
    isLoading,
    created,
    existing,
    count,
    failedBookmarks,
}) => {
    const dispatch = useDispatch()
    const [failedOpen, setFailedOpen] = useState(false)
    const failed = failedBookmarks.length

    const handleClickOpenFailed = () => {
        setFailedOpen(!failedOpen)
    }

    const handleClose = () => {
        dispatch({
            type: SET_IMPORT_STATE,
            payload: isLoading ? importEnum.IMPORTING_CLOSED : importEnum.CLOSED,
        })
    }

    return (
        <Box sx={styles.container}>
            <Typography variant="h4" mb={4} p={2} sx={styles.title}>
                {isLoading ? "Importing bookmarks" : "Bookmarks are imported"}
            </Typography>
            <Box sx={styles.loader}>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Box mb={2}>
                        <Verified sx={{ width: 120, height: 120 }} color="secondary" />
                    </Box>
                )}
                <Box
                    display="flex"
                    flexDirection="column"
                    px={2}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Typography variant="body1" my={1}>
                        Created {created} of {count} cards.
                    </Typography>
                    {!!existing && (
                        <Typography variant="body1" my={1}>
                            {existing} {existing > 1 ? "cards" : "card"} already exist.
                        </Typography>
                    )}
                    {!!failed && (
                        <>
                            <Typography
                                sx={styles.failed}
                                variant="body1"
                                my={1}
                                onClick={handleClickOpenFailed}
                            >
                                Failed to create {failed} {failed > 1 ? "cards" : "card"}{" "}
                                {failedOpen ? (
                                    <ExpandLess fontSize="large" />
                                ) : (
                                    <ExpandMore fontSize="large" />
                                )}
                            </Typography>
                            <Collapse
                                sx={styles.collapse}
                                in={failedOpen}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List sx={styles.failedList}>
                                    {failedBookmarks.map((failedBookmark, index) => (
                                        <ListItemButton
                                            key={`${failedBookmark.url}-${index}`}
                                            component="a"
                                            href={failedBookmark.url}
                                            target="_blank"
                                            sx={styles.listItemButton}
                                        >
                                            <ListItemIcon>
                                                <OpenInNewIcon fontSize="small" />
                                            </ListItemIcon>
                                            {failedBookmark.name}
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    )}
                </Box>

                <Box mt={3}>
                    <Button onClick={handleClose} variant="outlined">
                        {!isLoading ? "Close" : "Minimize"}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    title: {
        textAlign: "center",
    },
    collapse: {
        width: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        flexDirection: "column",
        mb: 6,
    },
    failed: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textDecoration: "underline",
        textDecorationStyle: "dotted",
    },
    failedList: {
        maxHeight: 250,
        borderRadius: 1,
        width: "100%",
        overflow: "scroll",
        background: (theme) => theme.palette.background.layout,
        msOverflowStyle: "none" /* Internet Explorer 10+ */,
        scrollbarWidth: "none" /* Firefox */,
        "::-webkit-scrollbar": {
            display: "none" /* Safari and Chrome */,
        },
    },
    listItemButton: {
        mx: 2,
        borderRadius: 1,
    },
}
