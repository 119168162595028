import { Database } from "@nozbe/watermelondb"
import { reviewsScheduleRepository } from "@recall/common"
import { QuestionModel } from "storage/watermelon/models"
import {
    CreateProps,
    UpdateProps,
    questionRepository,
} from "storage/watermelon/repository/questionRepository"
import { EditorBlockData } from "./editorData/EditorBlockData"
import { ImageBlockData } from "./editorData/ImageBlockData"

export const QUESTIONS_PER_REVIEW_LIMIT = 10

const getQuestionsForReview = async (db: Database, limit = QUESTIONS_PER_REVIEW_LIMIT) => {
    const questions = await questionRepository.getQuestionsForReview(db)
    return { questions: questions.slice(0, limit), unreviewedCount: questions.length }
}

const getQuestionSchedulePayload = async (question: QuestionModel) => {
    const item = await question.item.fetch()
    const [editorBlocks, editorOrders] = await Promise.all([
        item.editorBlocks.fetch(),
        item.editorOrders.fetch(),
    ])
    const orderedEditorBlocks = EditorBlockData.getOrdered(editorBlocks, editorOrders[0])
    const imageBlock = EditorBlockData.getFirstImageBlock(orderedEditorBlocks)

    const itemPayload: { name: string; image?: string } = {
        name: item.name,
    }

    if (imageBlock) {
        const imageUrl =
            item.image || imageBlock?.options?.url || ImageBlockData.getUrl320(imageBlock)

        if (imageUrl) itemPayload.image = imageUrl
    }

    return {
        id: question.id,
        nextReviewTime: question.nextReviewTime,
        question: question.question,
        item: itemPayload,
    }
}

const createQuestionAndScheduleReview = async (uid: string, props: CreateProps) => {
    const createdQuestion = await questionRepository.create(props)

    const payload = await getQuestionSchedulePayload(createdQuestion)
    await reviewsScheduleRepository.upsert(uid, payload)
    return createdQuestion
}

const updateQuestionAndScheduleReview = async (uid: string, props: UpdateProps) => {
    const createdQuestion = await questionRepository.update(props)
    const payload = await getQuestionSchedulePayload(createdQuestion)

    await reviewsScheduleRepository.upsert(uid, payload)
    return createdQuestion
}

export const questionReviewService = {
    getQuestionsForReview,
    createQuestionAndScheduleReview,
    updateQuestionAndScheduleReview,
}
