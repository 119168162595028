import { Box, SxProps, Theme, Typography } from "@mui/material"
import { UseEmojiPickerType } from "@udecode/plate-emoji"

export type EmojiPickerPreviewProps = Pick<
    UseEmojiPickerType,
    "emoji" | "hasFound" | "isSearching" | "i18n"
>

export type EmojiPreviewProps = Pick<UseEmojiPickerType, "emoji">

export type NoEmojiPreviewProps = Pick<UseEmojiPickerType, "i18n">
export type PickAnEmojiPreviewProps = NoEmojiPreviewProps

function EmojiPreview({ emoji }: EmojiPreviewProps) {
    return (
        <Box sx={styles.preview}>
            <Box fontSize={24}>{emoji?.skins[0].native}</Box>
            <Box display="flex" flexDirection="column">
                <Box>{emoji?.name}</Box>
                <Typography variant="caption" sx={styles.previewId}>{`:${emoji?.id}:`}</Typography>
            </Box>
        </Box>
    )
}

function NoEmoji({ i18n }: NoEmojiPreviewProps) {
    return (
        <Box sx={styles.preview}>
            <Box fontSize={24}>😢</Box>
            <Box display="flex" flexDirection="column">
                <Box>{i18n.searchNoResultsTitle}</Box>
                <Typography sx={styles.previewId} variant="caption">
                    {i18n.searchNoResultsSubtitle}
                </Typography>
            </Box>
        </Box>
    )
}

function PickAnEmoji({ i18n }: PickAnEmojiPreviewProps) {
    return (
        <Box sx={styles.preview}>
            <Box fontSize={24}>☝️</Box>
            <Box>
                <Box>{i18n.pick}</Box>
            </Box>
        </Box>
    )
}

export function EmojiPickerPreview({
    emoji,
    hasFound = true,
    isSearching = false,
    i18n,
    ...props
}: EmojiPickerPreviewProps) {
    const showPickEmoji = !emoji && !(isSearching && !hasFound)
    const showNoEmoji = isSearching && !hasFound
    const showPreview = emoji

    return (
        <>
            {!showNoEmoji && showPreview && <EmojiPreview emoji={emoji} {...props} />}
            {!showNoEmoji && showPickEmoji && <PickAnEmoji i18n={i18n} {...props} />}
            {showNoEmoji && <NoEmoji i18n={i18n} {...props} />}
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    preview: {
        display: "flex",
        alignItems: "center",
        p: 1,
        gap: 1,
    },
    previewId: {
        lineHeight: 1,
        mt: 0,
        p: 0,
        color: (theme) => theme.palette.text.disabled,
    },
}
