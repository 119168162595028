import { useDatabase } from "@nozbe/watermelondb/react"
import { useEffect } from "react"
import { storageService } from "services/storageService"
import { dbUtils } from "storage/watermelon/utils"

export const LOGOUT_STORAGE_EVENT = "logout-storage-event"

export const useClearOnLogout = () => {
    const db = useDatabase()

    useEffect(() => {
        const handleUserLogout = (e: StorageEvent) => {
            if (e.key !== LOGOUT_STORAGE_EVENT) return

            setTimeout(() => dbUtils.deleteDatabase(db))
        }

        window.addEventListener("storage", handleUserLogout)

        return () => {
            window.removeEventListener("storage", handleUserLogout)
        }
        // eslint-disable-next-line
    }, [])
}

export const dispatchClear = () => {
    const storageToggleTrigger = storageService.getItem(LOGOUT_STORAGE_EVENT)
    if (storageToggleTrigger) storageService.removeItem(LOGOUT_STORAGE_EVENT)
    else storageService.setItem(LOGOUT_STORAGE_EVENT, LOGOUT_STORAGE_EVENT)
}
