import { sentry } from "../sentry";
export const hasError = (status, statusText, url) => {
    if (status < 200 || status >= 300) {
        if (status >= 500) {
            sentry.captureMessage(`Status: ${status} - ${statusText} - ${url}`);
        }
        return true;
    }
    return false;
};
export const buildUrl = (url, params) => {
    if (Object.keys(params).length !== 0) {
        url = url + "?" + new URLSearchParams(params).toString();
    }
    return url;
};
export const buildPath = (path, url) => {
    return `${path}?url=${url}`;
};
