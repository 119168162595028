import { Database } from "@nozbe/watermelondb"
import { markdownService, sentry } from "@recall/common"
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from "@udecode/plate-code-block"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"
import { ELEMENT_LINK } from "@udecode/plate-link"
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { ELEMENT_CUSTOM_IMAGE } from "components/ItemPage/components/editor/plugins/image"
import { ELEMENT_CUSTOM_PARAGRAPH } from "components/ItemPage/components/editor/plugins/paragraph"
import { YOUTUBE_TIMESTAMP } from "components/ItemPage/components/editor/plugins/youtube-timestamp"
import { connectionRepository } from "storage/watermelon/repository"
import {
    EDITOR_BLOCK_TYPE,
    HEADING_FIVE_TYPE,
    HEADING_FOUR_TYPE,
    HEADING_ONE_TYPE,
    HEADING_SIX_TYPE,
    HEADING_THREE_TYPE,
    HEADING_TWO_TYPE,
    LINK_TYPE,
    REFERENCE_TYPE,
} from "../editorData/constants"

// The following code used this as a reference: https://github.com/hanford/remark-slate/blob/master/src/serialize.ts
// The code is modified to fit our needs

const ITALIC_FORMAT = "*"
const BOLD_FORMAT = "**"
const BOLD_ITALIC_FORMAT = "***"

const serialize = async (blocks, db: Database): Promise<string> => {
    let exportTexted = ""

    for (const block of blocks) {
        const serializedBlock = await serializeBlock(block, db)
        exportTexted += serializedBlock
    }
    return exportTexted
}

const serializeBlock = async (block, db: Database): Promise<string> => {
    let blockText = ""

    if ([EDITOR_BLOCK_TYPE, ELEMENT_UL, ELEMENT_OL].includes(block.type)) {
        for (const child of block.children) {
            if ([EDITOR_BLOCK_TYPE, ELEMENT_UL, ELEMENT_OL].includes(child.type)) {
                blockText += await serializeBlock(child, db)
            } else if (child.type) {
                blockText += await serializeBlockChild(child, db)
            } else {
                blockText += await formatText(child)
            }
        }
    } else {
        blockText += await serializeBlockChild(block, db)
    }

    return blockText.endsWith("\n\n") ? blockText : blockText + "\n\n"
}

const serializeBlockChild = async (child, db: Database): Promise<string> => {
    switch (child.type) {
        case ELEMENT_CODE_LINE:
        case ELEMENT_PARAGRAPH:
        case ELEMENT_CUSTOM_PARAGRAPH: {
            if (!child.listStyleType) {
                const paragraphText = await serializeParagraph(child, db)
                return paragraphText
            } else {
                return await serializeBlockChild({ ...child, type: ELEMENT_LI }, db)
            }
        }
        case ELEMENT_CUSTOM_IMAGE:
        case ELEMENT_IMAGE: {
            const image = formatImage(child)
            return image
        }
        case ELEMENT_H6:
        case HEADING_SIX_TYPE: {
            const heading = await formatHeading(child, 6, db)
            return heading
        }
        case ELEMENT_H5:
        case HEADING_FIVE_TYPE: {
            const heading = await formatHeading(child, 5, db)
            return heading
        }
        case ELEMENT_H4:
        case HEADING_FOUR_TYPE: {
            const heading = await formatHeading(child, 4, db)
            return heading
        }
        case ELEMENT_H3:
        case HEADING_THREE_TYPE: {
            const heading = await formatHeading(child, 3, db)
            return heading
        }
        case ELEMENT_H2:
        case HEADING_TWO_TYPE: {
            const heading = await formatHeading(child, 2, db)
            return heading
        }
        case ELEMENT_H1:
        case HEADING_ONE_TYPE: {
            const heading = await formatHeading(child, 1, db)
            return heading
        }
        case REFERENCE_TYPE: {
            const reference = await formatReference(child, db)
            return reference
        }
        case ELEMENT_CODE_BLOCK: {
            if (!child?.children) return ""
            const codeBlocks = await Promise.all(
                child.children.map((child) => serializeBlockChild(child, db))
            )

            return "```\n" + codeBlocks.join("") + "\n```"
        }
        case ELEMENT_LI: {
            if (child.children) {
                let value = ""
                for (const childElement of child.children) {
                    if (childElement.type) {
                        value += await serializeBlockChild(childElement, db)
                    } else if (childElement.text) {
                        value += await formatText(childElement)
                    }
                }
                if (!value) return ""
                return formatListItem(value)
            } else {
                const paragraph = await serializeParagraph(child, db)
                if (!paragraph) return ""
                const listItem = formatListItem(paragraph)
                return listItem
            }
        }
        case ELEMENT_LINK: {
            return formatLink(child)
        }
        case ELEMENT_HR: {
            return "\n\n---\n\n"
        }
        case YOUTUBE_TIMESTAMP: {
            const timestamp = child?.children?.[0]?.text
            const url = child.url

            if (!url || !timestamp) return ""

            return `[${timestamp}](${url})`
        }
        default:
            sentry.captureMessage(`Unknown block type: ${JSON.stringify(child)}`)
    }

    return ""
}

const serializeParagraph = async (paragraph, db: Database): Promise<string> => {
    let paragraphText = ""

    for (const element of paragraph?.children || []) {
        if ("type" in element) {
            if (element.type === REFERENCE_TYPE) {
                const reference = await formatReference(element, db)
                paragraphText += reference
            }

            if (element.type === LINK_TYPE) {
                paragraphText += formatLink(element)
            }
        } else {
            paragraphText += formatText(element)
        }
    }

    return paragraphText
}

const reverseString = (string: string): string => {
    return string.split("").reverse().join("")
}

// This function handles the case of a string like this: "   foo   "
// Where it would be invalid markdown to generate this: "**   foo   **"
// We instead, want to trim the whitespace out, apply formatting, and then
// bring the whitespace back. So our returned string looks like this: "   **foo**   "
const formatText = (textNode) => {
    const text = textNode.text
    let format = ""

    if (textNode.italic && textNode.bold) {
        format = BOLD_ITALIC_FORMAT
    } else if (textNode.italic) {
        format = ITALIC_FORMAT
    } else if (textNode.bold) {
        format = BOLD_FORMAT
    } else {
        return text
    }

    const trimmedText = text.trim()

    // We reverse the right side formatting, to properly handle bold/italic/strikethrough
    // formats, so we can create ~~***FooBar***~~
    const formattedTrimmedText = `${format}${trimmedText}${reverseString(format)}`

    // This conditions accounts for no whitespace in our string
    // if we don't have any, we can return early.
    if (trimmedText.length === text.length) {
        return formattedTrimmedText
    }

    // if we do have whitespace replace the non-whitespace content of the original string with the formatted text.
    return text.replace(trimmedText, formattedTrimmedText)
}

const formatReference = async (reference, db: Database) => {
    const connection = await connectionRepository.get(db, reference.connectionId)
    const item = await connection.to.fetch()
    const name = item.name

    const displayName = reference.children.map((unit) => unit.text).join("")
    if (name === displayName) {
        return `[[${name}]]`
    } else {
        return `[[${name} | ${displayName}]]`
    }
}

const formatListItem = (listItem) => {
    return markdownService.formatListItem(listItem)
}

const formatLink = (link) => {
    return markdownService.formatLink(link.url, link.children[0].text)
}

const formatImage = (img) => {
    return markdownService.formatImage(img.urlOriginal || img?.options?.url)
}

const formatHeading = async (heading, level: number, db: Database) => {
    const text = await Promise.all(
        heading.children.map(async (child) => {
            if (child.type) {
                return await serializeBlockChild(child, db)
            } else {
                return child.text
            }
        })
    )

    return markdownService.formatHeading(text.join(""), level)
}

export const markdownSerializer = { serialize }
