import { Database } from "@nozbe/watermelondb"
import { EditorElements } from "components/ItemPage/components/editor/types"
import { map } from "lodash"
import { Descendant } from "slate"
import { ItemModel } from "storage/watermelon/models"
import {
    connectionRepository,
    editorBlockRepository,
    editorOrderRepository,
} from "storage/watermelon/repository"
import { EditorBlockData } from "./EditorBlockData"

export const initEditorData = async (db: Database, itemId: string): Promise<Descendant[]> => {
    const editorBlockModels = await editorBlockRepository.getEditorBlocksByItemId(db, itemId)
    const editorOrderModel = await editorOrderRepository.get(db, itemId)
    let value = EditorBlockData.getOrdered(editorBlockModels, editorOrderModel)

    const connections = await connectionRepository.getItemConnections(db, itemId)
    const connectionIds = map(connections, "id")

    EditorBlockData.removeStaleReferences(value, connectionIds)

    if (value.length === 0) {
        value = [EditorBlockData.create()]
    }

    return value
}

const initItemNameBlockInEditorBlocks = async (
    db: Database,
    editorBlocks: EditorElements,
    editorOrder: string[],
    item: ItemModel
): Promise<EditorElements> => {
    for (const editorBlock of editorBlocks) {
        const blockContent = EditorBlockData.getText([editorBlock])

        if (blockContent.trim() === item.name.trim()) return editorBlocks

        if (blockContent !== "") break
    }

    const titleBlock = EditorBlockData.create(item.name, "h1")
    const editorBlock = await editorBlockRepository.upsert(db, item.id, titleBlock)
    await editorOrderRepository.upsert(db, item.id, [editorBlock.id, ...editorOrder])

    return [editorBlock.toEditorBlock(), ...editorBlocks]
}

export const initItemEditorData = async (
    db: Database,
    item: ItemModel
): Promise<EditorElements> => {
    const [editorBlockModels, editorOrderModels, connectionIds] = await Promise.all([
        item.editorBlocks.fetch(),
        item.editorOrders.fetch(),
        item.links.fetchIds(),
    ])
    const editorOrderModel = editorOrderModels?.[0] || null

    let orderedEditorBlocks: EditorElements = EditorBlockData.getOrdered(
        editorBlockModels,
        editorOrderModel
    )

    orderedEditorBlocks = await initItemNameBlockInEditorBlocks(
        db,
        orderedEditorBlocks,
        editorOrderModel?.order || [],
        item
    )

    EditorBlockData.removeStaleReferences(orderedEditorBlocks, connectionIds)

    if (orderedEditorBlocks.length === 0) {
        orderedEditorBlocks = [EditorBlockData.create()]
    }

    return orderedEditorBlocks
}
