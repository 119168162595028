import { Database, Q } from "@nozbe/watermelondb"
import { v4 as uuid } from "uuid"
import { ItemModel, QuestionModel } from "../models"
import { QUESTIONS } from "../schema"

const getQuestionsByItemId = async (db: Database, id: string) => {
    const questions = await db.get<QuestionModel>(QUESTIONS).query(Q.where("item_id", id)).fetch()

    return questions
}

const getQuestionsForReview = async (db: Database) => {
    return await db
        .get<QuestionModel>(QUESTIONS)
        .query(Q.where("next_review_time", Q.lte(Date.now())))
        .fetch()
}
const getAll = async (db: Database) => {
    return await db.get<QuestionModel>(QUESTIONS).query().fetch()
}

const observeCount = (db: Database) => {
    return db.collections.get<QuestionModel>(QUESTIONS).query().observeCount(false)
}

export interface CreateProps {
    db: Database
    item: ItemModel
    options: string[]
    correctAnswer: string
    question: string
}

const create = async ({ db, item, question, correctAnswer, options }: CreateProps) => {
    return await db.write(async () => {
        const reviewQuestion = await db.collections
            .get<QuestionModel>(QUESTIONS)
            .create((record) => {
                record._raw.id = uuid()
                record.item.set(item)
                record.isSaved = true
                record.question = question
                record.options = options
                record.correctAnswer = correctAnswer
                record.answer = null
                record.nextReviewTime = new Date()
            })

        return reviewQuestion
    })
}

export interface UpdateProps {
    db: Database
    question: QuestionModel
    data: {
        question?: string
        answer: string
        correctAnswer?: string
        options?: string[]
        nextReviewTime?: Date
    }
}

const update = async ({ db, question, data }: UpdateProps) => {
    return await db.write(async () => {
        const updatedQuestion = await question.update((record) => {
            record.answer = data.answer
            record.question = data.question || record.question
            record.correctAnswer = data.correctAnswer || record.correctAnswer
            record.options = data.options || record.options
            record.nextReviewTime = data.nextReviewTime || record.nextReviewTime
        })

        return updatedQuestion
    })
}

const getPendingQuestionsForReview = async (db: Database) => {
    return await db
        .get<QuestionModel>(QUESTIONS)
        .query(Q.where("next_review_time", Q.gt(Date.now())))
        .fetch()
}

const getUreviewedCount = async (db: Database) => {
    return await db
        .get<QuestionModel>(QUESTIONS)
        .query(Q.where("next_review_time", Q.lte(Date.now())))
        .fetchCount()
}

export const questionRepository = {
    getAll,
    getQuestionsByItemId,
    getQuestionsForReview,
    getPendingQuestionsForReview,
    create,
    update,
    getUreviewedCount,
    observeCount,
}
