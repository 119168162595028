import { Database } from "@nozbe/watermelondb"
import { SyncDatabaseChangeSet, synchronize } from "@nozbe/watermelondb/sync"
import { firebase } from "@recall/common"
import { get, orderByChild, query, ref, startAt } from "firebase/database"
import { functions } from "services/firebase/firebaseFunction"
import { v4 as uuidv4 } from "uuid"
import { COLLECTIONS } from "./schema"

export async function syncRealTimeDb(
    database: Database,
    user_id: string,
    handlePushCallback: Function,
    handlePullCallback: Function
) {
    const sessionId = uuidv4()

    await synchronize({
        database,
        sendCreatedAsUpdated: true,
        pullChanges: async (data) =>
            await pullChanges(sessionId, user_id, data, handlePullCallback),
        pushChanges: async (data) => await pushChanges(sessionId, data, handlePushCallback),
    })
}

const pullChanges = async (
    sessionId: string,
    user_id: string,
    { lastPulledAt = undefined },
    handlePullCallback: Function
) => {
    const syncTimestamp = new Date()
    let changes = {}

    await Promise.all(
        COLLECTIONS.map(async (collectionName) => {
            let updated = []
            let created = []
            let deleted = []

            const queryRef = ref(firebase.database, `${user_id}/${collectionName}/`)
            const values = (
                await get(
                    query(
                        queryRef,
                        orderByChild("last_synced_at"),
                        startAt(lastPulledAt - 1, "last_synced_at")
                    )
                )
            ).val()

            if (values) {
                updated = Object.values(values)
                    // @ts-ignore
                    .filter((data) => data.session_id !== sessionId)
                    // @ts-ignore
                    .filter((data) => data.is_deleted === false)

                deleted = Object.values(values)
                    // @ts-ignore
                    .filter((data) => data.session_id !== sessionId)
                    // @ts-ignore
                    .filter((data) => data.is_deleted === true)
                    // @ts-ignore
                    .map((data) => data.id)
            }

            changes = {
                ...changes,
                [collectionName]: { created, deleted, updated },
            }
        })
    )
    await handlePullCallback()

    return { changes, timestamp: +syncTimestamp }
}

const pushChanges = async (
    sessionId: string,
    { changes, lastPulledAt },
    handlePushCallback: Function
) => {
    const filteredChanges = filterUnsavedChanges(changes)
    const isEmpty = areChangesEmpty(filteredChanges)
    if (isEmpty) return

    await functions.pushDatabaseChanges({ changes, lastPulledAt, sessionId })
    await handlePushCallback()
}

const filterUnsavedChanges = (changes: SyncDatabaseChangeSet): SyncDatabaseChangeSet => {
    const filteredChanges: SyncDatabaseChangeSet = {}

    for (const [collectionName, collectionChanges] of Object.entries(changes)) {
        filteredChanges[collectionName] = {
            created: [],
            updated: [],
            deleted: [],
        }

        for (const actionType in collectionChanges) {
            const isDelete = actionType === "deleted"

            if (isDelete) {
                filteredChanges[collectionName][actionType] = collectionChanges[actionType]
                continue
            }

            filteredChanges[collectionName][actionType] = collectionChanges[actionType].filter(
                (action: { is_saved: boolean }) => action.is_saved === true
            )
        }
    }

    return filteredChanges
}

const areChangesEmpty = (changes: SyncDatabaseChangeSet): boolean => {
    for (const collection of Object.values(changes)) {
        for (const actions of Object.values(collection)) {
            if (actions.length > 0) {
                return false
            }
        }
    }
    return true
}

export const DOCUMENT_WAS_MODIFIED_ERROR = "DOCUMENT WAS MODIFIED DURING PULL AND PUSH OPERATIONS"
export const DOCUMENT_WAS_DELETED_ERROR = "DOCUMENT WAS DELETED DURING PULL AND PUSH OPERATIONS"
