import { Delete, Edit } from "@mui/icons-material"
import TreeItem from "@mui/lab/TreeItem"
import { Box, styled, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { HOME_PATH, KNOWLEDGE_GRAPH_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsMobile } from "hooks/useIsMobile"
import { debounce, map, orderBy } from "lodash"
import { FC, memo, useCallback, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { itemRepository, ROOT_TAG_ID, tagRepository } from "storage/watermelon/repository"
import { GroupedTags, RERENDER_TAGS_EVENT, Tag } from "./hooks/useGroupedTags"
import { TagsTreeCard } from "./TagsTreeCard"
import { TagsTreeText } from "./TagsTreeText"

interface Props {
    tag: Tag
    tags: GroupedTags
    selectedTagIds: string[]
    searchText?: string
    selectedItemId?: string
    selectedTagId?: string
    expandTag: (tagId: string) => void
    toggleSelect: (tagId: string) => void
    setTagToDelete: (tag: Tag) => void
    setTagToUpdate: (tag: Tag) => void
    handleClickItem: (id: string) => void
}

export const TagsTreeItem: FC<Props> = memo(
    ({
        tags,
        tag,
        selectedTagIds,
        selectedItemId,
        searchText,
        expandTag,
        toggleSelect,
        setTagToDelete,
        setTagToUpdate,
        handleClickItem,
    }) => {
        const db = useDatabase()
        const isMobile = useIsMobile()
        const history = useHistory()
        const location = useLocation()
        const { id } = useParams<{ id: string }>()
        const { getItemPath } = useOpenItem()
        const children = tag.children
            ? orderBy(
                  tag.children.filter((child) => tags[child]).map((child) => tags[child]),
                  (tag) => tag?.name?.toLowerCase()
              )
            : []
        const [isDraggedOver, setIsDraggedOver] = useState(false)
        const [isDragging, setIsDragging] = useState(false)

        const isSelected = selectedTagIds?.includes(tag.id)

        const handleClick = (e: React.MouseEvent) => {
            e.stopPropagation()
            toggleSelect(tag.id)

            if (location.pathname !== HOME_PATH && location.pathname !== KNOWLEDGE_GRAPH_PATH) {
                history.push(HOME_PATH)
            }
        }

        const setIsDraggedOverDebounced = useCallback(
            debounce((isDraggedOver) => {
                setIsDraggedOver(isDraggedOver)
            }),
            []
        )

        const handleDropTag = async (tagId: string) => {
            if (!tagId || tagId === tag.id) return
            const childTag = await tagRepository.get(db, tagId)

            if (childTag.parent.id === tag.id) return

            const existingTag = await tagRepository.getTagByNameAndParentId(
                db,
                childTag.name,
                tag.id
            )

            if (existingTag) {
                await tagRepository.mergeTags(db, existingTag, childTag)
                return
            }

            const nestedTags = await tagRepository.getNestedTags(db, [tagId])
            const isParentElement = map(nestedTags, "id").includes(tag.id)

            if (isParentElement) return

            await tagRepository.updateParent(db, tag.id, tagId)
        }

        const handleDropItem = async (tagId: string, itemId: string) => {
            if (tag.id === tagId) return
            if (tagId) await tagRepository.detach(db, tagId, itemId)
            if (tag.id === ROOT_TAG_ID) return

            const [currentTag, item] = await Promise.all([
                tagRepository.get(db, tag.id),
                itemRepository.get(db, itemId),
            ])

            if (currentTag && item) await tagRepository.attach(db, currentTag, item)
        }

        return (
            <Box
                draggable
                onDragStart={(e) => {
                    e.stopPropagation()
                    e.dataTransfer.setData(
                        "application/json",
                        JSON.stringify({ tagId: tag.id, isTag: true })
                    )
                    setIsDragging(true)
                }}
                onDragLeave={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setIsDraggedOverDebounced(false)
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setIsDraggedOverDebounced.cancel()
                    if (!isDraggedOver) {
                        setIsDraggedOver(true)
                    }
                }}
                onDragEnd={() => {
                    setIsDragging(false)
                }}
                onDrop={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsDraggedOver(false)

                    const { tagId, isTag, itemId } = JSON.parse(
                        e.dataTransfer.getData("application/json")
                    )

                    if (isTag) {
                        await handleDropTag(tagId)
                    } else {
                        await handleDropItem(tagId, itemId)
                    }
                    expandTag(tag.id)
                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                }}
                sx={{
                    opacity: isDragging && 0.5,
                    background: (theme) => isDraggedOver && theme.palette.action.disabledBackground,
                }}
            >
                <StyledTreeItemRoot
                    TransitionProps={{
                        timeout: 0,
                    }}
                    nodeId={tag.id}
                    onFocusCapture={(e) => e.stopPropagation()}
                    label={
                        <Box sx={styles.menuItem(isMobile && isSelected)}>
                            <Box
                                className="tag"
                                sx={{
                                    py: 0.5,
                                    px: 1,
                                    bgcolor: (theme) =>
                                        isSelected
                                            ? theme.palette.action.disabledBackground
                                            : "transparent",
                                    borderRadius: 2,
                                    "&:hover": {
                                        bgcolor: (theme) =>
                                            isSelected ? undefined : theme.palette.action.hover,
                                    },
                                }}
                                onClick={handleClick}
                            >
                                <Typography
                                    sx={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        lineHeight: "1.35",
                                    }}
                                    fontWeight={500}
                                    variant="body2"
                                >
                                    <TagsTreeText
                                        label={tag.name}
                                        searchText={searchText}
                                        selected={isSelected}
                                    />
                                </Typography>
                            </Box>

                            {!isDragging && !isDraggedOver && (
                                <Box display="flex" className="actions">
                                    <Box
                                        data-testid="menu-tree-tag-update"
                                        onClick={(e) => {
                                            setTagToUpdate(tag)
                                            e.stopPropagation()
                                        }}
                                    >
                                        <Edit
                                            fontSize="small"
                                            sx={styles.menuItemAction(isMobile && isSelected)}
                                        />
                                    </Box>
                                    <Box
                                        data-testid="menu-tree-tag-delete"
                                        onClick={(e) => {
                                            setTagToDelete(tag)
                                            e.stopPropagation()
                                        }}
                                    >
                                        <Delete
                                            fontSize="small"
                                            sx={styles.menuItemAction(isMobile && isSelected)}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    }
                >
                    <Box sx={{ display: isDragging && "none" }}>
                        {tag.items.map((searchedItem) => (
                            <TagsTreeCard
                                key={searchedItem.item.id}
                                tagId={tag.id}
                                selected={
                                    (id === searchedItem.item.id &&
                                        location.pathname.includes(getItemPath(id))) ||
                                    (location.pathname.includes(KNOWLEDGE_GRAPH_PATH) &&
                                        searchedItem.item.id === selectedItemId)
                                }
                                searchedItem={searchedItem}
                                searchText={searchText}
                                handleClick={handleClickItem}
                            />
                        ))}
                    </Box>
                    <Box sx={{ display: isDragging && "none" }}>
                        {children.map((tag) => (
                            <TagsTreeItem
                                key={tag.id + tag.isSaved}
                                expandTag={expandTag}
                                tags={tags}
                                tag={tag}
                                selectedItemId={selectedItemId}
                                selectedTagIds={selectedTagIds}
                                toggleSelect={toggleSelect}
                                setTagToDelete={setTagToDelete}
                                setTagToUpdate={setTagToUpdate}
                                searchText={searchText}
                                handleClickItem={handleClickItem}
                            />
                        ))}
                    </Box>
                </StyledTreeItemRoot>
            </Box>
        )
    }
)

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    ".MuiTreeItem-content": {
        backgroundColor: "transparent !important",
        padding: 0,
    },
    ".MuiTreeItem-iconContainer": {
        marginRight: "-1px !important",
    },
}))

const styles = {
    menuItem: (isSelectedOnMobile: boolean) => ({
        my: 0.2,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .actions svg": {
            display: isSelectedOnMobile ? "block" : "none",
        },
        "&:hover .actions svg": {
            display: "block",
        },
    }),
    menuItemAction: (isSelectedOnMobile?: boolean) => ({
        opacity: isSelectedOnMobile ? 1 : 0.3,
        ":hover": {
            opacity: 1,
        },
    }),
}
