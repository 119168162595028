import { Database } from "@nozbe/watermelondb"
import { ExtensionItemDoc, firebase, isWikipediaUrl, sentry } from "@recall/common"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { doc, runTransaction } from "firebase/firestore"
import { orderBy } from "lodash"
import { itemAPI } from "storage/api/items/Item"
import { createAssets } from "storage/watermelon/helper/editorBlocks"
import { itemRepository } from "storage/watermelon/repository"

const createExtensionItems = async (
    extensionItems: ExtensionItemDoc[],
    uid: string,
    db: Database
) => {
    const orderedExtensionItems = orderBy(extensionItems, "createdAt", "asc")

    try {
        await runTransaction(firebase.firestore, async (transaction) => {
            const extensionItemRefs = []
            for (const extensionItem of orderedExtensionItems) {
                const extensionItemRef = doc(
                    firebase.firestore,
                    "users",
                    uid,
                    "extension_items",
                    extensionItem.id
                )
                const extensionItemDoc = await transaction.get(extensionItemRef)

                // eslint-disable-next-line
                if (!extensionItemDoc.exists()) throw "Document already processing"
                extensionItemRefs.push(extensionItemRef)
            }

            for (const extensionItemRef of extensionItemRefs) {
                transaction.delete(extensionItemRef)
            }
        })

        for (const extensionItem of orderedExtensionItems) {
            try {
                if (!extensionItem.expand) {
                    const item = await itemAPI.saveItemApi(db, extensionItem.itemApi, true, {
                        id: extensionItem.itemId,
                    })
                    await item.setCreatedAt(extensionItem.createdAt)
                    await item.setUpdatedAt(extensionItem.createdAt)

                    if (isWikipediaUrl(extensionItem.url)) {
                        await item.setIsExpanded(true)
                    }
                    await createAssets(db, item)
                } else {
                    const sourceIdentifier = extensionItem.itemApi?.sources?.[0]?.identifier
                    if (!sourceIdentifier) return

                    const item = await itemRepository.getBySource({ db, sourceIdentifier })
                    const newItem = await itemAPI.saveItemApi(db, extensionItem.itemApi, true, {
                        id: extensionItem.itemId,
                    })

                    if (item) await item.merge(newItem)
                    await createAssets(db, item)
                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                }
            } catch (error) {
                sentry.captureException(error, { url: extensionItem.url, uid })
            }
        }
    } catch (error) {
        console.error("Transaction failed: ", error)
    }
}

export const extensionItemService = { createExtensionItems }
