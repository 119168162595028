import { Box, DialogContent, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { tutorials } from "@recall/common"
import LogoDark from "assets/svg/logo_full_dark.svg"
import LogoLight from "assets/svg/logo_full_light.svg"
import { ITEM_PARTIAL_DIV_ID } from "components/shared/ItemPartial/ItemPartialComp"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsDarkMode } from "hooks/useThemeMode"
import React, { FC, memo, useEffect, useState } from "react"
import { itemHelper } from "storage/watermelon/helper/item"
import { itemRepository } from "storage/watermelon/repository"
import { Step, TutorialBase, commonTutorialStyles } from "./TutorialBase"

export const itemsIntroTutorialName = "itemsIntro"

export interface Props {
    goNext: Function
    isAutoGenerated: boolean
}

const KnowledgeCardExampleStep: FC<Props> = ({ goNext, isAutoGenerated }) => {
    const isMobile = useIsMobile()

    useEffect(() => {
        const handleOnClick = () => {
            setTimeout(() => {
                goNext()
            })
        }

        const button = document.getElementById(ITEM_PARTIAL_DIV_ID)

        if (button) {
            button.addEventListener("click", handleOnClick)
            return () => button.removeEventListener("click", handleOnClick)
        }
    }, [goNext])

    return (
        <DialogContent sx={commonTutorialStyles.popoverContent}>
            <Typography sx={commonTutorialStyles.heading}>Knowledge card</Typography>
            <Typography sx={commonTutorialStyles.body}>
                {isAutoGenerated
                    ? "We created an example knowledge card for this tutorial."
                    : "This is a knowledge card."}
            </Typography>
            <Typography sx={commonTutorialStyles.body}>
                <b>{isMobile ? "Press" : "Click"}</b> it to continue.
            </Typography>
        </DialogContent>
    )
}

const TutorialItemsIntroComponent: React.FC = () => {
    const db = useDatabase()
    const isDarkMode = useIsDarkMode()

    const [isFirstCardAutogenerated, setIsFirstCardAutogenerated] = useState(true)

    const getIsFirstCardAutoGenerated = async () => {
        await itemHelper.initializeDefaultCard(db)
        const items = await itemRepository.getQuery(db, false).fetch()

        if (items.length > 1) {
            setIsFirstCardAutogenerated(false)
            return
        }

        setIsFirstCardAutogenerated(items[0]?.name === "ChatGPT")
    }

    useEffect(() => {
        getIsFirstCardAutoGenerated()
        // eslint-disable-next-line
    }, [])

    const steps: Step[] = [
        {
            anchorId: null,
            placement: null,
            showArrow: false,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Box display="flex" justifyContent="center">
                        <Box
                            component="img"
                            alt="recall logo"
                            height={30}
                            src={isDarkMode ? LogoDark : LogoLight}
                        />
                    </Box>
                    <Typography
                        pt={1.5}
                        style={{ textAlign: "center", fontSize: 28 }}
                        variant={"h4"}
                    >
                        Welcome to Recall
                    </Typography>
                    <Typography pt={1.5} variant="body1">
                        Recall allows you to create and store <b>knowledge cards</b>, which are
                        brief summaries of various topics, including blog posts, podcasts, Wikipedia
                        entries, news articles, recipes, and other online content.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: ITEM_PARTIAL_DIV_ID,
            placement: "bottom",
            showArrow: true,
            isNextDisabled: false,
            component: (props: Props) => (
                <KnowledgeCardExampleStep {...props} isAutoGenerated={isFirstCardAutogenerated} />
            ),
        },
    ]

    return <TutorialBase tutorialName={tutorials.ITEMS_INTRO} steps={steps} />
}

export const TutorialItemsIntro = memo(TutorialItemsIntroComponent)
