export const SET_USER = "SET_USER"
export const UPDATE_USER = "UPDATE_USER"
export const SET_INITIALIZING_USER = "SET_INITIALIZING_USER"
export const TUTORIAL_RESTART = "TUTORIAL_RESTART"
export const TUTORIAL_COMPLETE = "TUTORIAL_COMPLETE"
export const SET_DID_UPDATE = "SET_DID_UPDATE"
export const SET_IS_APP_INSTALLED = "SET_IS_APP_INSTALLED"
export const SET_SEARCH_TERM = "SET_SEARCH_TERM"
export const SET_ACTIVE_TUTORIAL = "SET_ACTIVE_TUTORIAL"
export const TUTORIAL_RESTART_SINGLE = "TUTORIAL_RESTART_SINGLE"
export const SET_ONBOARDING = "SET_ONBOARDING"
export const SET_SURVEY_COMPLETED = "SET_SURVEY_COMPLETED"
export const SET_DB_VERSION = "SET_DB_VERSION"
export const SET_CLOSE_REVIEW_MODAL = "SET_CLOSE_REVIEW_MODAL"
