var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { collection, getDocs, query, where } from "@firebase/firestore";
import { COLLECTIONS } from "../../constants";
import { firebase } from "./firebaseServices";
const LIFETIME_PRICE_ID = "price_1NiAlNKLKMKbaiD6re1deI2Y";
const checkUserSubscriptions = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    const subscriptionsQuery = query(collection(firebase.firestore, COLLECTIONS.USERS, userId, COLLECTIONS.SUBSCRIPTIONS), where("status", "==", "active"));
    const subscriptions = yield getDocs(subscriptionsQuery);
    return !!subscriptions.docs.length;
});
const checkIsLifetimeUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    const paymentsQuery = query(collection(firebase.firestore, COLLECTIONS.USERS, userId, COLLECTIONS.PAYMENTS));
    const payments = (yield getDocs(paymentsQuery)) || [];
    for (const paymentDoc of payments.docs) {
        const payment = paymentDoc.data();
        if (!(payment === null || payment === void 0 ? void 0 : payment.items))
            continue;
        const isLifetimePrice = payment.items.some((item) => { var _a, _b; return ((_a = item === null || item === void 0 ? void 0 : item.price) === null || _a === void 0 ? void 0 : _a.active) && ((_b = item === null || item === void 0 ? void 0 : item.price) === null || _b === void 0 ? void 0 : _b.id) === LIFETIME_PRICE_ID; });
        if (isLifetimePrice)
            return true;
    }
    return false;
});
const checkIsPremiumUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    const isSubscribedPremium = yield checkUserSubscriptions(userId);
    if (isSubscribedPremium)
        return true;
    return yield checkIsLifetimeUser(userId);
});
const checkIsPremiumNotCanceledUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    const subscriptionsQuery = query(collection(firebase.firestore, COLLECTIONS.USERS, userId, COLLECTIONS.SUBSCRIPTIONS), where("status", "==", "active"), where("cancel_at_period_end", "==", false));
    const subscriptions = yield getDocs(subscriptionsQuery);
    return !!subscriptions.docs.length;
});
export const premiumUserService = {
    checkIsPremiumUser,
    checkIsPremiumNotCanceledUser,
};
