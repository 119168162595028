import { Bookmark as BookmarkIcon } from "@mui/icons-material"
import TreeItem, { treeItemClasses, TreeItemProps } from "@mui/lab/TreeItem"
import { Checkbox, Theme, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled, SxProps } from "@mui/material/styles"
import { Bookmark } from "@recall/common"
import { orderBy } from "lodash"
import { FC, MouseEvent, useContext, useEffect } from "react"
import { extensionService } from "services/extensionService"
import { TreeContext } from "./BookmarksTree"

type Props = TreeItemProps & {
    bookmark: Bookmark
}

export const BookmarksTreeItem: FC<Props> = ({ label, bookmark, ...other }) => {
    const { selected, addBookmarks, removeBookmarks, isSelected } = useContext(TreeContext)

    const isBookmarkSelected = isSelected(bookmark.id)

    useEffect(() => {
        if (!bookmark.isDirectory) return

        const bookmarkIds = extensionService.extractBookmarkIds([bookmark])

        const isEveryChildSelected = bookmarkIds
            .filter((id) => id !== bookmark.id)
            .every((bookmarkId) =>
                selected.some((selectedBookmark) => bookmarkId === selectedBookmark.id)
            )

        if (isEveryChildSelected && !isBookmarkSelected) addBookmarks([bookmark])
        if (!isEveryChildSelected && isBookmarkSelected) removeBookmarks([bookmark.id])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    const handleCheck = () => {
        if (isBookmarkSelected) {
            removeBookmarks([bookmark.id])
        } else {
            addBookmarks([bookmark])
        }
    }

    const handleCheckDirectory = (e: MouseEvent<Element>) => {
        e.stopPropagation()

        if (isBookmarkSelected) {
            const bookmarkIds = extensionService.extractBookmarkIds([bookmark])
            removeBookmarks(bookmarkIds)
        } else {
            const bookmarks = extensionService.getChildrenBookmarks([bookmark])
            addBookmarks(bookmarks)
        }
    }

    if (bookmark.isDirectory && !bookmark?.children?.length) return null

    const orderedBookmarks = orderBy(bookmark?.children, "isDirectory")

    return (
        <StyledTreeItem
            label={
                <Box
                    sx={{
                        ...styles.treeItem,
                        cursor: bookmark.isDirectory ? "pointer" : "default",
                    }}
                    onClick={bookmark.isDirectory ? undefined : handleCheck}
                >
                    {!bookmark.isDirectory && (
                        <Box component={BookmarkIcon} color="inherit" mr={1} className="icon" />
                    )}
                    <Typography variant="body2" sx={styles.treeItemLabel}>
                        {label}
                    </Typography>

                    <Checkbox
                        size="small"
                        checked={isBookmarkSelected}
                        onClick={bookmark.isDirectory ? handleCheckDirectory : handleCheck}
                    />
                </Box>
            }
            {...other}
        >
            {orderedBookmarks.map((bookmark) => (
                <BookmarksTreeItem
                    className={bookmark.isDirectory ? "directory" : "bookmark"}
                    key={bookmark.id}
                    nodeId={bookmark.id}
                    bookmark={bookmark}
                    label={bookmark.name}
                />
            ))}
        </StyledTreeItem>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    treeItem: { display: "flex", alignItems: "center", p: 0.5, pr: 0 },
    treeItemLabel: { fontWeight: "inherit", flexGrow: 1 },
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    ".icon": {
        fill: theme.palette.text.secondary,
    },
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "&.Mui-expanded": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "&:hover": {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: "transparent",
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: "inherit",
            color: "inherit",
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 14,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
    ".bookmark > div": {
        paddingLeft: "0 !important",
    },
}))
