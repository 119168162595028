import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { isDevelopment } from "./environment";
const sentryAppDsn = {
    web: "https://49a444f922ca4f8b950594de49bb160f@o1101435.ingest.sentry.io/6127317",
    extension: "https://d308c9834d364120b6bddbfd3a0c8161@o1101435.ingest.sentry.io/4504780139397120",
};
const captureException = (e, data) => {
    console.warn(e);
    if (isDevelopment()) {
        return;
    }
    if (data)
        return Sentry.captureException(e, { contexts: { data } });
    Sentry.captureException(e);
};
const captureMessage = (message, data) => {
    console.warn(message);
    if (isDevelopment()) {
        return;
    }
    Sentry.captureMessage(message, data);
};
const captureEvent = (event) => {
    if (isDevelopment()) {
        return;
    }
    Sentry.captureEvent(event);
};
const init = (app, release) => {
    if (isDevelopment()) {
        return;
    }
    Sentry.init({
        dsn: sentryAppDsn[app],
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0,
        release,
    });
};
export const sentry = { captureException, captureMessage, captureEvent, init };
