import ImageIcon from "@mui/icons-material/Image"
import { Avatar, Box, SxProps, Theme } from "@mui/material"
import React from "react"

interface Props {
    sx?: SxProps<Theme>
    image: string
}

export const PlaceHolderImage = ({ sx }: { sx: Props["sx"] }) => {
    return (
        <Box
            sx={[
                styles.image,
                ...(Array.isArray(sx) ? sx : [sx]),
                { backgroundColor: "action.hover" },
            ]}
        >
            <ImageIcon sx={styles.icon} fontSize="large" />
        </Box>
    )
}

export const FirstImageComp: React.FC<React.PropsWithChildren<Props>> = ({ image, sx = {} }) => {
    if (!image) return <PlaceHolderImage sx={sx} />

    return (
        <Avatar
            alt=""
            variant={"square"}
            src={image}
            sx={[
                styles.image,
                ...(Array.isArray(sx) ? sx : [sx]),
                { backgroundColor: "rgba(255, 255, 255, 0.9)" },
            ]}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    image: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "action.hover",
        width: 90,
        minHeight: 130,
        height: "100%",
    },
    icon: {
        color: "action.selected",
    },
}
