import { Database } from "@nozbe/watermelondb"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    CHECK_USER_PREMIUM_STATE,
    checkExtensionStatus,
    firebase,
    premiumUserService,
    RECALL_APP_URL,
    sendMessageToExtension,
    sentry,
    User,
} from "@recall/common"
import { SIGNUP_EVENT } from "constants/events"
import {
    createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
    User as FirebaseUser,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    OAuthProvider,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail as sendPasswordResetEmailFirebase,
    signInWithCredential,
    signInWithCustomToken,
    signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
    signOut as signOutFirebase,
} from "firebase/auth"
import { useInit } from "hooks/auth/useInit"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { seenOptions } from "hooks/useIsSeen"
import { useQueryParameter } from "hooks/useQueryParameter"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { userRepository } from "repositories/userRepository"
import { captureEventService } from "services/captureEventService"
import { gtmService } from "services/gtmService"
import { referralService } from "services/referralService"
import { storageService } from "services/storageService"
import { SET_APP_LOADING, SET_USER_LOADING } from "storage/redux/app/actionTypes"
import { LOGOUT_ACTION, RootState } from "storage/redux/rootReducer"
import store from "storage/redux/store"
import { SET_USER } from "storage/redux/user/actionTypes"
import { dbUtils } from "storage/watermelon/utils"
import { initializeUser } from "../../utils/config"
import { authErrorCodeMap } from "./errors"
import { dispatchClear, useClearOnLogout } from "./useClearOnLogout"
import { useExtensionAuth } from "./useExtensionAuth"

const actionCodeSettings = {
    url: `${RECALL_APP_URL}/items/`,
    handleCodeInApp: true,
}

export const useAuthenticate = () => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)
    const { initOnboarding, initTutorials } = useInit()
    const { resetBreadcrumbs } = useBreadcrumbActions()
    const db = useDatabase()
    const extensionAuth = useExtensionAuth()
    const { param: token, remove } = useQueryParameter("authToken")

    const removeAnonymousUserData = async () => {
        await dbUtils.deleteDatabase(db)
    }

    useClearOnLogout()

    useEffect(() => {
        if (uid) loginExtension()
    }, [])

    const loginExtension = async () => {
        await extensionAuth.loginWithCustomToken()
        const { isExtensionInstalled, isExtensionLoggedIn } = await checkExtensionStatus()
        if (isExtensionInstalled && isExtensionLoggedIn)
            sendMessageToExtension({
                type: CHECK_USER_PREMIUM_STATE,
                isPremium: isPremiumUser,
            })
    }

    const loginWithToken = async () => {
        if (!token) return

        try {
            await signInWithCustomToken(firebase.auth, token)
            remove()
        } catch (e) {
            sentry.captureException(e)
        }
    }

    useEffect(() => {
        loginWithToken()
    }, [])

    const migrateUserFields = async (user: User, uid: string) => {
        if (user?.isReviewEmailEnabled === undefined)
            await userRepository.upsertUser(uid, { isReviewEmailEnabled: true })

        if (user?.isOnboardingBannerVisible === undefined) {
            const lastSeen = storageService.getItem(seenOptions.ONBOARDING_BANNER)
            let isOnboardingBannerVisible = !Boolean(lastSeen)
            await userRepository.upsertUser(uid, { isOnboardingBannerVisible })
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebase.auth, async (currentUser) => {
            if (!currentUser) {
                dispatch({ type: LOGOUT_ACTION })
                dispatch({ type: SET_APP_LOADING, payload: true })
                dispatch({ type: SET_USER_LOADING, payload: false })
                userRepository.removeUser()
                extensionAuth.logout()
                resetBreadcrumbs(false)
                return
            }
            dispatch({ type: SET_USER_LOADING, payload: false })

            const user = await userRepository.getUser(currentUser.uid)
            if (currentUser.emailVerified && user?.referralTokenUsed)
                referralService.verifyReferralUsed(currentUser.uid, user.referralTokenUsed)

            await migrateUserFields(user, currentUser.uid)

            await initializeUser(db, currentUser)

            const [, completedTutorials] = await Promise.all([
                initOnboarding(currentUser),
                initTutorials(currentUser),
            ])

            const isPremiumUser = await premiumUserService.checkIsPremiumUser(currentUser.uid)

            const isExistingUserLoggedIn = !uid && !isEmpty(user)

            if (isExistingUserLoggedIn) await removeAnonymousUserData()

            dispatch({
                type: SET_USER,
                payload: {
                    uid: currentUser.uid,
                    email: currentUser.email,
                    surveys: user?.surveys || null,
                    modals: user?.modals || [],
                    menu: {
                        expandedTags: user?.menu?.expandedTags,
                        isGetRecallHidden: user?.menu?.isGetRecallHidden || false,
                    },
                    completedTutorials,
                    dbVersion: user?.dbVersion || null,
                    isPremiumUser,
                    language: user?.summaryOptions?.language || "auto",
                    searchLanguage: user?.summaryOptions?.searchLanguage || "en",
                    sharedCards: user?.sharedCards || [],
                    isReviewEmailEnabled:
                        user?.isReviewEmailEnabled === undefined
                            ? true
                            : user?.isReviewEmailEnabled,
                    isOnboardingBannerVisible: user?.isOnboardingBannerVisible,
                },
            })
        })

        return unsubscribe

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, db, firebase.auth])
}

export const useAuth = () => {
    const db = useDatabase()

    const extensionAuth = useExtensionAuth()

    const handleErrorMessage = (error_code: string) => {
        let message = "Something went wrong. Please contact support (Paul)."

        if (error_code in authErrorCodeMap) {
            message = authErrorCodeMap[error_code]
        }

        toast(message, { type: "error" })
    }

    const sendVerificationEmail = async (user: FirebaseUser) => {
        try {
            await sendEmailVerification(user, actionCodeSettings)
        } catch (e) {
            sentry.captureException(e, { message: "Failed to send verification email." })
        }
    }

    const resendVerificationEmail = async () => {
        const user = firebase.auth.currentUser

        if (!user) return

        await sendEmailVerification(user, actionCodeSettings)
        toast("We have sent you a verification email.", { type: "success" })
    }

    const signOut = async () => {
        await logout(db)
    }

    const sendPasswordResetEmail = async (email: string) => {
        await sendPasswordResetEmailFirebase(firebase.auth, email, actionCodeSettings)
    }

    const signInUserWithAppleIdToken = async ({
        token,
        nonce,
    }: {
        token: string
        nonce: string
    }) => {
        const credential = new OAuthProvider("apple.com").credential({
            idToken: token,
            rawNonce: nonce,
        })
        await signInWithCredential(firebase.auth, credential)
    }

    const signInUserWithIdToken = async (token: string) => {
        const googleCredential = GoogleAuthProvider.credential(token)
        await signInWithCredential(firebase.auth, googleCredential)
    }

    const executeLogin = async (
        loginAction: () => Promise<FirebaseUser | null>,
        errorHandler?: (error: any) => void
    ) => {
        try {
            extensionAuth.isLoggingIn.current = true
            const user = await loginAction()
            extensionAuth.isLoggingIn.current = false
            return user || null
        } catch (error) {
            if (errorHandler) {
                errorHandler(error)
            }
            extensionAuth.isLoggingIn.current = false
            return null
        }
    }

    const createUserWithEmailAndPassword = async (email: string, password: string) => {
        return executeLogin(
            async () => {
                const credential = await createUserWithEmailAndPasswordFirebase(
                    firebase.auth,
                    email,
                    password
                )
                await sendVerificationEmail(credential.user)
                await referralService.createReferralUsed(credential.user.uid)
                gtmService.trackSignupConversion()
                captureEventService.captureGoogleAndPosthog(SIGNUP_EVENT)
                await extensionAuth.loginWithEmailAndPassword(email, password)
                return credential.user
            },
            (error) => handleErrorMessage(error.code)
        )
    }

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        return await executeLogin(
            async () => {
                const credential = await signInWithEmailAndPasswordFirebase(
                    firebase.auth,
                    email,
                    password
                )
                await extensionAuth.loginWithEmailAndPassword(email, password)
                return credential.user
            },
            (error) => {
                throw error
            }
        )
    }

    const signInWithGooglePopup = async () => {
        return executeLogin(
            async () => {
                const credential = await firebase.signinWithPopup()

                const { isNewUser } = getAdditionalUserInfo(credential)
                if (isNewUser) {
                    gtmService.trackSignupConversion()
                    captureEventService.captureGoogleAndPosthog(SIGNUP_EVENT)
                    await referralService.createReferralUsed(credential.user.uid, true)
                }

                await extensionAuth.loginWithCredential(credential)

                return credential.user
            },
            (error) => handleErrorMessage(error.code)
        )
    }

    const signInWithApple = async () => {
        return executeLogin(
            async () => {
                const credential = await firebase.signinWithApple()

                const { isNewUser } = getAdditionalUserInfo(credential)
                if (isNewUser) {
                    gtmService.trackSignupConversion()
                    captureEventService.captureGoogleAndPosthog(SIGNUP_EVENT)
                    await referralService.createReferralUsed(credential.user.uid, true)
                }

                await extensionAuth.loginWithCredential(credential)

                return credential.user
            },
            (error) => handleErrorMessage(error.code)
        )
    }

    return {
        signOut,
        createUserWithEmailAndPassword,
        signInWithGooglePopup,
        signInWithEmailAndPassword,
        sendPasswordResetEmail,
        signInUserWithIdToken,
        resendVerificationEmail,
        signInUserWithAppleIdToken,
        signInWithApple,
    }
}

export const logout = async (db: Database) => {
    store.dispatch({ type: LOGOUT_ACTION })
    await signOutFirebase(firebase.auth)
    setTimeout(() => {
        dbUtils.deleteDatabase(db)
    })
    dispatchClear()
}
