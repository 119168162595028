import { PushPin, PushPinOutlined } from "@mui/icons-material"
import { IconButton, Tooltip, Typography } from "@mui/material"
import React from "react"

interface Props {
    isPinned: boolean
    onClick: (e) => Promise<void>
}

export const PushPinToggle: React.FC<Props> = ({ onClick, isPinned }) => {
    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2">{isPinned ? "Unpin" : "Pin"} this card</Typography>
                    <i>
                        {isPinned
                            ? 'Unpinned cards will not show up in the left-side menu (unless you click "Show all cards")'
                            : "Pinned cards will always show up in the left-side menu"}
                    </i>
                </>
            }
        >
            <IconButton sx={{ transform: "rotate(45deg)" }} onClick={onClick}>
                {isPinned ? <PushPin /> : <PushPinOutlined />}
            </IconButton>
        </Tooltip>
    )
}
