var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import { v4 as uuid } from "uuid";
import { EXTENSION_INSTALLED, EXTERNAL_FIREFOX_MESSAGE, EXTERNAL_FIREFOX_MESSAGE_RESPONSE, } from "../../constants";
import { EXTENSION_ID } from "../../settings";
import { isFirefox, sentry } from "../../utils";
import { IS_EXTENSION_LOGGED_IN } from "./messageTypes";
const EXTENSION_NOT_INSTALLED_MESSAGE = "Extension not installed";
const EXTENSION_NOT_INSTALLED_ERROR = "Error: Could not establish connection. Receiving end does not exist.";
export const checkExtensionStatus = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield sendMessageToExtension({
            type: IS_EXTENSION_LOGGED_IN,
        });
        return { isExtensionInstalled: true, isExtensionLoggedIn: response.isLoggedIn };
    }
    catch (err) {
        if (err !== EXTENSION_NOT_INSTALLED_MESSAGE && err !== EXTENSION_NOT_INSTALLED_ERROR)
            sentry.captureException(err);
        return { isExtensionInstalled: false, isExtensionLoggedIn: false };
    }
});
const isChromeSendMessageDefined = () => {
    var _a;
    if (typeof chrome === "undefined")
        return false;
    if (typeof (chrome === null || chrome === void 0 ? void 0 : chrome.runtime) === "undefined")
        return false;
    if (typeof ((_a = chrome === null || chrome === void 0 ? void 0 : chrome.runtime) === null || _a === void 0 ? void 0 : _a.sendMessage) === "undefined")
        return false;
    return true;
};
const checkIsScriptInjectedDelayed = () => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(!!document.getElementsByClassName(EXTENSION_INSTALLED).length);
        }, 2000);
    });
});
export function sendMessageToExtension(message) {
    return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        const isContentScriptInjected = !!document.getElementsByClassName(EXTENSION_INSTALLED).length;
        if (isFirefox() || isContentScriptInjected) {
            if (!isContentScriptInjected) {
                const isInjectedDelayed = yield checkIsScriptInjectedDelayed();
                if (!isInjectedDelayed)
                    reject(EXTENSION_NOT_INSTALLED_MESSAGE);
            }
            const messageId = uuid();
            const timeoutId = setTimeout(() => {
                window.removeEventListener("message", listener);
                reject(new Error("Response timeout"));
            }, 5000);
            const listener = (event) => {
                if (event.data.type === EXTERNAL_FIREFOX_MESSAGE_RESPONSE &&
                    event.data.messageId === messageId) {
                    clearTimeout(timeoutId);
                    window.removeEventListener("message", listener);
                    resolve(event.data.response);
                }
            };
            window.addEventListener("message", listener);
            window.postMessage({
                type: EXTERNAL_FIREFOX_MESSAGE,
                message,
                messageId,
            });
        }
        else if (!isChromeSendMessageDefined()) {
            return reject(EXTENSION_NOT_INSTALLED_MESSAGE);
        }
        else {
            chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
                if (chrome.runtime.lastError) {
                    reject(new Error(chrome.runtime.lastError.message));
                }
                else {
                    resolve(response);
                }
            });
        }
    }));
}
