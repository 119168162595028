export const checkIsUrl = (url) => {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
};
export const isYoutubeVideo = (url) => {
    return url.match(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?([a-zA-Z0-9_-]{11})/);
};
export const YoutubeLiveUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/live\/([a-zA-Z0-9_-]+)$/;
export const isYouTubeLiveVideo = (url) => {
    return url.match(YoutubeLiveUrlRegex);
};
export const isWikipediaUrl = (url) => {
    const regex = /^https:\/\/(?:www\.)?([a-z]{2,})\.wikipedia\.org\/wiki\/(.+)$/;
    return regex.test(url);
};
export const isShortenYouTubeVideo = (url) => {
    return url.match(/^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]{11})/);
};
export const expandShortenYouTubeUrl = (url) => {
    const urlParts = url.split("/");
    const lastSegment = urlParts[urlParts.length - 1];
    const videoId = lastSegment.split("?")[0];
    return `https://www.youtube.com/watch?v=${videoId}`;
};
export const isFirefox = () => {
    return (navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
        ("netscape" in window && / rv:/.test(navigator.userAgent)));
};
export const isGoogleSearchUrl = (url) => {
    try {
        const parsedUrl = new URL(url);
        const isGoogleDomain = /^www\.google\.[a-z]{2,}(?:\.[a-z]{2})?$/.test(parsedUrl.hostname);
        const isSearchPath = parsedUrl.pathname.startsWith("/search");
        return isGoogleDomain && isSearchPath;
    }
    catch (_a) {
        return false;
    }
};
