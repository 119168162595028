import { Box } from "@mui/material"
import { EmojiSettings, UseEmojiPickerType } from "@udecode/plate-emoji"
import { EmojiPcikerNavigation } from "./EmojiPcikerNavigation"
import { EmojiPickerContent } from "./EmojiPickerContent"
import { EmojiPickerPreview } from "./EmojiPickerPreview"
import { EmojiPickerSearch } from "./EmojiPickerSearch"

export const EmojiPicker = ({
    i18n,
    searchValue,
    setSearch,
    clearSearch,
    isSearching,
    hasFound,
    searchResult,
    emoji,
    onSelectEmoji,
    onMouseOver,
    emojiLibrary,
    icons,
    handleCategoryClick,
    focusedCategory,
    visibleCategories,
    refs,
    settings = EmojiSettings,
}: UseEmojiPickerType) => {
    return (
        <Box
            sx={{
                height: 350,
                width: 316,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >
            <EmojiPcikerNavigation
                i18n={i18n}
                emojiLibrary={emojiLibrary}
                icons={icons}
                focusedCategory={focusedCategory}
                onClick={handleCategoryClick}
            />
            <EmojiPickerSearch
                i18n={i18n}
                clearSearch={clearSearch}
                searchValue={searchValue}
                setSearch={setSearch}
            />
            <EmojiPickerContent
                i18n={i18n}
                emojiLibrary={emojiLibrary}
                isSearching={isSearching}
                searchResult={searchResult}
                visibleCategories={visibleCategories}
                settings={settings}
                onSelectEmoji={onSelectEmoji}
                onMouseOver={onMouseOver}
                refs={refs}
            />
            <EmojiPickerPreview
                i18n={i18n}
                emoji={emoji}
                hasFound={hasFound}
                isSearching={isSearching}
            />
        </Box>
    )
}
