import { Box } from "@mui/material"
import { useMemo } from "react"
import { extractSearchText } from "./helpers/extractSearchText"

export const TagsTreeText = ({ searchText, label, selected, isCard = false }) => {
    const tagLabel = useMemo(() => {
        if (!searchText) {
            return label.length > 25 && !isCard ? label.slice(0, 22).trim() + "..." : label
        }

        const extractedSearch = extractSearchText(label, searchText)

        if (!extractedSearch) {
            return label.length > 25 && !isCard ? label.slice(0, 22).trim() + "..." : label
        }

        let { beforeSearch, afterSearch, searchedText } = extractedSearch
        const maxTotalLength = 25

        if (searchedText.length >= maxTotalLength) {
            searchedText =
                (beforeSearch.length > 0 ? "..." : "") +
                searchedText.slice(searchText.length - maxTotalLength)
            beforeSearch = ""
            afterSearch = ""
        } else {
            const searchedTextLength = searchedText.length
            const remainingLength = maxTotalLength - searchedTextLength

            if (beforeSearch.length + afterSearch.length > remainingLength) {
                let beforeLength = Math.ceil(remainingLength / 2)
                let afterLength = Math.floor(remainingLength / 2)

                if (beforeSearch.length < beforeLength) {
                    afterLength += beforeLength - beforeSearch.length
                    beforeLength = beforeSearch.length
                } else if (afterSearch.length < afterLength) {
                    beforeLength += afterLength - afterSearch.length
                    afterLength = afterSearch.length
                }

                if (beforeSearch.length > beforeLength) {
                    beforeSearch = "..." + beforeSearch.slice(-beforeLength)
                }
                if (afterSearch.length > afterLength) {
                    afterSearch = afterSearch.slice(0, afterLength) + "..."
                }
            }
        }

        return (
            <>
                {beforeSearch}
                <Box component="b" sx={{ color: selected ? undefined : "primary.light" }}>
                    {searchedText}
                </Box>
                {afterSearch}
            </>
        )
    }, [searchText, label, selected])

    return tagLabel
}
