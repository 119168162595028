import { MAX_DESCRIPTION_LENGTH } from "../constants"

export const slugify = (value: string) => {
    return value.replace(/ /g, "_").toLowerCase()
}

export const splitStringOnIndex = (str: string, index: number) => {
    const leftValue = str.substring(0, index)
    const rightValue = str.substring(index)

    return [leftValue, rightValue]
}

export const truncateText = (text: string, length = MAX_DESCRIPTION_LENGTH) => {
    let truncatedText = text
    let truncated = false
    const words = text.split(" ")

    if (text.length > length) {
        truncated = true
        truncatedText = ""

        for (const word of words) {
            if (truncatedText.length >= length) {
                break
            }
            truncatedText += `${word} `
        }
        truncatedText = truncatedText.replace(/(,|:|;)*$/, "")
        truncatedText = `${truncatedText.trim()}...`
    }

    return [truncatedText, truncated]
}

export const count = (pin: string, haystack: string) => {
    return (haystack.match(new RegExp(pin, "g")) || []).length
}
