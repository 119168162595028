import { HOME_PATH } from "constants/routes"
import produce from "immer"
import {
    ADD_LOADING_QUESTION_ITEM_ID,
    INCREASE_VISIBLE_ITEMS,
    REMOVE_LOADING_QUESTION_ITEM_ID,
    RESET_SCROLL_STATE,
    SET_APP_LOADING,
    SET_BREADCRUMBS,
    SET_BRIGHTNESS_MODE,
    SET_EXTENSION_STATE,
    SET_IMPORT_STATE,
    SET_IS_CREATING_EXTENSION_ITEMS,
    SET_IS_INITIAL_SYNC_FINISHED,
    SET_IS_NATIVE,
    SET_IS_SCRAPING_MODAL_OPEN,
    SET_IS_UPGRADE_MODAL_OPEN,
    SET_LOADING,
    SET_SCROLL_POSITION,
    SET_USER_LOADING,
} from "./actionTypes"
import { AppState, extensionStateEnum, importEnum, themeModeEnum } from "./types"

const ITEMS_PER_PAGE = 25
export const defaultBreadcrumb = { label: "Home", path: HOME_PATH }

const initialState: AppState = {
    platform: "web",
    isNative: false,
    themeMode: themeModeEnum.DARK,
    loading: false,
    appLoading: false,
    userLoading: true,
    importState: importEnum.CLOSED,
    extensionState: extensionStateEnum.NOT_LOGGED_IN,
    isUpgradePlanModalOpen: false,
    isScrapingModalOpen: false,
    visibleItems: ITEMS_PER_PAGE,
    scrollPosition: 0,
    questionsLoadingItemIds: [],
    breadcrumbs: [defaultBreadcrumb],
    isInitialSyncFinished: false,
    isCreatingExtensionItems: true,
}

export const appReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case SET_BRIGHTNESS_MODE: {
                const themeMode = action.payload
                draftState.themeMode = themeMode
                break
            }
            case SET_LOADING: {
                const loading = action.payload
                draftState.loading = loading
                break
            }
            case SET_IMPORT_STATE: {
                draftState.importState = action.payload
                break
            }
            case SET_APP_LOADING: {
                draftState.appLoading = action.payload
                break
            }
            case SET_EXTENSION_STATE: {
                draftState.extensionState = action.payload
                break
            }
            case SET_IS_UPGRADE_MODAL_OPEN: {
                draftState.isUpgradePlanModalOpen = action.payload
                break
            }

            case SET_IS_SCRAPING_MODAL_OPEN: {
                draftState.isScrapingModalOpen = action.payload
                break
            }
            case INCREASE_VISIBLE_ITEMS: {
                draftState.visibleItems = state.visibleItems + ITEMS_PER_PAGE
                break
            }
            case SET_SCROLL_POSITION: {
                draftState.scrollPosition = action.payload
                break
            }
            case RESET_SCROLL_STATE: {
                draftState.visibleItems = ITEMS_PER_PAGE
                draftState.scrollPosition = 0
                break
            }
            case SET_USER_LOADING: {
                draftState.userLoading = action.payload
                break
            }
            case SET_IS_NATIVE: {
                draftState.isNative = true
                draftState.platform = action.payload
                break
            }
            case ADD_LOADING_QUESTION_ITEM_ID: {
                draftState.questionsLoadingItemIds = [
                    ...state.questionsLoadingItemIds,
                    action.payload,
                ]
                break
            }
            case REMOVE_LOADING_QUESTION_ITEM_ID: {
                draftState.questionsLoadingItemIds = state.questionsLoadingItemIds.filter(
                    (id) => id !== action.payload
                )
                break
            }
            case SET_BREADCRUMBS: {
                draftState.breadcrumbs = action.payload
                break
            }
            case SET_IS_INITIAL_SYNC_FINISHED: {
                draftState.isInitialSyncFinished = action.payload
                break
            }
            case SET_IS_CREATING_EXTENSION_ITEMS: {
                draftState.isCreatingExtensionItems = action.payload
                break
            }

            default:
                return state
        }
    })
