import { Box, SxProps, Theme } from "@mui/material"
import { PlateElement, PlateElementProps, Value } from "@udecode/plate-common"
import { TLinkElement, useLink } from "@udecode/plate-link"
import React from "react"

export const LinkElement = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    PlateElementProps<Value, TLinkElement>
>(({ className, children, ...props }, ref) => {
    const { props: linkProps } = useLink({ element: props.element })

    return (
        <PlateElement asChild ref={ref} {...linkProps} {...(props as any)}>
            <Box component="a" sx={styles}>
                {children}
            </Box>
        </PlateElement>
    )
})

const styles: SxProps<Theme> = {
    fontWeight: 600,
    color: "text.secondary",
}
