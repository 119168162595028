const formatLink = (url, text) => {
    return `[${text}](${url})`;
};
const formatImage = (url, text) => {
    return `![${text !== null && text !== void 0 ? text : ""}](${url})\n\n`;
};
const formatListItem = (blockText) => {
    return `- ${blockText}\n`;
};
const formatHeading = (text, level) => {
    return `\n${"#".repeat(level)} ${text}\n`;
};
export const markdownService = { formatLink, formatImage, formatListItem, formatHeading };
