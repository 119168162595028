import { Model, Q, Query, Relation } from "@nozbe/watermelondb"
import { HasManyAssociation } from "@nozbe/watermelondb/Model"
import { children, date, field, lazy, relation, writer } from "@nozbe/watermelondb/decorators"
import { ItemPartial, Source, sentry } from "@recall/common"
import { compact, map, orderBy } from "lodash"
import { v4 as uuidv4 } from "uuid"
import { EditorBlockData } from "../../../services/editorData/EditorBlockData"
import { tagRepository } from "../repository"
import { ITEM_TAG, TAGS } from "../schema"
import { dbUtils } from "../utils"
import { AssetModel } from "./AssetModel"
import { ConnectionModel } from "./ConnectionModel"
import { EditorBlockModel } from "./EditorBlockModel"
import { EditorOrderModel } from "./EditorOrderModel"
import { ItemTagModel } from "./ItemTagModel"
import { QuestionModel } from "./QuestionModel"
import { SourceModel } from "./SourceModel"
import { TagModel } from "./TagModel"
import { TypeModel } from "./TypeModel"

export class ItemModel extends Model {
    static table = "items"

    static associations = {
        sources: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        editor_orders: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        editor_blocks: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        item_tag: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        review_questions: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        question_reviews: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
        assets: { type: "has_many", foreignKey: "item_id" } as HasManyAssociation,
    }

    @field("name") name: string
    @field("is_saved") isSaved: boolean
    @field("is_reference") isReference: boolean
    @field("is_expanded") isExpanded: boolean
    @field("language") language: string
    @field("description") description: string
    @field("image") image: string

    @children("sources") sources: Query<SourceModel>
    @children("editor_orders") editorOrders: Query<EditorOrderModel>
    @children("editor_blocks") editorBlocks: Query<EditorBlockModel>
    @children("review_questions") questions: Query<QuestionModel>
    @children("assets") assets: Query<AssetModel>

    @relation("types", "type_id") type: Relation<TypeModel>

    @date("created_at") createdAt
    @date("updated_at") updatedAt

    @lazy links = this.collections
        .get<ConnectionModel>("connections")
        .query(Q.where("from_id", Q.eq(this.id)))

    @lazy mentions = this.collections
        .get<ConnectionModel>("connections")
        .query(Q.where("to_id", Q.eq(this.id)))

    @lazy itemTags = this.collections.get<ItemTagModel>(ITEM_TAG).query(Q.where("item_id", this.id))

    @lazy tags = this.collections
        .get<TagModel>(TAGS)
        .query(Q.on(ITEM_TAG, Q.where("item_id", this.id)))

    // Returns in chronological order
    getTags = async () => {
        const itemTags = await this.itemTags.fetch()
        const orderedItemTags = orderBy(itemTags, "createdAt")
        const tags = (
            await Promise.all(
                orderedItemTags.map(async (itemTag) => {
                    try {
                        const tag = await itemTag.tag.fetch()
                        return tag
                    } catch (e) {
                        sentry.captureException(e)
                        return null
                    }
                })
            )
        ).filter(Boolean)
        return compact(tags)
    }

    getLinkedItems = async () => {
        const links = await this.links.fetch()
        const linkedItems = await Promise.all(
            links.map(async (link: ConnectionModel) => await link.to.fetch())
        )
        return linkedItems
    }

    getMentionedItems = async () => {
        const mentions = await this.mentions.fetch()
        const mentionedItems = await Promise.all(
            mentions.map(async (mention: ConnectionModel) => await mention.from.fetch())
        )
        return mentionedItems
    }

    getOrderedEditorBlocks = async () => {
        const editorOrders = await this.editorOrders.fetch()
        const editorBlocks = await this.editorBlocks.fetch()

        if (editorOrders.length === 0) {
            return []
        }

        return EditorBlockData.getOrderedModels(editorBlocks, editorOrders[0]) as EditorBlockModel[]
    }

    getEditorOrder = async () => {
        return (await this.editorOrders.fetch())[0]
    }

    toItemPartial = async (): Promise<ItemPartial> => {
        const [sources, editorBlockModels] = await Promise.all([
            (await this.sources.fetch()).map((source: SourceModel) => source.toSource()),
            this.getOrderedEditorBlocks(),
        ])

        const editorBlocks = editorBlockModels.map((editorBlock: EditorBlockModel) =>
            editorBlock.toEditorBlock()
        )

        const description = EditorBlockData.getText(editorBlocks)
        const image = EditorBlockData.getFirstImageBlock(editorBlocks)

        const images = []

        if (image) {
            images.push(image)
        }

        return {
            id: this.id,
            name: this.name,
            description: description,
            images: images,
            editorBlocks: editorBlocks,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            isReference: this.isReference,
            isSaved: this.isSaved,
            sources: sources,
            language: this.language,
        }
    }

    getSource = async (sourceName: string): Promise<SourceModel> => {
        const sources = await this.sources.fetch()
        for (let source of sources) {
            if (source.name === sourceName) {
                return source
            }
        }
    }

    @writer async setIsReference(isReference: boolean) {
        await this.update((record) => {
            record.isReference = isReference
        })
    }

    @writer async setCreatedAt(createdAt: number) {
        await this.update((record) => {
            record.createdAt = createdAt
        })
    }

    @writer async setUpdatedAt(createdAt: number) {
        await this.update((record) => {
            record.updatedAt = createdAt
        })
    }

    @writer async setIsExpanded(isExpanded: boolean) {
        await this.update((record) => {
            record.isExpanded = isExpanded
        })
    }

    @writer async merge(item: ItemModel) {
        let tasks = []

        const editorBlocks = await this.getOrderedEditorBlocks()
        let newEditorBlocks = await item.getOrderedEditorBlocks()

        let nextEditorOrder = []
        newEditorBlocks = newEditorBlocks.filter(
            (editorBlock) =>
                !JSON.stringify(editorBlock.children).includes('"type":"image"') &&
                editorBlock.type !== "h1" &&
                editorBlock.type !== "img"
        )
        nextEditorOrder = [...map(editorBlocks, "id"), ...map(newEditorBlocks, "id")]

        const editorOrderModel = await this.getEditorOrder()

        if (editorOrderModel)
            tasks.push(
                editorOrderModel.prepareUpdate((record) => {
                    record.order = nextEditorOrder
                })
            )
        else
            tasks.push(
                this.collections.get("editor_orders").prepareCreate((record: EditorOrderModel) => {
                    record._raw.id = uuidv4()
                    record.item.id = this.id
                    record.order = nextEditorOrder
                    record.isSaved = this.isSaved
                })
            )

        const editorBlocksToSaveTasks = newEditorBlocks.map((editorBlock) =>
            editorBlock.prepareUpdate((record) => {
                record.item.id = this.id
                record.isSaved = true
            })
        )

        tasks = [...tasks, ...editorBlocksToSaveTasks]

        tasks.push(
            this.prepareUpdate((record) => {
                record.isExpanded = true
                record.isReference = false
            })
        )

        const existingLinks = await this.links.fetch()
        const links = await item.links.fetch()
        const linkedItems = await Promise.all(
            links.map(async (link: ConnectionModel) => await link.to.fetch())
        )

        for (const linkedItem of linkedItems) {
            const linkedItemTasks = await linkedItem.prepareSave()
            tasks = [...tasks, ...linkedItemTasks]
        }

        for (const link of links) {
            if (existingLinks.some((existingLink) => existingLink.to.id === link.to.id)) continue

            tasks.push(
                link.prepareUpdate((record) => {
                    record.from.id = this.id
                    record.isSaved = true
                })
            )
        }

        const existingTags = await this.tags.fetch()

        const itemTagsToSet = (await item.itemTags.fetch()).filter((itemTag) =>
            existingTags.every((tag) => tag.id !== itemTag.tag.id)
        )

        const tagIdsToCreate = map(itemTagsToSet, "tag.id")

        const tagsToCreate = (await item.tags.fetch()).filter((tag) =>
            tagIdsToCreate.includes(tag.id)
        )

        for (const tag of tagsToCreate) {
            const tagToCreate = await tag.prepareSave()
            if (!tagToCreate) continue
            tasks = [...tasks, ...tagToCreate]
        }

        const itemTagTasks = itemTagsToSet.map((itemTag) =>
            itemTag.prepareUpdate((record) => {
                record.item.set(this)
                record.isSaved = true
            })
        )

        const sourceIdentifiers = (await this.sources.fetch()).map((source) => source.identifier)
        const newSources = await item.sources.fetch()
        const sourceTasks = newSources
            .filter((source) => !sourceIdentifiers.includes(source.identifier))
            .map((source) =>
                source.prepareUpdate((record) => {
                    record.item.set(this)
                })
            )
        const sourceDeleteTasks = newSources
            .filter((source) => sourceIdentifiers.includes(source.identifier))
            .map((source) => source.prepareDelete())

        tasks = [...tasks, ...sourceTasks, ...sourceDeleteTasks, ...itemTagTasks]

        tasks.push(item.prepareDestroyPermanently())
        await this.batch(...tasks)
        await this.callWriter(() => this.updateItemTagReferenceStatus())
    }

    @writer async updateItemTagReferenceStatus() {
        const tags = await this.tags.fetch()
        await this.callWriter(() =>
            tagRepository.updateTagsReferenceState(this.database, tags, this)
        )
    }

    @writer async saveDeep() {
        let tasks = await this.prepareSaveDeep()
        await this.batch(...tasks)
    }

    async searchEditorBlocks(searchTerm: string) {
        return await this.editorBlocks
            .extend(Q.where("text", Q.like(`%${dbUtils.sanitizeSearchTerm(searchTerm)}%`)))
            .fetch()
    }

    prepareSaveDeep = async () => {
        let tasks = await this.prepareSave()
        const itemsToSave = await this.getLinkedItems()

        for (let itemModel of itemsToSave) {
            // TODO - does this make sense
            // const moreTasks = await itemModel.prepareSaveDeep()
            const moreTasks = await itemModel.prepareSave()
            tasks = [...tasks, ...moreTasks]
        }

        return tasks
    }

    prepareSave = async () => {
        let tasks = []

        if (this.isSaved === false) {
            tasks.push(
                this.prepareUpdate((record) => {
                    record.isSaved = true
                })
            )

            const sourceTasks = (await this.sources.fetch()).map((source) => source.prepareSave())
            const editorBlockTasks = (await this.editorBlocks.fetch()).map((editorBlock) =>
                editorBlock.prepareSave()
            )
            const editorOrderTasks = (await this.editorOrders.fetch()).map((editorOrder) =>
                editorOrder.prepareSave()
            )
            const connectionTasks = (await this.links.fetch()).map((link) => link.prepareSave())
            const tagsTasks = (
                await Promise.all((await this.tags.fetch()).map((tag) => tag.prepareSave()))
            ).flat()

            const itemTagTasks = (await this.itemTags.fetch()).map((itemTag) =>
                itemTag.prepareSave()
            )

            tasks = [
                ...tasks,
                ...sourceTasks,
                ...editorBlockTasks,
                ...editorOrderTasks,
                ...connectionTasks,
                ...tagsTasks,
                ...itemTagTasks,
            ]
        }

        return tasks
    }

    @writer async save() {
        const tasks = await this.prepareSave()
        await this.batch(...tasks)
    }

    @writer async updateName(name: string) {
        await this.update((record) => {
            record.name = name
        })
    }

    @writer async updateImage(image: string) {
        await this.update((record) => {
            record.image = image
        })
    }

    @writer async updateDescription(description: string) {
        await this.update((record) => {
            record.description = description
        })
    }

    @writer async updateLanguage(language: string) {
        await this.update((record) => {
            record.language = language
        })
    }

    @writer async updateUpdatedAt() {
        await this.update((record) => {})
    }

    prepareAddSource = (source: Source, isSaved = true) => {
        return this.collections.get("sources").prepareCreate((record: SourceModel) => {
            record._raw.id = source.id
            record.item.set(this)
            record.name = source.name
            record.identifier = source.identifier
            record.isSaved = isSaved
        })
    }

    @writer async addSource(source: Source, isSaved = true) {
        const task = this.prepareAddSource(source, isSaved)
        await this.batch(task)
    }

    @writer async addSources(sources: Source[], isSaved = true) {
        const collection = this.collections.get("sources")
        const tasks = sources.map((source) =>
            collection.prepareCreate((record: SourceModel) => {
                record._raw.id = source.id
                record.item.set(this)
                record.name = source.name
                record.identifier = source.identifier
                record.isSaved = isSaved
            })
        )

        await this.batch(...tasks)
    }

    @writer async addEditorBlocks(editorBlocks: any[], isSaved = true) {
        const collection = this.collections.get("editor_blocks")
        const tasks = editorBlocks.map((editorBlock) => {
            const { id, children, type, ...options } = editorBlock

            return collection.prepareCreate((record: EditorBlockModel) => {
                record._raw.id = id
                record.item.set(this)
                record.children = children
                record.isSaved = isSaved
                record.type = type
                record.options = options || {}
                record.text = EditorBlockData.getFormattedText([editorBlock])
            })
        })

        const editorOrder = editorBlocks.map((editorBlock) => editorBlock.id)

        tasks.push(
            this.collections.get("editor_orders").prepareCreate((record: EditorOrderModel) => {
                record._raw.id = uuidv4()
                record.item.id = this.id
                record.order = editorOrder
                record.isSaved = isSaved
            })
        )

        await this.batch(...tasks)
    }

    // TODO delete, prepareDelete, etc can be shared in base class.

    @writer async delete() {
        const tasks = await this.prepareDelete()
        await this.batch(...tasks)
    }

    prepareDelete = async () => {
        let tasks = []

        // @ts-ignore
        if (this._preparedState === null) {
            if (this.isSaved) {
                tasks.push(this.prepareMarkAsDeleted())
            } else {
                tasks.push(this.prepareDestroyPermanently())
            }
        }

        const sources = await this.sources.fetch()
        const editorBlocks = await this.editorBlocks.fetch()
        const editorOrders = await this.editorOrders.fetch()
        const mentions = await this.mentions.fetch()
        const links = await this.links.fetch()
        const itemTags = await this.itemTags.fetch()
        const questions = await this.questions.fetch()

        tasks = [
            ...tasks,
            ...(
                await Promise.all(links.map(async (l) => await l.prepareDeleteWithStaleItem(false)))
            ).flat(),
            ...mentions.map((m) => m.prepareDelete()),
            ...sources.map((s) => s.prepareDelete()),
            ...editorBlocks.map((eb) => eb.prepareDelete()),
            ...editorOrders.map((eo) => eo.prepareDelete()),
            ...itemTags.map((t) => t.prepareDelete()),
            ...questions.map((q) => q.prepareDelete()),
        ]

        return tasks
    }

    isStale = async (limit = 0) => {
        // Limit will be set as 1, where delete of mention is being batched.

        if (this.isSaved === false) {
            return true
        }

        // if (this.isReference) {
        //     const mentions = await this.mentions.fetch()
        //     const sources = await this.sources.fetch()

        //     if (mentions.length <= limit && sources.length !== 0) {
        //         return true
        //     }
        // }
        return false
    }

    isEmpty = async () => {
        const orderedEditorBlocks = await this.getOrderedEditorBlocks()
        const text = EditorBlockData.getText(orderedEditorBlocks)

        if (this.name === "" && text.length === 0) {
            return true
        }

        return false
    }
}
