import { Model } from "@nozbe/watermelondb"
import { field } from "@nozbe/watermelondb/decorators"
import { CONNECTION_PROPERTIES } from "../schema"
export class ConnectionPropertyModel extends Model {
    static table = CONNECTION_PROPERTIES

    //@ts-ignore
    @field("name") name: string
    //@ts-ignore
    @field("display") display: string
    //@ts-ignore
    @field("description") description: string
    //@ts-ignore
    @field("plural_display") pluralDisplay: string
    //@ts-ignore
    @field("wikidata_pid") wikidataPid: string
    //@ts-ignore
    @field("is_saved") isSaved: boolean

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: ConnectionPropertyModel) => {
                record.isSaved = true
            })
        }

        return null
    }
}
