export const SOURCE_SAVED = "saved";
export const WIKIPEDIA = "wikipedia";
export const WEBSITE = "website";
export const WIKIDATA = "wikidata";
export const ROOT_TYPE_NAME = "Thing";
export const ROOT_TYPE_ID = ROOT_TYPE_NAME;
export const SHOW_LOGGING_IN_PAGE_QUERY_PARAM = "extension_login";
export const IS_EXTENSION_TUTORIAL_CARD = "isExtensionTutorialCard";
export const EXTENSION_INSTALLED = "recall-extension-installed";
export const EXTERNAL_FIREFOX_MESSAGE = "recall-external-firefox-message";
export const EXTERNAL_FIREFOX_MESSAGE_RESPONSE = "recall-external-firefox-message-response";
export * from "./collections";
export * from "./languages";
export * from "./routes";
export * from "./tutorial";
