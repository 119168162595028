import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { date, field, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { ItemModel } from "./ItemModel"

export const ASSET_TYPES = {
    IMAGE: "image",
    IMAGE_URL: "image_url",
}

export class AssetModel extends Model {
    static table = "assets"

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @relation("items", "item_id") item: Relation<ItemModel>
    @field("size") size: number
    @field("url") url: string
    @field("type") type: string
    @readonly @date("created_at") createdAt!: Date

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: AssetModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        // @ts-ignore
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }
}
