import { v4 as uuidv4 } from "uuid";
export const createEmptyItem = () => {
    return {
        id: uuidv4(),
        name: "",
        description: "",
        isSaved: true,
        isReference: false,
        sources: [],
        editorBlocks: [],
        images: [],
        links: [],
        mentions: [],
        language: "en",
    };
};
