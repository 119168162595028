import { Database } from "@nozbe/watermelondb"
import { posthogService } from "@recall/common"
import { EXPORT_ALL_TO_MARKDOWN, EXPORT_CARD_TO_MARKDOWN } from "constants/events"
import saveAs from "file-saver"
import JSZip from "jszip"
import { ItemModel, TagModel } from "storage/watermelon/models"
import { ROOT_TAG_ID, itemRepository, tagRepository } from "storage/watermelon/repository"
import { dates } from "../../utils/dates"
import { initEditorData } from "../editorData/initEditorData"
import { markdownSerializer } from "./markdownSerializer"

const getZipName = () => {
    return `Recall_export_${dates.formatNow()}.zip`
}

const addItemsToFolder = async (
    db: Database,
    tag: TagModel,
    parentFolder: JSZip
): Promise<void> => {
    const items = await tagRepository.getItemsByTagIds(db, [tag.id])

    for (const item of items) {
        const data = await getMarkDownData(db, item.id)
        const fileName = getFileName(item)
        parentFolder.file(fileName, data)
    }

    const childrenTags = await tagRepository.getByParentTagIds(db, [tag.id])
    for (const childTag of childrenTags) {
        const folder = parentFolder.folder(childTag.name)
        await addItemsToFolder(db, childTag, folder)
    }
}

const getFileName = (item: ItemModel) => {
    return `${item.name}.md`
}

const getMarkDownData = async (db: Database, itemId: string) => {
    const value = await initEditorData(db, itemId)
    const markdown = await markdownSerializer.serialize(value, db)
    const data = new Blob([markdown], { type: "text/markdown" })
    return data
}

const exportCardToMarkdown = async (db: Database, item: ItemModel) => {
    posthogService.captureEvent(EXPORT_CARD_TO_MARKDOWN, { card: item.name })
    const data = await getMarkDownData(db, item.id)
    const fileName = getFileName(item)
    saveAs(data, fileName)
}

const exportAllToMarkdown = async (db: Database) => {
    posthogService.captureEvent(EXPORT_ALL_TO_MARKDOWN)
    const zip = new JSZip()
    const tag = await tagRepository.get(db, ROOT_TAG_ID)
    await addItemsToFolder(db, tag, zip)
    const items = await itemRepository.getUntaggedItems(db)
    for (const item of items) {
        const data = await getMarkDownData(db, item.id)
        const fileName = getFileName(item)
        zip.file(fileName, data)
    }

    const data = await zip.generateAsync({ type: "blob" })
    saveAs(data, getZipName())
}

export const exportService = { exportCardToMarkdown, exportAllToMarkdown }
