import { Box, Button, Typography } from "@mui/material"
import { ItemApi, ItemChip, ItemPartial, languages } from "@recall/common"
import { FC, useEffect, useState } from "react"
import { ItemModel } from "storage/watermelon/models"

interface Props {
    handleChange: (itemApi: ItemApi, language: string) => void
    itemApi: ItemApi
    item: ItemModel
    selectedLanguage: string
}

export const ConfirmLanguageStep: FC<Props> = ({
    handleChange,
    itemApi,
    item,
    selectedLanguage,
}) => {
    const [itemsToDelete, setItemsToDelete] = useState<ItemPartial[]>([])

    const getItemsToDelete = async () => {
        const items = await item.getLinkedItems()

        const itemsToDelete = []
        for (const item of items) {
            const backlinksCount = await item.mentions.count
            if (backlinksCount === 1) {
                itemsToDelete.push(item)
            }
        }

        const itemPartials = await Promise.all(
            itemsToDelete.map(async (itemToDelete) => await itemToDelete.toItemPartial())
        )

        setItemsToDelete(itemPartials)
    }

    useEffect(() => {
        getItemsToDelete()
    }, [])

    return (
        <Box px={3} pt={1} pb={2}>
            <Typography>
                Content from this card will be removed and replaced with content in{" "}
                <b>{languages[selectedLanguage].label}</b>
            </Typography>
            {Boolean(itemsToDelete.length) && (
                <Box py={1} pt={0.5}>
                    <Typography>
                        Linked cards that don't exist in <b>{languages[selectedLanguage].label}</b>{" "}
                        will be deleted:
                    </Typography>
                    <Box pt={1}>
                        {itemsToDelete.map((item) => (
                            <ItemChip
                                key={item.id}
                                name={item.name}
                                description={item.description}
                                image={item?.images?.[0]}
                            />
                        ))}
                    </Box>
                </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
                <Button
                    onClick={() => {
                        handleChange(itemApi, selectedLanguage)
                    }}
                    color="secondary"
                    variant="contained"
                >
                    Confirm
                </Button>
            </Box>
        </Box>
    )
}
