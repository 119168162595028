import { useDatabase } from "@nozbe/watermelondb/react"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { itemRepository } from "storage/watermelon/repository"
import { SearchItem, filterItems } from "../helpers/searchItems"
import { RERENDER_TAGS_EVENT } from "./useGroupedTags"

export const useUntaggedItems = ({ searchText }) => {
    const [searchedUntaggedItems, setSearchedUntaggedItems] = useState<SearchItem[]>([])
    const [untaggedItems, setUntaggedItems] = useState<SearchItem[]>([])
    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )
    const db = useDatabase()

    useEffect(() => {
        filterUntaggedCards()
    }, [searchText, untaggedItems, includeReferences])

    useEffect(() => {
        document.addEventListener(RERENDER_TAGS_EVENT, getUntaggedItems)
        const subscription = itemRepository.observeCount(db).subscribe(getUntaggedItems)

        return () => {
            subscription.unsubscribe()
            document.addEventListener(RERENDER_TAGS_EVENT, getUntaggedItems)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, includeReferences])

    const getUntaggedItems = useCallback(async () => {
        const items = await itemRepository.getUntaggedItems(db)
        const itemsWithImage = await Promise.all(items.map((item) => ({ item, image: item.image })))
        return setUntaggedItems(itemsWithImage)
    }, [includeReferences])

    const filterUntaggedCards = async () => {
        let filteredItems = untaggedItems
        if (!includeReferences) {
            filteredItems = filteredItems.filter(({ item }) => !item.isReference)
        }

        if (!searchText) {
            setSearchedUntaggedItems(filteredItems)
            return
        }

        const items = await filterItems(filteredItems, searchText)
        setSearchedUntaggedItems(items)
    }

    return { untaggedItems: searchedUntaggedItems }
}
