import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import { FC } from "react"

interface VariantProps {
    variant: "ul" | "ol"
}

type ListElementProps = PlateElementProps & VariantProps

export const ListElement: FC<ListElementProps> = ({
    className,
    children,
    variant = "ul",
    ...props
}) => {
    const Element = variant!

    return (
        <PlateElement asChild {...props}>
            <Element>{children}</Element>
        </PlateElement>
    )
}
