import { Box, IconButton } from "@mui/material"
import { EmojiCategoryList, IEmojiFloatingLibrary, UseEmojiPickerType } from "@udecode/plate-emoji"
export type EmojiPickerNavigationProps = Pick<
    UseEmojiPickerType,
    "i18n" | "emojiLibrary" | "icons" | "focusedCategory"
> & {
    onClick: (id: EmojiCategoryList) => void
}

const getBarProperty = (
    emojiLibrary: IEmojiFloatingLibrary,
    focusedCategory?: EmojiCategoryList
) => {
    let width = 0
    let position = 0
    if (focusedCategory) {
        width = 100 / emojiLibrary.getGrid().size
        position = focusedCategory ? emojiLibrary.indexOf(focusedCategory) * 100 : 0
    }

    return { width, position }
}

export const EmojiPcikerNavigation = ({
    i18n,
    icons,
    emojiLibrary,
    focusedCategory,
    onClick,
}: EmojiPickerNavigationProps) => {
    const { width, position } = getBarProperty(emojiLibrary, focusedCategory)
    return (
        <Box display="flex" position="relative" justifyContent="space-around">
            {emojiLibrary
                .getGrid()
                .sections()
                .map(({ id }) => (
                    <IconButton
                        key={id}
                        aria-label={i18n.categories[id]}
                        title={i18n.categories[id]}
                        onClick={() => onClick(id)}
                        size="small"
                    >
                        <Box
                            sx={{
                                fill: (theme) =>
                                    focusedCategory === id
                                        ? theme.palette.secondary.main
                                        : theme.palette.text.primary,
                                width: 20,
                                height: 20,
                            }}
                        >
                            {icons.categories[id].outline}
                        </Box>
                    </IconButton>
                ))}
            <Box
                sx={{
                    position: "absolute",
                    bottom: -3,
                    left: 0,
                    height: 3,
                    visibility: `${focusedCategory ? "visible" : "hidden"}`,
                    width: `${width}%`,
                    transform: `translateX(${position}%)`,
                    background: (theme) => theme.palette.secondary.main,
                    transition: "transform 200ms",
                    borderRadius: 1.5,
                }}
            />
        </Box>
    )
}
