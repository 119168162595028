import { Database, Q } from "@nozbe/watermelondb"
import { ROOT_TYPE_NAME, Type } from "@recall/common"
import { map } from "lodash"
import pluralize from "pluralize"
import { TypeModel } from "../models"
import { TYPES } from "../schema"

const search = async (db: Database, searchTerm: string, limit = 15): Promise<TypeModel[]> => {
    const types = await db.collections
        .get<TypeModel>(TYPES)
        .query(
            Q.or(
                Q.where("display", Q.like(`${Q.sanitizeLikeString(searchTerm)}%`)),
                Q.where("plural_display", Q.like(`${Q.sanitizeLikeString(searchTerm)}%`))
            )
        )
        .fetch()

    if (types.length > limit) return types.slice(0, limit)

    return types
}

const getByName = async (db: Database, name: string) => {
    const type = await db.collections.get<TypeModel>(TYPES).query(Q.where("name", name)).fetch()

    return type?.[0] || null
}

const get = async (db: Database, id: string) => {
    try {
        return await db.collections.get<TypeModel>(TYPES).find(id)
    } catch (e) {
        return null
    }
}

const create = async (db: Database, type: Type, isSaved = true) => {
    const newType = await db.write<TypeModel>(async (writer) => {
        let existingType = await getByName(db, type.name)

        if (existingType) return existingType

        existingType = await get(db, type.id)

        if (existingType && existingType._raw["_status"] === "deleted")
            await writer.callWriter(() => existingType.unDelete())

        if (existingType) return existingType

        let parentType: TypeModel

        if (type.parentId) {
            parentType = await get(db, type.parentId)
        } else {
            parentType = await getRoot(db)
        }

        const newType = await db.collections.get<TypeModel>(TYPES).create((record: TypeModel) => {
            record._raw.id = type.id
            record.name = type.name
            record.display = type.display
            record.userCreated = type.userCreated
            record.isSaved = isSaved

            record.pluralDisplay = type.pluralDisplay.length
                ? type.pluralDisplay
                : pluralize(type.display, 0)

            if (type.wikidataId) record.wikidataId = type.wikidataId
            if (parentType) record.parent.set(parentType)
        })

        return newType
    })

    return newType
}

const getChildTypesRecursive = async (db: Database, typeId: string, onlyIds = true) => {
    let nestedChildren = []

    const type = await get(db, typeId)

    if (!type) return nestedChildren

    const childTypes = await type.children.fetch()

    if (onlyIds) {
        nestedChildren = map(childTypes, "id")
    } else {
        nestedChildren = [...childTypes]
    }

    for (let childType of childTypes) {
        const moreTypes = await getChildTypesRecursive(db, childType.id, onlyIds)
        nestedChildren = [...nestedChildren, ...moreTypes]
    }

    return [...new Set(nestedChildren)]
}

const getRoot = (db: Database) => {
    return getByName(db, ROOT_TYPE_NAME)
}

const deleteStale = async (db: Database) => {
    const types = await db.collections.get<TypeModel>(TYPES).query().fetch()

    for (const type of types) {
        const isStale = await type.isStale()

        if (!isStale) continue

        await type.delete()
    }
}

export const typeRepository = {
    search,
    getRoot,
    getByName,
    get,
    getChildTypesRecursive,
    create,
    deleteStale,
}
