import { Box } from "@mui/material"
import { PlateLeaf, PlateLeafProps } from "@udecode/plate-common"

export const HighlightLeaf = ({ className, children, ...props }: PlateLeafProps) => {
    return (
        <PlateLeaf asChild {...props}>
            <Box sx={{ background: (theme) => theme.palette.secondary.light }} component="mark">
                {children}
            </Box>
        </PlateLeaf>
    )
}
