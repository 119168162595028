var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import posthog from "posthog-js";
import { v4 as uuid } from "uuid";
import { HttpClient, isDevelopment } from "../utils";
const POSTHOG_API_HOST = "https://d2squso3hsjcqf.cloudfront.net";
const API_KEY = "phc_XER41Cya8lZH6DXgQpNpHZb8NRHljrXBBJ8VSOeqBqG";
const posthogHttpClient = new HttpClient(POSTHOG_API_HOST);
class PosthogService {
    constructor() {
        this.init = (config = {}) => {
            if (isDevelopment()) {
                return;
            }
            posthog.init(API_KEY, Object.assign({ api_host: POSTHOG_API_HOST }, config));
        };
        this.setUser = (email) => {
            if (isDevelopment()) {
                return;
            }
            posthog.identify(email, { email });
        };
        this.setUserId = (email) => {
            if (isDevelopment()) {
                return;
            }
            this.userId = email;
        };
        this.captureEvent = (name, properties) => {
            if (isDevelopment()) {
                return;
            }
            posthog.capture(name, properties);
        };
        this.capturePublicApiEvent = (event, properties = {}) => __awaiter(this, void 0, void 0, function* () {
            if (isDevelopment()) {
                return;
            }
            yield posthogHttpClient.post("/capture/", JSON.stringify({
                api_key: API_KEY,
                event,
                properties: Object.assign(Object.assign({}, properties), { distinct_id: this.userId }),
            }));
        });
        this.isFeatureEnabled = (name) => {
            if (isDevelopment()) {
                return true;
            }
            return posthog.isFeatureEnabled(name);
        };
        this.userId = uuid();
    }
}
const posthogService = new PosthogService();
export { posthogService };
