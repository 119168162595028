import { Database, Q } from "@nozbe/watermelondb"
import { posthogService, sentry } from "@recall/common"
import { logout } from "hooks/auth/useAuth"
import { toast } from "react-toastify"
import { DataMigrationModel } from "../models/DataMigrationModel"
import { ROOT_TAG_ID, tagRepository } from "../repository"
import { DATA_MIGRATIONS } from "../schema"
import { syncRealTimeDb } from "../sync"

export const fixMigration34Issue = async (
    db: Database,
    uid: string,
    handlePushCallback: Function,
    handlePullCallback: Function
) => {
    let tags = await tagRepository.getAll(db)
    tags = tags.filter(
        (tag) => tag.updatedAt.getTime() === 0 && !tag.parent.id && tag.id !== ROOT_TAG_ID
    )

    if (tags.length > 0) {
        const migrations = await db.collections
            .get<DataMigrationModel>(DATA_MIGRATIONS)
            .query(Q.where("name", "Assign parent tags 2"))
            .fetch()

        if (migrations.length) {
            const migration = migrations[0]
            await migration.delete()
            await syncRealTimeDb(db, uid, handlePushCallback, handlePullCallback)
            await logout(db)

            posthogService.captureEvent("Version 34 fix", { uid })
            toast.info(
                "We've just logged you out to upgrade you to the latest version of Recall. Apologies for the inconvenience."
            )
        } else {
            sentry.captureMessage("No tags migration in syncing fix")
        }
    }
}
