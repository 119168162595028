import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import Showdown from "showdown";
import { sentry } from "../../utils";
const converter = new Showdown.Converter({
    disableForced4SpacesIndentedSublists: true,
    smartIndentationFix: true,
});
converter.addExtension({
    type: "output",
    regex: /href=\"https:\/\/www\.youtube\.com\/watch\?v=.+?t=(\d+)s\"/g,
    replace: 'href="#" data-timestamp="$1" class="youtube-timestamp"',
}, "timestampLinks");
const TIMESTAMP_PARENT_CLASS = "timestamp-anchor-parent";
export const MarkdownRenderer = ({ card }) => {
    const html = converter.makeHtml((card === null || card === void 0 ? void 0 : card.markdown) || "");
    const contentRef = useRef(null);
    useEffect(() => {
        if (contentRef.current) {
            contentRef.current
                .querySelectorAll("a[data-timestamp]")
                .forEach((anchor) => {
                const parentElement = anchor === null || anchor === void 0 ? void 0 : anchor.parentElement;
                const originalText = anchor.innerText;
                if (parentElement) {
                    anchor.style.visibility = "hidden";
                    parentElement.classList.add(TIMESTAMP_PARENT_CLASS);
                    parentElement.addEventListener("mouseenter", handleMouseEnter);
                    parentElement.addEventListener("mouseleave", handleMouseLeave);
                }
                anchor.title = `Jump to timestamp: ${originalText}`;
                anchor.innerText = "↗";
                anchor.addEventListener("click", handleTimestampClick);
            });
        }
        return () => {
            if (contentRef.current) {
                contentRef.current
                    .querySelectorAll("a[data-timestamp]")
                    .forEach((anchor) => {
                    anchor.removeEventListener("click", handleTimestampClick);
                });
                contentRef.current
                    .querySelectorAll(`.${TIMESTAMP_PARENT_CLASS}`)
                    .forEach((parent) => {
                    parent.removeEventListener("mouseenter", handleMouseEnter);
                    parent.removeEventListener("mouseleave", handleMouseLeave);
                });
            }
        };
    }, [html]);
    function handleMouseEnter(e) {
        const parent = e.currentTarget;
        const anchor = parent.querySelector("a[data-timestamp]");
        if (anchor)
            anchor.style.visibility = "visible";
    }
    function handleMouseLeave(e) {
        const parent = e.currentTarget;
        const anchor = parent.querySelector("a[data-timestamp]");
        if (anchor)
            anchor.style.visibility = "hidden";
    }
    const handleTimestampClick = (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const seconds = target.getAttribute("data-timestamp");
        if (seconds)
            seekTo(parseInt(seconds, 10));
    };
    const seekTo = (seconds) => {
        const video = document.querySelector("video");
        if (video) {
            video.currentTime = seconds;
            if (video.paused)
                video.play();
        }
        else {
            sentry.captureMessage("Video not found", { url: card.sources });
        }
    };
    return html ? (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Box, { ref: contentRef, sx: Object.assign({}, styles.html), dangerouslySetInnerHTML: { __html: html } }) }))) : (_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Typography, Object.assign({ variant: "body2", component: "p", mb: 1, whiteSpace: "pre-wrap" }, { children: card.description })) })));
};
const styles = {
    html: {
        padding: 0,
        margin: 0,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: (theme) => theme.typography.fontFamily,
        "& h1": {
            fontSize: "24px",
            fontWeight: 500,
            mx: 0,
            mt: 3,
            mb: 1.5,
        },
        "& h2": {
            fontSize: "20px",
            fontWeight: 500,
            mx: 0,
            mt: 3,
            mb: 1,
        },
        "& ul": {
            paddingInlineStart: "20px",
            marginBlockStart: "8px",
        },
        "& p": {
            marginBlockEnd: "8px",
        },
        "& li p": {
            margin: 0,
        },
        "& li": {
            mb: 0.5,
        },
        "& a": {
            color: (theme) => theme.palette.primary.main,
        },
        "& a.youtube-timestamp": {
            fontSize: "16px",
            lineHeight: "normal",
            color: (theme) => theme.palette.action.active,
            pl: "5px",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            },
        },
    },
};
