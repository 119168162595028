import { createPluginFactory, onKeyDownToggleElement } from "@udecode/plate-common"

export const ELEMENT_CUSTOM_PARAGRAPH = "paragraph"

export const createCustomParagraphPlugin = createPluginFactory({
    key: ELEMENT_CUSTOM_PARAGRAPH,
    isElement: true,
    handlers: {
        onKeyDown: onKeyDownToggleElement,
    },
    options: {
        hotkey: ["mod+opt+0", "mod+shift+0"],
    },
    deserializeHtml: {
        rules: [
            {
                validNodeName: "P",
            },
        ],
        query: (el) => el.style.fontFamily !== "Consolas",
    },
})
