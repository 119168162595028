import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import SvgIcon from "@mui/material/SvgIcon"
import React, { FC } from "react"

interface Props {
    count?: number
    iconSVG: React.ReactNode
}

export const LinkIcon: FC<Props> = ({ count, iconSVG }) => {
    const theme = useTheme()
    let rightPosition = "15px"

    if (count && count > 9) {
        rightPosition = "12px"
    }

    return (
        <Box position="relative" display="inline-flex">
            <SvgIcon sx={{ fontSize: "30px" }}>{iconSVG}</SvgIcon>
            {count && (
                <Typography
                    variant="caption"
                    component="span"
                    style={{
                        position: "absolute",
                        top: "9px",
                        right: rightPosition,
                        fontSize: "0.75rem",
                        color: theme.palette.text.primary,
                        fontWeight: 500,
                        cursor: "default",
                    }}
                >
                    {count}
                </Typography>
            )}
        </Box>
    )
}
