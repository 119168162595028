import produce from "immer"
import { SET_ITEMS_ORDER_BY, SET_ITEMS_ORDER_DIRECTION } from "./actionTypes"
import { ItemsState, ItemsStateActionTypes } from "./types"

const initialState: ItemsState = {
    orderBy: "updatedAt",
    direction: "desc",
}

export const itemsReducer = (state = initialState, action: ItemsStateActionTypes) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case SET_ITEMS_ORDER_BY: {
                draftState.orderBy = action.payload
                break
            }
            case SET_ITEMS_ORDER_DIRECTION: {
                draftState.direction = action.payload
                break
            }

            default:
                return state
        }
    })
