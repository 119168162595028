import { Grid } from "@mui/material"
import { Header } from "components/layouts/components/Header/Header"
import React, { FC, useEffect } from "react"
import { ItemsList } from "./components/ItemsList"
import { TagsBar } from "./components/TagsBar"

const ItemsPage: FC = () => {
    useEffect(() => {
        document.title = "Recall Cards"
    }, [])

    return (
        <>
            <Grid item xs>
                <Header showItemsActions />
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TagsBar />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <ItemsList />
            </Grid>
        </>
    )
}

export default React.memo(ItemsPage)
