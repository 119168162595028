import { isEmpty, isEqual, uniq } from "lodash"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { RootState } from "storage/redux/rootReducer"
import { updateUser } from "storage/redux/user/actions"
import { GroupedTags, Tag } from "./useGroupedTags"

export const useExpandedTags = (tags: GroupedTags) => {
    const dispatch = useDispatch()

    const uid = useSelector((state: RootState) => state.user.uid)
    const expandedTags = useSelector((state: RootState) => state.user.menu?.expandedTags)
    const isGetRecallHidden = useSelector((state: RootState) => state.user.menu?.isGetRecallHidden)
    const { id: itemId } = useParams<{ id: string }>()

    const tag = useMemo(() => {
        return Object.values(tags).find((tag) => {
            if (!expandedTags || !itemId) return null
            return tag.items.some(({ item }) => item.id === itemId)
        })
    }, [tags, itemId, expandedTags])

    useEffect(() => {
        if (isEmpty(tags) || expandedTags?.length) return

        getExpandedTags()
        // eslint-disable-next-line
    }, [tags])

    useEffect(() => {
        if (!tag) return
        expandTagsTreeToItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, itemId])

    const expandTagsTreeToItem = () => {
        const parentTagsToExpand = getAllParentIds(tag)
        const newExpandedTags = uniq([...expandedTags, ...parentTagsToExpand, tag.id])

        if (isEqual(newExpandedTags, expandedTags)) {
            scrollToItem()
            return
        }
        handleChangeExpanded(newExpandedTags)
        scrollToItem()
    }

    const scrollToItem = () => {
        setTimeout(() => {
            const item = document.getElementById(itemId)
            if (item) item.scrollIntoView({ block: "center" })
        }, 50)
    }

    const getAllParentIds = (tag: Tag) => {
        let maxNested = 20
        let parent = tags[tag.parentId]
        const ids = []
        while (Boolean(parent) && maxNested-- > 0) {
            ids.push(parent.id)
            if (!parent.parentId) break
            parent = tags[parent.parentId]
        }

        return ids
    }

    const getExpandedTags = async () => {
        if (!uid) return

        if (!expandedTags) await initializeExpandedTags()
    }

    const initializeExpandedTags = async () => {
        const expandedTags = Object.keys(tags)
        dispatch(updateUser({ menu: { expandedTags, isGetRecallHidden } }, uid))
    }

    const handleChangeExpanded = async (expandedTags: string[]) => {
        dispatch(updateUser({ menu: { expandedTags, isGetRecallHidden } }, uid))
    }
    const expandTag = async (tagId: string) => {
        handleChangeExpanded([...expandedTags, tagId])
    }

    return useMemo(
        () => ({ expandedTags: expandedTags || [], handleChangeExpanded, expandTag }),
        // eslint-disable-next-line
        [expandedTags, isGetRecallHidden]
    )
}
