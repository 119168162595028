import { useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { Slide, ToastContainer } from "react-toastify"

const RecallToastContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme()

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--toastify-color-success",
            theme.palette.success.main
        )
        document.documentElement.style.setProperty(
            "--toastify-color-warning",
            theme.palette.warning.main
        )
        document.documentElement.style.setProperty(
            "--toastify-color-error",
            theme.palette.error.main
        )
        // document.documentElement.style.setProperty('--toastify-color-info', 'pink');
        // eslint-disable-next-line
    }, [theme.palette.mode])

    return (
        <ToastContainer
            position="bottom-center"
            transition={Slide}
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            draggable={true}
            theme={"dark"}
            toastStyle={{
                borderRadius: theme.spacing(2),
            }}
        />
    )
}

export default RecallToastContainer
