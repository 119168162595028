import { TElement, TNodeEntry, getPluginInjectProps, isCollapsed } from "@udecode/plate-common"

import {
    PlateEditor,
    Value,
    getBlockAbove,
    getNodeEntries,
    getPluginOptions,
    isExpanded,
    setElements,
    unsetNodes,
    withoutNormalizing,
} from "@udecode/plate-common"
import { KEY_INDENT } from "@udecode/plate-indent"
import {
    IndentListOptions,
    IndentListPlugin,
    KEY_LIST_STYLE_TYPE,
    ListStyleType,
    setIndentListNodes,
    setIndentListSiblingNodes,
    toggleIndentListSet,
    toggleIndentListUnset,
} from "@udecode/plate-indent-list"

const areEqListStyleType = (
    editor: PlateEditor<Value>,
    entries: TNodeEntry[],
    {
        listStyleType = ListStyleType.Disc,
    }: {
        listStyleType?: string
    }
) => {
    let eqListStyleType = true
    const { validTypes } = getPluginInjectProps(editor, KEY_LIST_STYLE_TYPE)

    for (const entry of entries) {
        const [block] = entry

        if (block.type && !validTypes.includes(block.type as string)) continue

        if (!block[KEY_LIST_STYLE_TYPE] || block[KEY_LIST_STYLE_TYPE] !== listStyleType) {
            eqListStyleType = false
            break
        }
    }

    return eqListStyleType
}

export const toggleIndentList = (editor: PlateEditor<Value>, options: IndentListOptions<Value>) => {
    const { listStyleType } = options

    const { getSiblingIndentListOptions } = getPluginOptions<IndentListPlugin, Value>(
        editor,
        KEY_LIST_STYLE_TYPE
    )

    if (isCollapsed(editor.selection)) {
        const entry = getBlockAbove<TElement>(editor)
        if (!entry) return

        if (toggleIndentListSet(editor, entry, { listStyleType })) {
            return
        }

        if (toggleIndentListUnset(editor, entry, { listStyleType })) {
            return
        }

        setIndentListSiblingNodes(editor, entry, {
            listStyleType,
            getSiblingIndentListOptions,
        })
        return
    }

    if (isExpanded(editor.selection)) {
        const _entries = getNodeEntries<TElement>(editor, { block: true })
        const entries = [..._entries]

        const eqListStyleType = areEqListStyleType(editor, entries, {
            listStyleType,
        })

        if (eqListStyleType) {
            withoutNormalizing(editor, () => {
                entries.forEach((entry) => {
                    const [node, path] = entry

                    const indent = node[KEY_INDENT] as number

                    unsetNodes(editor, KEY_LIST_STYLE_TYPE, { at: path })
                    if (indent > 1) {
                        setElements(editor, { [KEY_INDENT]: indent - 1 }, { at: path })
                    } else {
                        unsetNodes(editor, KEY_INDENT, { at: path })
                    }
                })
            })
            return
        }

        setIndentListNodes(editor, entries, { listStyleType })
    }
}
