var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addMonths } from "date-fns";
import { UsageService } from "./usageService";
export class CostUsageService extends UsageService {
    constructor() {
        super(...arguments);
        this.limit = 5;
        this.increaseUsage = (userId, cost) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const user = yield this.userRepository.getUser(userId);
            const costUsage = cost || 0;
            const shouldResetCostUsage = !user || !((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.cost) || user.usage.cost.resetDate <= Date.now();
            if (shouldResetCostUsage) {
                yield this.resetCostUsage(userId, costUsage);
                return costUsage;
            }
            const newCost = user.usage.cost.value + costUsage;
            yield this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { cost: Object.assign(Object.assign({}, user.usage.cost), { value: newCost }) }),
            });
            return newCost;
        });
        this.resetCostUsage = (userId, cost) => __awaiter(this, void 0, void 0, function* () {
            const user = yield this.userRepository.getUser(userId);
            const resetDate = addMonths(Date.now(), 1).getTime();
            yield this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { cost: { value: cost, resetDate } }),
            });
        });
        this.isUsageExceeded = (userId) => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const user = yield this.userRepository.getUser(userId);
            const shouldResetCostUsage = !user || !((_b = user === null || user === void 0 ? void 0 : user.usage) === null || _b === void 0 ? void 0 : _b.cost) || user.usage.cost.resetDate <= Date.now();
            if (shouldResetCostUsage) {
                yield this.resetCostUsage(userId, 0);
                return false;
            }
            return user.usage.cost.value > this.limit;
        });
        this.getUsage = (userId) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            const user = yield this.userRepository.getUser(userId);
            const usage = ((_d = (_c = user === null || user === void 0 ? void 0 : user.usage) === null || _c === void 0 ? void 0 : _c.cost) === null || _d === void 0 ? void 0 : _d.value) || 0;
            return usage;
        });
    }
}
