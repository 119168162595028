import { Database, Q } from "@nozbe/watermelondb"
import { Connection, sentry } from "@recall/common"
import { v4 as uuidv4 } from "uuid"
import { connectionPropertyRepository } from "."
import { ConnectionModel, ConnectionPropertyModel } from "../models"
import { CONNECTIONS } from "../schema"

const getAll = async (db: Database): Promise<ConnectionModel[]> => {
    return await db.collections.get<ConnectionModel>(CONNECTIONS).query().fetch()
}

const get = async (db: Database, id: string): Promise<ConnectionModel | null> => {
    try {
        return await db.collections.get<ConnectionModel>(CONNECTIONS).find(id)
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

const getConnection = async (
    db: Database,
    from: string,
    to: string
): Promise<ConnectionModel | null> => {
    try {
        const connections = await db.collections
            .get<ConnectionModel>(CONNECTIONS)
            .query(Q.where("from_id", from), Q.where("to_id", to))
            .fetch()
        return connections?.[0] || null
    } catch (e) {
        sentry.captureException(e)
        return null
    }
}

const getItemConnections = (db: Database, itemId: string): Promise<ConnectionModel[]> => {
    return db.collections
        .get<ConnectionModel>(CONNECTIONS)
        .query(Q.where("from_id", itemId))
        .fetch()
}

const create = async (
    db: Database,
    connection: Omit<Connection, "id">,
    isSaved = true
): Promise<ConnectionModel> => {
    return await db.write<ConnectionModel>(async () => {
        const collection = db.collections.get<ConnectionModel>(CONNECTIONS)

        const existingConnections = await collection
            .query(Q.where("from_id", connection.fromId), Q.where("to_id", connection.toId))
            .fetch()

        if (existingConnections.length) return existingConnections[0]

        let propertyModel: ConnectionPropertyModel | null = null

        if (connection.property)
            propertyModel = await connectionPropertyRepository.getOrCreate(db, connection.property)

        return await collection.create((record) => {
            record._raw.id = uuidv4()
            record.from.id = connection.fromId
            record.to.id = connection.toId
            record.property.set(propertyModel)
            record.isSaved = isSaved
        })
    })
}

const deleteConnections = async (db: Database, ids: string[], dispatchEvent = true) => {
    await db.write(async (writer) => {
        let tasks = []

        for (const id of ids) {
            const connectionModel = await get(db, id)
            if (!connectionModel) continue

            const moreTasks = await connectionModel.prepareDeleteWithStaleItem(dispatchEvent)
            tasks = [...tasks, ...moreTasks]
        }

        await writer.batch(...tasks)
    })
}

const deleteStale = async (db: Database) => {
    await db.write(async (writer) => {
        let connections = await db.collections.get<ConnectionModel>(CONNECTIONS).query().fetch()
        let tasks = []

        for (const connection of connections) {
            try {
                await connection.to.fetch()
                await connection.from.fetch()
            } catch (e) {
                const moreTasks = await connection.prepareDeleteWithStaleItem()
                tasks = [...tasks, ...moreTasks]
            }
        }

        await writer.batch(...tasks)
    })
}

export const connectionRepository = {
    getAll,
    get,
    getConnection,
    getItemConnections,
    create,
    delete: deleteConnections,
    deleteStale,
}
