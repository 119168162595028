import { ChevronLeft } from "@mui/icons-material"
import { Button, Container, SxProps, Theme, Typography } from "@mui/material"
import { OPEN_URL } from "@recall/common"
import { Logo } from "components/layouts/components/Logos/Logo"
import { HOME_PATH } from "constants/routes"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { functions } from "services/firebase/firebaseFunction"
import { messagesService } from "services/messagesService"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

export const PricingNative = () => {
    const [isCopied, setIsCopied] = useState(false)
    const history = useHistory()

    const handleClick = () => {
        history.push(HOME_PATH)
    }

    const handleOpenPricing = async () => {
        const response = await functions.createCustomAuthToken()
        const token = response.data
        messagesService.post(OPEN_URL, "https://app.getrecall.ai/pricing?authToken=" + token, true)
    }

    useEffect(() => {
        if (!isCopied) return

        setTimeout(() => {
            setIsCopied(false)
        }, 3000)
    }, [isCopied])

    return (
        <Container sx={styles.container}>
            <Button onClick={handleClick} sx={styles.back} startIcon={<ChevronLeft />}>
                Back
            </Button>
            <Logo size={80} />
            <Typography my={2} variant="h5">
                Open in browser to our see plans:
            </Typography>
            <Button
                variant="contained"
                sx={{ px: 4 }}
                onClick={handleOpenPricing}
                endIcon={<OpenInNewIcon />}
            >
                Open
            </Button>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
    },
    back: {
        position: "absolute",
        top: 20,
        left: 20,
        color: "white",
    },
}
