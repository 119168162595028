import { Box } from "@mui/material"
import { sentry } from "@recall/common"
import { ItemChip } from "components/shared/chips/ItemChip"
import { ItemPopper } from "components/shared/poppers/ItemPopper"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useHoverDelay } from "hooks/useHoverDelay"
import { useIsMobile } from "hooks/useIsMobile"
import React, { FC, useEffect, useState } from "react"
import { ConnectionModel, ItemModel } from "storage/watermelon/models"

interface Props {
    item: ItemModel
    connection: ConnectionModel
    readOnly?: boolean
}

const HOVER_DELAY = 250

const LinkComp: FC<Props> = ({ item, connection, readOnly = false }) => {
    const isMobile = useIsMobile()
    const { openItem } = useOpenItem()
    const [mentionCount, setMentionCount] = useState<number>()

    const { isOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(HOVER_DELAY)

    const getLinkItemAndCount = async () => {
        try {
            const mentionCount = await item.mentions.count
            setMentionCount(mentionCount)
        } catch (error) {
            sentry.captureException(error)
        }
    }

    useEffect(() => {
        getLinkItemAndCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const handleOnClick = () => {
        openItem(item)
    }

    return (
        <Box
            sx={{ p: 0.2, display: "inline-block" }}
            id={item.id}
            onMouseEnter={isMobile ? undefined : handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ItemPopper
                hidePin
                readOnly={readOnly}
                item={item}
                open={isOpen}
                connectionId={connection.id}
            >
                <ItemChip
                    size="medium"
                    id={item.id}
                    name={item.name}
                    image={item.image}
                    mentionCount={mentionCount}
                    handleClick={isMobile ? handleMouseEnter : handleOnClick}
                />
            </ItemPopper>
        </Box>
    )
}

export const Link = React.memo(
    LinkComp,
    (prevProps, nextProps) =>
        prevProps.item.id === nextProps.item.id &&
        prevProps.connection.id === nextProps.connection.id
)
