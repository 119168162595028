import { ArticleOutlined } from "@mui/icons-material"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { FC } from "react"
import { SearchItem } from "./helpers/searchItems"
import { TagsTreeText } from "./TagsTreeText"

interface Props {
    tagId?: string
    selected: boolean
    searchedItem: SearchItem
    searchText: string
    handleClick: (id: string) => void
}

export const TagsTreeCard: FC<Props> = ({
    tagId,
    selected,
    searchedItem,
    searchText,
    handleClick,
}) => {
    const { extractedText, item } = searchedItem
    const isSearchInTitle = item.name.toLowerCase().includes(searchText.toLowerCase())

    if (!extractedText && !isSearchInTitle) return null

    return (
        <Box ml={0.1} mb={0} pb={0.6}>
            <Box
                id={item.id}
                display="flex"
                gap={1}
                draggable
                onDragStart={(e) => {
                    e.stopPropagation()
                    e.dataTransfer.setData(
                        "application/json",
                        JSON.stringify({ tagId: tagId, isTag: false, itemId: item.id })
                    )
                }}
                sx={{
                    cursor: "pointer",
                    py: 0.5,
                    px: 1,
                    pl: 0.7,
                    bgcolor: (theme) =>
                        selected ? theme.palette.action.disabledBackground : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                        bgcolor: (theme) => (selected ? undefined : theme.palette.action.hover),
                    },
                }}
                onClick={() => {
                    handleClick(item.id)
                }}
            >
                <ArticleOutlined
                    sx={{ color: (theme) => theme.palette.primary.light }}
                    fontSize="small"
                />
                <Typography
                    variant="body2"
                    sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                >
                    <TagsTreeText
                        label={item.name}
                        searchText={searchText}
                        selected={selected}
                        isCard
                    />
                </Typography>
            </Box>
            {!isSearchInTitle && extractedText && (
                <Typography variant="caption" component="p" sx={styles.matchedText}>
                    {extractedText.beforeSearch}
                    <Box component="b" sx={{ color: "primary.light" }}>
                        {extractedText.searchedText}
                    </Box>
                    {extractedText.afterSearch}
                </Typography>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    matchedText: {
        opacity: 0.9,
        mb: 1,
        lineHeight: 1.2,
        ml: 0.5,
        mt: 0.4,
    },
}
