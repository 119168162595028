import { Image } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { focusEditor } from "@udecode/plate-common"
import { insertImage } from "@udecode/plate-media"
import { MAX_IMAGE_SIZE_IN_BYTES } from "constants/storage"
import { FC, useRef } from "react"
import { toast } from "react-toastify"
import { ASSET_TYPES, ItemModel } from "storage/watermelon/models"
import { assetRepository } from "storage/watermelon/repository/assetRepository"
import { uploadArrayBufferImage } from "../../plugins/image"
import { useMyPlateEditorRef } from "../../types"

interface Props {
    item: ItemModel
}

export const ImageToolbarButton: FC<Props> = ({ item }) => {
    const db = useDatabase()
    const editor = useMyPlateEditorRef()
    const inputRef = useRef(null)

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (file) {
            const contentType = file.type
            const reader = new FileReader()
            reader.onloadend = async () => {
                if (reader.result instanceof ArrayBuffer) {
                    const arrayBuffer = reader.result
                    const size = arrayBuffer.byteLength

                    if (size > MAX_IMAGE_SIZE_IN_BYTES) {
                        toast.error(
                            `Max image size is ${MAX_IMAGE_SIZE_IN_BYTES / (1024 * 1024)}MB`
                        )
                        return null
                    }
                    const url = await uploadArrayBufferImage(arrayBuffer, contentType)

                    await assetRepository.createAsset(db, {
                        item,
                        url,
                        size,
                        type: ASSET_TYPES.IMAGE,
                    })

                    if (!item.image) {
                        await item.updateImage(url)
                    }

                    insertImage(editor, url)
                    focusEditor(editor)
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    return (
        <>
            <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleImageChange}
            />
            <Tooltip title="Upload image">
                <IconButton
                    size={"small"}
                    onClick={() => {
                        inputRef.current?.click()
                    }}
                >
                    <Image />
                </IconButton>
            </Tooltip>
        </>
    )
}
