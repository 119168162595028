import { Article, Google } from "@mui/icons-material"
import { Avatar, Box, Divider, Skeleton, Typography } from "@mui/material"
import { SearchPreview, isGoogleSearchUrl } from "@recall/common"
import { useIsMobile } from "hooks/useIsMobile"
import { truncate } from "lodash"
import { FC, memo, useEffect, useState } from "react"
import { summariesApi } from "services/api"

interface Props {
    url: string
}

export const SummarizeSearchResult: FC<Props> = memo(({ url }) => {
    const [result, setResult] = useState<SearchPreview | null>(null)
    const isMobile = useIsMobile()

    useEffect(() => {
        getResult()
    }, [])
    const getResult = async () => {
        try {
            const result = await summariesApi.getSummaryPreview(url)
            setResult(result)
        } catch (err) {
            setResult({ title: "", image: "", description: "" })
        }
    }

    if (!result) return <SummarizeSearchResultLoader />
    if (!result.title) return <SummarizeSearchResultDefault url={url} />

    return (
        <Box width="100%">
            <Typography variant="body2" display="flex" alignItems="center" justifyContent="center">
                {isMobile ? "Press" : "Click"} to summarize
            </Typography>
            <Divider />
            <Box display="flex" alignItems="center" mt={1} mb={0.5} gap={1} width="100%">
                <Avatar
                    src={isGoogleSearchUrl(url) ? undefined : result?.image}
                    variant="square"
                    sx={{
                        width: 90,
                        height: 51,
                        borderRadius: 0.5,
                        backgroundColor: "action.hover",
                    }}
                >
                    {isGoogleSearchUrl(url) ? (
                        <Google fontSize="large" sx={{ color: "text.primary" }} />
                    ) : (
                        <Article fontSize="large" sx={{ color: "text.primary" }} />
                    )}
                </Avatar>

                <Box>
                    <Typography variant="body2" fontWeight={600} sx={{ lineHeight: 1.1 }}>
                        {truncate(result?.title, { length: 60 })}
                    </Typography>
                    {result?.description && (
                        <Typography
                            variant="caption"
                            sx={{ lineHeight: 1.1 }}
                            mt={0.5}
                            component="p"
                        >
                            {truncate(result?.description, { length: 60 })}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    )
})

const SummarizeSearchResultLoader = () => (
    <Box display="flex" p={0.5} alignItems="center" justifyContent="center">
        <Skeleton height={51} width={90} variant="rectangular" />
        <Box pl={1}>
            <Skeleton width={180} />
            <Skeleton width={120} />
        </Box>
    </Box>
)

const SummarizeSearchResultDefault = ({ url }: { url: string }) => {
    const isMobile = useIsMobile()

    return (
        <Box width="100%">
            <Typography variant="body2" display="flex" alignItems="center" justifyContent="center">
                {isMobile ? "Press" : "Click"} to summarize
            </Typography>
            <Divider />
            <Box display="flex" alignItems="center" py={1} width="100%">
                <Article />
                <Typography pl={1}>{truncate(url, { length: 50, omission: "..." })}</Typography>
            </Box>
        </Box>
    )
}
