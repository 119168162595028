import { checkExtensionStatus } from "@recall/common"
import { useEffect, useState } from "react"
import { useIsMobile } from "./useIsMobile"
import { useTabFocus } from "./useTabFocus"

export const useShouldInstallExtension = () => {
    const isMobile = useIsMobile()
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(true)

    const getExtensionStatus = () => {
        if (isMobile) return
        checkExtensionStatus().then(({ isExtensionInstalled }) => {
            setIsExtensionInstalled(isExtensionInstalled)
        })
    }

    useEffect(() => {
        getExtensionStatus()
        // eslint-disable-next-line
    }, [])

    useTabFocus(getExtensionStatus)

    const shouldInstallExtension = !isExtensionInstalled && !isMobile
    return shouldInstallExtension
}
