import { Add, KeyboardCommandKey } from "@mui/icons-material"
import { Box, SxProps, Theme, Typography, alpha } from "@mui/material"
import { FC, useMemo } from "react"

interface ShortcutProps {
    size: "small" | "medium"
}

export const ShortcutInfo: FC<ShortcutProps> = ({ size }) => {
    const isMac = useMemo(() => navigator.platform.toUpperCase().indexOf("MAC") >= 0, [])

    return (
        <Box sx={styles.shortcut}>
            <Typography mr={1.5} fontWeight="bold">
                Shortcut:
            </Typography>
            {isMac ? (
                <Box sx={styles.keyContainer}>
                    <KeyboardCommandKey fontSize={size} />
                </Box>
            ) : (
                <Box sx={styles.keyContainer}>
                    <Typography sx={styles.ctrlCopy}>
                        <code>ctrl</code>
                    </Typography>
                </Box>
            )}
            <Box sx={styles.addIcon}>
                <Add />
            </Box>
            <Box sx={styles.keyContainer}>
                <Typography sx={styles.shortcutKey}>k</Typography>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    shortcut: {
        display: "flex",
        alignItems: "center",
        mb: 2,
        color: alpha("#FFFFFF", 0.9),
    },
    shortcutKey: { fontSize: 24 },
    keyContainer: {
        width: 44,
        height: 44,
        border: (theme) => `2px solid ${theme.palette.background.paper}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0.5,
    },
    ctrlCopy: {
        fontSize: 12,
        fontWeight: "bold",
    },
    addIcon: {
        ml: 0.8,
        mr: 1,
        display: "flex",
        alignItems: "center",
    },
}
