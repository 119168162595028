import { Box, SxProps, Theme, Typography } from "@mui/material"
import { getTheme } from "@recall/common"
import { FC, useState } from "react"
import { ItemModel } from "storage/watermelon/models"
import { GroupedAssets } from "./AssetsModal"
import { FileMenu } from "./FileMenu"
import { ZoomedImage } from "./ZoomedImage"

interface Props {
    assets: GroupedAssets
    item: ItemModel
    getAssets: () => void
}

const theme = getTheme("dark")

export const Files: FC<Props> = ({ assets, item, getAssets }) => {
    const [zoomedImage, setZoomedImage] = useState(null)

    if (assets.order.length === 0) return <Box>No files attached to this card</Box>

    return (
        <Box>
            {zoomedImage && (
                <ZoomedImage url={zoomedImage} handleClose={() => setZoomedImage(null)} />
            )}
            {assets.order.map((date) => (
                <Box mb={2} key={date}>
                    <Typography variant="body2" mb={1}>
                        {date}
                    </Typography>
                    <Box display="flex" gap={1} flexWrap="wrap" alignItems="flex-start">
                        {assets.groupedAssets[date].map((asset) => (
                            <Box key={asset.id} position="relative" sx={styles.wrapper}>
                                <FileMenu getAssets={getAssets} asset={asset} item={item} />
                                <Box
                                    onClick={() => setZoomedImage(asset.url)}
                                    sx={styles.image}
                                    component="img"
                                    src={asset.url}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        [theme.breakpoints.up("md")]: { ".menu": { display: "none" } },

        ":hover": {
            ".menu": { display: "block" },
        },
    },
    image: {
        width: { xs: 130, md: 180 },
        height: { xs: 130, md: 180 },
        borderRadius: 1,
        objectFit: "cover",
        cursor: "pointer",
    },
}
