import { editableProps } from "./config"

import { Plate } from "@udecode/plate-common"
import { FC, useEffect, useMemo, useState } from "react"

import { Box, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { PlateSkeleton } from "components/ItemPage/components/editor/components/PlateSkeleton"
import { MAX_IMAGE_SIZE_IN_BYTES } from "constants/storage"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useIsMobile } from "hooks/useIsMobile"
import { isEqual, map } from "lodash"
import { toast } from "react-toastify"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { initItemEditorData } from "services/editorData/initEditorData"
import { ASSET_TYPES, ItemModel } from "storage/watermelon/models"
import { editorBlockRepository, editorOrderRepository } from "storage/watermelon/repository"
import { assetRepository } from "storage/watermelon/repository/assetRepository"
import { v4 as uuid } from "uuid"
import { PlateConfig } from "./PlateConfig"
import { FixedToolbar } from "./components/Toolbar/FixedToolbar"
import { FloatingToolbar } from "./components/Toolbar/FloatingToolbar"
import { useCleanUpCopiedBlocks } from "./hooks/useCleanUpCopiedBlocks"
import { createPlatePlugins } from "./plugins"
import { getBase64Size, uploadImageToStorage } from "./plugins/image"
import { EditorElements } from "./types"

export const EDITOR_BLOCK_TEXT_DIV_ID = "id-editor-block-text"
export const RERENDER_EDITOR_BLOCKS_EVENT = "rerender-editor-blocks-event"
interface Props {
    item: ItemModel
    readOnly?: boolean
}

export const PlateEditor: FC<Props> = ({ item, readOnly = false }) => {
    const db = useDatabase()
    const [editorBlocks, setEditorBlocks] = useState<EditorElements | null>(null)
    const isMobile = useIsMobile()
    const { updateLastLabel } = useBreadcrumbActions()
    const { cleanUpCopiedBlocks } = useCleanUpCopiedBlocks(item)
    // Force rerenders editor, after image deletion for e.g., changing editorBlock don't cause rerender so this is hacky solution
    const [plateEditorKey, setPlateEditorKey] = useState(uuid())

    const uploadImage = async (dataUrl: string | ArrayBuffer) => {
        if (typeof dataUrl !== "string") return null
        const size = getBase64Size(dataUrl)

        if (size > MAX_IMAGE_SIZE_IN_BYTES) {
            toast.error(`Max image size is ${MAX_IMAGE_SIZE_IN_BYTES / (1024 * 1024)}MB`)
            return null
        }

        const url = await uploadImageToStorage(dataUrl)
        await assetRepository.createAsset(db, { item, url, size, type: ASSET_TYPES.IMAGE })
        if (!item.image) {
            await item.updateImage(url)
        }
        return url
    }

    const plugins = useMemo(() => {
        return createPlatePlugins({ readOnly, item, uploadImage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const getEditorBlocks = async () => {
        const editorBlocks = await initItemEditorData(db, item)
        setEditorBlocks(editorBlocks)
        setPlateEditorKey(uuid())
    }

    useEffect(() => {
        getEditorBlocks()

        document.addEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)

        return () => {
            document.removeEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const upsertEditorBlocks = async (editorBlocks: EditorElements) => {
        for (let editorBlock of editorBlocks) {
            await editorBlockRepository.upsert(db, item.id, editorBlock)
        }
    }

    const updateItemDetails = async (item: ItemModel, newValue: EditorElements) => {
        for (const editorBlock of newValue) {
            const title = EditorBlockData.getText([editorBlock])

            const name = title.trim()

            if (name !== item.name) {
                await item.updateName(name)
                updateLastLabel(name)
            }
            if (name) break
        }
        const description = EditorBlockData.getTextWithoutHeadings(newValue)
        if (item.description !== description) await item.updateDescription(description)
    }

    const updateEditorBlocks = async (newValue: EditorElements) => {
        const existingIds = []
        for (const editorBlock of newValue) {
            // Plate editor creates custom elements with same ID when you hit enter, so it can cause duplicates without this fix
            if (!editorBlock?.id || existingIds.includes(editorBlock.id)) {
                editorBlock.id = uuid()
            }
            existingIds.push(editorBlock.id)
        }

        const editorOrder = map(newValue, "id")
        await upsertEditorBlocks(newValue)
        await editorOrderRepository.upsert(db, item.id, editorOrder)
        await updateItemDetails(item, newValue)
        await item.updateUpdatedAt()

        setEditorBlocks(newValue)
    }

    const handleChange = async (newValue: EditorElements) => {
        if (isEqual(editorBlocks, newValue)) return

        const cleanedUpValue = await cleanUpCopiedBlocks(newValue)

        await updateEditorBlocks(cleanedUpValue)
    }

    if (!editorBlocks) {
        return <PlateSkeleton />
    }

    return (
        <Box sx={styles.editor}>
            <Plate<EditorElements>
                key={plateEditorKey}
                plugins={plugins}
                initialValue={editorBlocks}
                value={editorBlocks}
                onChange={handleChange}
                editableProps={{
                    ...editableProps,
                    readOnly,
                    className: "plate-editor",
                }}
            >
                <PlateConfig />
                <FloatingToolbar />
                {!isMobile && !readOnly && <FixedToolbar item={item} />}
            </Plate>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    editor: {
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 1,
        pb: 2,
        maxWidth: "100%",
        wordBreak: "break-word",
        position: "relative",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ".plate-editor": {
            "h1, h2, h3, h4, h5, h6": {
                lineHeight: 1.3,
                mt: 2.5,
                mb: 1.25,
                ":first-child": {
                    mt: 0.5,
                },
            },
        },
    },
}
