import { Grid, Typography } from "@mui/material"
import ItemPartialComp from "components/shared/ItemPartial/ItemPartialComp"
import { useOpenItem } from "hooks/items/useOpenItem"
import { isEmpty } from "lodash"
import { Fragment, useCallback } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { ItemModel } from "storage/watermelon/models"
import { useGroupedItems } from "../hooks/useGroupedItems"
import { useScrollPosition } from "../hooks/useScrollPosition"
import { ItemsListEmpty } from "./ItemsListEmpty"
import { ItemsLoader } from "./ItemsLoader"
import { NewUserMessage } from "./NewUserMessage"

export const ItemsList = () => {
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)

    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)

    const isFilterApplied = Boolean(selectedTagIds.length)

    const { openItem } = useOpenItem()

    const handleOnClick = useCallback((item: ItemModel) => {
        openItem(item)
    }, [])

    const { groupedItems, order, itemsCount } = useGroupedItems()

    const items = Object.values(groupedItems).flat()
    const isEmptyList = isFilterApplied && items.length === 0

    useScrollPosition(!isEmpty(groupedItems))

    if (isEmptyList) return <ItemsListEmpty />

    return (
        <>
            {order.map((date, index) => (
                <Fragment key={date}>
                    <Grid item xs={12} pt={index === 0 ? "0 !important" : 1}>
                        <Typography pt={index === 0 ? 0 : 1} fontSize={14}>
                            {date}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        {groupedItems[date].map((item) => (
                            <Grid
                                xs={12}
                                sm={isDrawerOpen ? 12 : 6}
                                md={isDrawerOpen ? 6 : 4}
                                lg={3}
                                xl={3}
                                item
                                key={item.id}
                            >
                                <ItemPartialComp
                                    item={item}
                                    onClick={handleOnClick}
                                    showTags
                                    maxTitleLength={50}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Fragment>
            ))}
            {Boolean(order.length) && (
                <ItemsLoader itemsCount={itemsCount} isVisible={Boolean(items.length)} />
            )}
            {!isFilterApplied && <NewUserMessage />}
        </>
    )
}
