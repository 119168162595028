import { DialogContent, Typography } from "@mui/material"
import { IS_EXTENSION_TUTORIAL_CARD, tutorials } from "@recall/common"
import { EDITOR_BLOCK_TEXT_DIV_ID } from "components/ItemPage/components/editor/PlateEditor"
import { REFERENCE_DIV_ID } from "components/ItemPage/components/editor/components/ReferenceElement"
import { LINKS_DIV_ID } from "components/ItemPage/components/item/components/Links/Links"
import { TAG_INPUT_DIV_ID } from "components/ItemPage/components/item/components/TagInputChip"
import { useIsMobile } from "hooks/useIsMobile"
import { useQueryParameter } from "hooks/useQueryParameter"
import { FC, memo } from "react"
import { Step, TutorialBase, commonTutorialStyles } from "./TutorialBase"

export interface Props {
    goNext: Function
}

const TutorialItemIntroComponent: FC = () => {
    const isMobile = useIsMobile()
    const { isPresent: isExtensionTutorialCard } = useQueryParameter(IS_EXTENSION_TUTORIAL_CARD)

    const steps: Step[] = [
        {
            anchorId: TAG_INPUT_DIV_ID,
            placement: isMobile ? "bottom" : "right",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Tags
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        Tags allow you to easily categorize cards in the <b>left-side menu</b>.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: EDITOR_BLOCK_TEXT_DIV_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Editor
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        All the content in the card is editable.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: REFERENCE_DIV_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Links
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        This is a link to another card. Hover over the link to see more.
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: LINKS_DIV_ID,
            placement: "top",
            showArrow: true,
            component: () => (
                <DialogContent sx={commonTutorialStyles.popoverContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"}>
                        Card links
                    </Typography>
                    <Typography sx={commonTutorialStyles.body}>
                        All cards linked from this card are listed below.
                    </Typography>
                </DialogContent>
            ),
        },
    ]

    if (isExtensionTutorialCard) {
        steps.unshift({
            anchorId: null,
            placement: null,
            showArrow: false,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Typography sx={commonTutorialStyles.heading} variant={"h4"} textAlign="center">
                        Congratulations
                    </Typography>
                    <Typography sx={commonTutorialStyles.body} textAlign="center" mt={2}>
                        You have added your first knowledge card with the Recall Browser Extension.
                    </Typography>
                </DialogContent>
            ),
        })
    }

    return <TutorialBase tutorialName={tutorials.ITEM_INTRO} steps={steps} />
}

export const TutorialItemIntro = memo(TutorialItemIntroComponent)
