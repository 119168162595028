import { tutorials } from "@recall/common"
import produce from "immer"
import { uniq } from "lodash"
import {
    SET_ACTIVE_TUTORIAL,
    SET_CLOSE_REVIEW_MODAL,
    SET_DB_VERSION,
    SET_DID_UPDATE,
    SET_INITIALIZING_USER,
    SET_IS_APP_INSTALLED,
    SET_ONBOARDING,
    SET_SEARCH_TERM,
    SET_SURVEY_COMPLETED,
    SET_USER,
    TUTORIAL_COMPLETE,
    TUTORIAL_RESTART,
    TUTORIAL_RESTART_SINGLE,
    UPDATE_USER,
} from "./actionTypes"

import { UserState } from "./types"

interface UserReducerAction {
    type: string
    payload: any
}

const initialState: UserState = {
    uid: null,
    email: null,
    initializing: true,
    didUpdate: false,
    isAppInstalled: false,
    searchTerm: "",
    sharedCards: [],
    tutorial: {
        completed: {} as Record<tutorials, boolean>,
        active: "none",
    },
    onboarding: {
        finished: [],
        skipped: [],
        isOpen: false,
    },
    menu: {
        expandedTags: [],
        isGetRecallHidden: false,
    },
    isPremiumUser: false,
    surveys: [],
    dbVersion: null,
    modals: null,
    language: "auto",
    searchLanguage: "en",
    isReviewEmailEnabled: true,
    isOnboardingBannerVisible: true,
}

export const userReducer = (state = initialState, action: UserReducerAction) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case SET_USER: {
                const {
                    email,
                    uid,
                    completedTutorials,
                    isPremiumUser,
                    surveys,
                    modals,
                    menu,
                    language,
                    searchLanguage,
                    sharedCards,
                    isReviewEmailEnabled,
                    isOnboardingBannerVisible,
                } = action.payload
                draftState.uid = uid
                draftState.email = email
                draftState.tutorial.completed = completedTutorials
                draftState.isPremiumUser = isPremiumUser
                draftState.surveys = surveys
                draftState.modals = modals
                draftState.menu = menu
                draftState.language = language || "auto"
                draftState.searchLanguage = searchLanguage || "en"
                draftState.sharedCards = sharedCards || []
                draftState.isReviewEmailEnabled = isReviewEmailEnabled
                draftState.isOnboardingBannerVisible =
                    isOnboardingBannerVisible === undefined
                        ? state.isOnboardingBannerVisible
                        : isOnboardingBannerVisible
                break
            }
            case UPDATE_USER: {
                const {
                    modals,
                    surveys,
                    onboarding,
                    tutorial,
                    menu,
                    language,
                    searchLanguage,
                    sharedCards,
                    isReviewEmailEnabled,
                    isOnboardingBannerVisible,
                }: Partial<UserState> = action.payload
                draftState.modals = modals || state.modals
                draftState.surveys = surveys || state.surveys
                draftState.onboarding = onboarding
                    ? { ...state.onboarding, ...onboarding }
                    : state.onboarding
                draftState.tutorial = tutorial || state.tutorial
                draftState.menu = menu || state.menu
                draftState.language = language || state.language
                draftState.searchLanguage = searchLanguage || state.searchLanguage
                draftState.sharedCards = sharedCards || state.sharedCards
                draftState.isReviewEmailEnabled =
                    isReviewEmailEnabled === undefined
                        ? state.isReviewEmailEnabled
                        : isReviewEmailEnabled
                draftState.isOnboardingBannerVisible =
                    isOnboardingBannerVisible === undefined
                        ? state.isOnboardingBannerVisible
                        : isOnboardingBannerVisible
                break
            }
            case SET_INITIALIZING_USER: {
                const initializing = action.payload
                draftState.initializing = initializing
                break
            }

            case TUTORIAL_COMPLETE: {
                const tutorialName = action.payload
                draftState.tutorial.completed[tutorialName] = true
                draftState.tutorial.active = "none"
                break
            }
            case TUTORIAL_RESTART_SINGLE: {
                const tutorialName = action.payload
                draftState.tutorial.completed[tutorialName] = false
                break
            }
            case TUTORIAL_RESTART: {
                draftState.tutorial.completed = {} as Record<tutorials, boolean>
                break
            }
            case SET_DID_UPDATE: {
                const didUpdate = action.payload
                draftState.didUpdate = didUpdate
                break
            }
            case SET_IS_APP_INSTALLED: {
                const isAppInstalled = action.payload
                draftState.isAppInstalled = isAppInstalled
                break
            }
            case SET_SEARCH_TERM: {
                const searchTerm = action.payload
                draftState.searchTerm = searchTerm
                break
            }
            case SET_ONBOARDING: {
                const onboarding = action.payload
                draftState.onboarding = { ...state.onboarding, ...onboarding }
                break
            }
            case SET_ACTIVE_TUTORIAL: {
                const tutorial = action.payload
                draftState.tutorial.active = tutorial
                break
            }
            case SET_SURVEY_COMPLETED: {
                const survey = action.payload
                draftState.surveys = uniq([...(state.surveys || []), survey])
                break
            }
            case SET_DB_VERSION: {
                const dbVersion = action.payload
                draftState.dbVersion = dbVersion
                break
            }
            case SET_CLOSE_REVIEW_MODAL: {
                draftState.modals = uniq([...(state.surveys || []), action.payload])
                break
            }
        }
    })
