import { useDatabase } from "@nozbe/watermelondb/react"
import { groupBy, keyBy, map, orderBy } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { TagModel } from "storage/watermelon/models"
import { ROOT_TAG_ID, tagRepository } from "storage/watermelon/repository"

export interface TagsWithPath {
    path: string
    tag: TagModel
}

export const useTagsWithPaths = () => {
    const db = useDatabase()
    const [allTags, setAllTags] = useState<TagsWithPath[]>([])

    const orderTagsByRecentUsage = async (tags: TagsWithPath[]) => {
        const itemTags = await tagRepository.getItemTags(db)
        const groupedItemTagsByTagId = groupBy(itemTags, "tag.id")

        const tagsWithDates = []
        for (const tagWithPath of tags) {
            const itemTags = groupedItemTagsByTagId[tagWithPath.tag.id]
            const dates = [...map(itemTags, "createdAt"), tagWithPath.tag.updatedAt]
            const latestDate = Math.max(...dates.map((date) => date.getTime()))
            tagsWithDates.push({ tag: tagWithPath, date: latestDate })
        }
        const orderedTags = map(orderBy(tagsWithDates, "date", "desc"), "tag")

        return orderedTags
    }

    const getTags = useCallback(async () => {
        const tags = await tagRepository.getAll(db)
        const groupedTags = keyBy(tags, "id")
        const tagsWithPaths: TagsWithPath[] = []

        for (const tag of tags) {
            let path = tag.name
            let parentId = tag.parent.id
            while (parentId && parentId !== ROOT_TAG_ID) {
                const parentTag = groupedTags[parentId]
                if (!parentTag) {
                    break
                }

                parentId = parentTag.parent.id
                path = parentTag.name + "/" + path
            }
            tagsWithPaths.push({ tag, path })
        }

        const orderedTags = await orderTagsByRecentUsage(tagsWithPaths)
        setAllTags(orderedTags)
    }, [db])

    useEffect(() => {
        getTags()
    }, [getTags])

    return { allTags, getAllTags: getTags }
}
