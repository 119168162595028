import {
    Code,
    FormatBold,
    FormatItalic,
    FormatStrikethrough,
    FormatUnderlined,
} from "@mui/icons-material"
import {
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_UNDERLINE,
} from "@udecode/plate-basic-marks"
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight"
import { FC } from "react"
import { MarkButton } from "./MarkButton"

interface Props {
    isCodeVisible?: boolean
}

export const MarkButtons: FC<Props> = ({ isCodeVisible = true }) => {
    return (
        <>
            <MarkButton nodeType={MARK_BOLD} tooltip="Bold">
                <FormatBold />
            </MarkButton>
            <MarkButton nodeType={MARK_ITALIC} tooltip="Italic">
                <FormatItalic />
            </MarkButton>
            <MarkButton nodeType={MARK_UNDERLINE} tooltip="Underline">
                <FormatUnderlined />
            </MarkButton>
            <MarkButton nodeType={MARK_STRIKETHROUGH} tooltip="Strikethrough">
                <FormatStrikethrough />
            </MarkButton>
            <MarkButton nodeType={MARK_HIGHLIGHT} tooltip="Highlight">
                <HighlightIcon />
            </MarkButton>
            {isCodeVisible && (
                <MarkButton nodeType={MARK_CODE} tooltip="Code">
                    <Code />
                </MarkButton>
            )}
        </>
    )
}

const HighlightIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="m9 11-6 6v3h9l3-3" />
        <path d="m22 12-4.6 4.6a2 2 0 0 1-2.8 0l-5.2-5.2a2 2 0 0 1 0-2.8L14 4" />
    </svg>
)
