import { Box, Grid, Typography } from "@mui/material"
import { Emoji, EmojiSettings, GridRow, UseEmojiPickerType } from "@udecode/plate-emoji"
import { memo, useCallback } from "react"

export type EmojiPickerContentProps = Pick<
    UseEmojiPickerType,
    | "i18n"
    | "onMouseOver"
    | "onSelectEmoji"
    | "emojiLibrary"
    | "isSearching"
    | "searchResult"
    | "visibleCategories"
    | "refs"
    | "settings"
>

export const EmojiPickerContent = ({
    i18n,
    onSelectEmoji,
    onMouseOver,
    emojiLibrary,
    isSearching = false,
    searchResult,
    visibleCategories,
    refs,
    settings = EmojiSettings,
}: EmojiPickerContentProps) => {
    const getRowWidth = settings.perLine.value * settings.buttonSize.value

    const isCategoryVisible = useCallback(
        (categoryId: any) => {
            return visibleCategories.has(categoryId) ? visibleCategories.get(categoryId) : false
        },
        [visibleCategories]
    )

    const EmojiList = useCallback(() => {
        return emojiLibrary
            .getGrid()
            .sections()
            .map(({ id: categoryId }) => {
                const section = emojiLibrary.getGrid().section(categoryId)
                const { buttonSize } = settings
                return (
                    <Box key={categoryId} data-id={categoryId} ref={section.root}>
                        <Typography
                            sx={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                                backdropFilter: "blur(4px)",
                                fontWeight: 500,
                            }}
                        >
                            {i18n.categories[categoryId]}
                        </Typography>
                        <Box sx={{ height: section.getRows().length * buttonSize.value }}>
                            {isCategoryVisible(categoryId) &&
                                section
                                    .getRows()
                                    .map((row: GridRow, index) => (
                                        <RowOfEmoji
                                            key={index}
                                            emojiLibrary={emojiLibrary}
                                            row={row}
                                            onSelectEmoji={onSelectEmoji}
                                            onMouseOver={onMouseOver}
                                        />
                                    ))}
                        </Box>
                    </Box>
                )
            })
    }, [
        emojiLibrary,
        getRowWidth,
        i18n.categories,
        isCategoryVisible,
        onSelectEmoji,
        onMouseOver,
        settings,
    ])

    const SearchList = useCallback(() => {
        return (
            <Box data-id="search">
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        backdropFilter: "blur(4px)",
                        fontWeight: 500,
                    }}
                >
                    {i18n.searchResult}
                </Box>
                <Grid container>
                    {searchResult.map((emoji: Emoji, index: number) => (
                        <Grid xs={12 / 8}>
                            <EmojiButton
                                key={emoji.id}
                                index={index}
                                emoji={emojiLibrary.getEmoji(emoji.id)}
                                onSelect={onSelectEmoji}
                                onMouseOver={onMouseOver}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }, [emojiLibrary, getRowWidth, i18n.searchResult, searchResult, onSelectEmoji, onMouseOver])
    return (
        <Box
            ref={refs.current.contentRoot}
            sx={{ minHeight: "50%", height: "100%", overflowY: "auto" }}
        >
            <Box ref={refs.current.content}>{isSearching ? SearchList() : EmojiList()}</Box>
        </Box>
    )
}

export type EmojiButtonProps = {
    index: number
    emoji: Emoji
    onSelect: (emoji: Emoji) => void
    onMouseOver: (emoji?: Emoji) => void
}

const EmojiButton = memo(({ index, emoji, onSelect, onMouseOver }: EmojiButtonProps) => {
    return (
        <Box
            aria-label={emoji.skins[0].native}
            tabIndex={-1}
            data-index={index}
            onClick={() => onSelect(emoji)}
            onMouseEnter={() => onMouseOver(emoji)}
            onMouseLeave={() => onMouseOver()}
            sx={{
                fontSize: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            {emoji.skins[0].native}
        </Box>
    )
})

export type RowOfButtonsProps = Pick<
    UseEmojiPickerType,
    "onMouseOver" | "onSelectEmoji" | "emojiLibrary"
> & {
    row: GridRow
}

const RowOfEmoji = memo(({ row, emojiLibrary, onSelectEmoji, onMouseOver }: RowOfButtonsProps) => (
    <Grid container key={row.id} data-index={row.id} display="flex">
        {row.elements.map((emojiId, index) => (
            <Grid key={emojiId} item xs={12 / 8}>
                <EmojiButton
                    index={index}
                    emoji={emojiLibrary.getEmoji(emojiId)}
                    onSelect={onSelectEmoji}
                    onMouseOver={onMouseOver}
                />
            </Grid>
        ))}
    </Grid>
))
