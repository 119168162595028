import { TotalCountBadge } from "@recall/common"
import { FC, useEffect, useState } from "react"
import { ItemModel } from "storage/watermelon/models"

interface Props {
    onlyOtherMentionCount?: boolean
    item: ItemModel
}

export const MentionCountBadge: FC<Props> = ({ item, onlyOtherMentionCount }) => {
    const [mentions, setMentions] = useState<number>()

    useEffect(() => {
        getCount()
    }, [])

    const getCount = async () => {
        const mentionsCount = await item.mentions.count
        setMentions(mentionsCount)
    }

    if (!mentions) return null

    return (
        <TotalCountBadge
            name={item.name}
            count={mentions}
            color={"default"}
            onlyOtherMentionCount={onlyOtherMentionCount}
        />
    )
}
