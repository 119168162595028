import { useDatabase } from "@nozbe/watermelondb/react"
import { expandShortenYouTubeUrl, isShortenYouTubeVideo, sentry, WIKIPEDIA } from "@recall/common"
import { IN_APP_SUMMARY_GENERATED } from "constants/events"
import { useSelector } from "react-redux"
import { summariesApi } from "services/api"
import { captureEventService } from "services/captureEventService"
import { itemAPI } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"
import { ItemModel } from "storage/watermelon/models"
import { itemRepository } from "storage/watermelon/repository"
import { useUsageUtils } from "./useUsageUtils"

const WIKIPEDIA_URL_REGEX = /https:\/\/.+\.wikipedia\.org\/wiki\/.+/gm
const WIKIPEDIA_SLUG_SEPARATOR = "/wiki/"

const getWikipediaSlug = (url: string) => {
    if (!url.match(WIKIPEDIA_URL_REGEX)) return null

    const urlParts = url.split(WIKIPEDIA_SLUG_SEPARATOR)
    const slug = urlParts?.[urlParts.length - 1]

    return slug || null
}

export type UsageType = "bookmark-import" | "summary"

export const useItemUtils = () => {
    const db = useDatabase()
    const { increaseSummariesUsage, increaseBookmarksImportUsage } = useUsageUtils()
    const userId = useSelector((state: RootState) => state.user.uid)
    const language = useSelector((state: RootState) => state.user.searchLanguage)

    const expandShortenUrl = (url: string) => {
        if (isShortenYouTubeVideo(url)) {
            return expandShortenYouTubeUrl(url)
        }

        return url
    }

    const getRealUrl = async (url: string): Promise<string> => {
        try {
            if (url.length >= 30) return url

            const newUrl = await summariesApi.expandSummaryUrl(url)

            if (newUrl) return newUrl

            return url
        } catch (err) {
            sentry.captureException(err, { message: "Failed to expand url", url })
            return url
        }
    }

    const getExistingItem = async (url: string) => {
        let item = await itemRepository.getBySource({ db, sourceIdentifier: url })

        if (item) return item

        const wikipediaSlug = getWikipediaSlug(url)

        if (!wikipediaSlug) return null

        item = await itemRepository.getBySource({
            db,
            sourceIdentifier: wikipediaSlug,
            sourceName: WIKIPEDIA,
        })

        return item || null
    }

    const generateItem = async (
        pageUrl: string,
        type: UsageType = "summary"
    ): Promise<{ item: ItemModel | null; error?: any }> => {
        if (!pageUrl) return null

        const url = await getRealUrl(expandShortenUrl(pageUrl))

        const existingItem = await getExistingItem(url)

        if (existingItem) {
            return { item: existingItem }
        }

        const { item, cost, error } = await itemAPI.getAndSaveScraped(db, url, language)

        if (!item) sentry.captureMessage("Failed to scrape item", { url })
        else if (type === "summary") await increaseSummariesUsage(userId, cost)
        else await increaseBookmarksImportUsage(userId, cost)

        captureEventService.capture(`${IN_APP_SUMMARY_GENERATED} - ${type}`)

        return { item, error }
    }

    return { generateItem, getExistingItem }
}
