import { ComboboxItemProps } from "@udecode/plate-combobox"
import {
    EmojiItemData,
    KEY_EMOJI,
    TEmojiCombobox,
    useEmojiComboboxState,
} from "@udecode/plate-emoji"

import { Box } from "@mui/material"
import { Combobox } from "../Combobox"

export function EmojiComboboxItem({
    item,
    ...props
}: ComboboxItemProps<EmojiItemData>): JSX.Element {
    const {
        data: { id, emoji },
    } = item

    return (
        <Box sx={{ px: 0.5, display: "flex", alignItems: "center" }}>
            {emoji} :{id}:
        </Box>
    )
}

export const EmojiCombobox = <TData extends EmojiItemData = EmojiItemData>({
    pluginKey = KEY_EMOJI,
    id = pluginKey,
    ...props
}: TEmojiCombobox<TData>) => {
    const { trigger, onSelectItem } = useEmojiComboboxState({ pluginKey })

    return (
        <Combobox
            id={id}
            trigger={trigger}
            controlled
            onSelectItem={onSelectItem}
            onRenderItem={EmojiComboboxItem}
            {...props}
        />
    )
}
