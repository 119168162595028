import { ItemApi } from "@recall/common"

export const DEFAULT_CARD: ItemApi = {
    name: "ChatGPT",
    isReference: false,
    isSaved: false,
    language: "en",
    type: {
        name: "LanguageModel",
        display: "Language Model",
        pluralDisplay: "",
        wikidataId: "",
        userCreated: false,
        parentName: "SoftwareApplication",
        genealogy: [
            {
                name: "SoftwareApplication",
                display: "Software Application",
                pluralDisplay: "",
                wikidataId: "",
                userCreated: false,
                parentName: "Thing",
                genealogy: null,
            },
            {
                name: "Thing",
                display: "Thing",
                pluralDisplay: "Things",
                wikidataId: "",
                parentName: "",
                userCreated: false,
                genealogy: [],
            },
        ],
    },
    description: "AI chatbot developed by OpenAI",
    editorBlocks: [
        {
            type: "editor-block",
            children: [
                {
                    type: "image",
                    urlOriginal:
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png",
                    urlThumbnail:
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/320px-ChatGPT_logo.svg.png",
                    url_320:
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/320px-ChatGPT_logo.svg.png",
                    url_1024:
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png",
                    caption: "",
                    children: [
                        {
                            text: "",
                            bold: false,
                        },
                    ],
                },
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "ChatGPT is an ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Artificial_intelligence",
                            children: [
                                {
                                    text: "artificial-intelligence",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " (AI) ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Chatbot",
                            children: [
                                {
                                    text: "chatbot",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " developed by ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "OpenAI",
                            children: [
                                {
                                    text: "OpenAI",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " and launched in November 2022.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "It is built on top of OpenAI's  and  families of ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Large_language_model",
                            children: [
                                {
                                    text: "large language models",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " (LLMs) and has been ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Fine-tuning_(machine_learning)",
                            children: [
                                {
                                    text: "fine-tuned",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " (an approach to ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Transfer_learning",
                            children: [
                                {
                                    text: "transfer learning",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: ") using both ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Supervised_learning",
                            children: [
                                {
                                    text: "supervised",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " and ",
                            bold: false,
                        },
                        {
                            type: "reference",
                            connectionId: "Reinforcement_learning",
                            children: [
                                {
                                    text: "reinforcement learning",
                                    bold: false,
                                },
                            ],
                        },
                        {
                            text: " techniques.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "ChatGPT was launched as a prototype on November 30, 2022.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "It garnered attention for its detailed responses and articulate answers across many domains of knowledge.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "Its uneven factual accuracy, however, has been identified as a significant drawback.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "Following the release of ChatGPT, OpenAI's valuation was estimated at billion in 2023.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "The original release of ChatGPT was based on GPT-3.5.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
        {
            type: "editor-block",
            indent: 1,
            listStyleType: "disc",
            options: { listStyleType: "disc", indent: 1 },
            children: [
                {
                    type: "paragraph",
                    children: [
                        {
                            text: "A version based on GPT-4, the newest OpenAI model, was released on March 14, 2023, and is available for paid subscribers on a limited basis.",
                            bold: false,
                        },
                    ],
                },
            ],
        },
    ],
    images: [
        {
            urlOriginal:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png",
            urlThumbnail:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/320px-ChatGPT_logo.svg.png",
            url_320:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/320px-ChatGPT_logo.svg.png",
            url_1024:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png",
            caption: "ChatGPT logo.",
        },
    ],
    sources: [
        {
            name: "wikidata",
            identifier: "Q115564437",
        },
        {
            name: "wikipedia",
            identifier: "ChatGPT",
        },
    ],
    tags: [],
    links: [
        {
            item: {
                name: "Supervised learning",
                isReference: true,
                isSaved: false,
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Things",
                    wikidataId: "",
                    parentName: "",
                    userCreated: false,
                    genealogy: [],
                },
                language: "en",
                description: "Machine learning task",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/f/fe/Kernel_Machine.svg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/100px-Kernel_Machine.svg.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/320px-Kernel_Machine.svg.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/1024px-Kernel_Machine.svg.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Machine learning task",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/f/fe/Kernel_Machine.svg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/100px-Kernel_Machine.svg.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/320px-Kernel_Machine.svg.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/1024px-Kernel_Machine.svg.png",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Supervised_learning",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q334384",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Supervised_learning",
        },
        {
            item: {
                name: "Reinforcement learning",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Things",
                    wikidataId: "",
                    parentName: "",
                    userCreated: false,
                    genealogy: [],
                },
                description: "Field of machine learning",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/f/fe/Kernel_Machine.svg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/100px-Kernel_Machine.svg.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/320px-Kernel_Machine.svg.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/1024px-Kernel_Machine.svg.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Field of machine learning",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/f/fe/Kernel_Machine.svg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/100px-Kernel_Machine.svg.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/320px-Kernel_Machine.svg.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Kernel_Machine.svg/1024px-Kernel_Machine.svg.png",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Reinforcement_learning",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q830687",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Reinforcement_learning",
        },
        {
            item: {
                name: "Chatbot",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Things",
                    wikidataId: "",
                    parentName: "",
                    userCreated: false,
                    genealogy: [],
                },
                description: "Program that simulates conversation",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/8/8b/Automated_online_assistant.png",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/100px-Automated_online_assistant.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/320px-Automated_online_assistant.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/1024px-Automated_online_assistant.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Program that simulates conversation",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/8/8b/Automated_online_assistant.png",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/100px-Automated_online_assistant.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/320px-Automated_online_assistant.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Automated_online_assistant.png/1024px-Automated_online_assistant.png",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Chatbot",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q870780",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Chatbot",
        },
        {
            item: {
                name: "Transfer learning",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Things",
                    wikidataId: "",
                    parentName: "",
                    userCreated: false,
                    genealogy: [],
                },
                description: "Applying previously-learned knowledge to new problems",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Applying previously-learned knowledge to new problems",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Transfer_learning",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q6027324",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Transfer_learning",
        },
        {
            item: {
                name: "OpenAI",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Corporation",
                    display: "Corporation",
                    pluralDisplay: "",
                    wikidataId: "",
                    userCreated: false,
                    parentName: "Organization",
                    genealogy: [
                        {
                            name: "Organization",
                            display: "Organization",
                            pluralDisplay: "Organizations",
                            wikidataId: "Q43229",
                            userCreated: false,
                            parentName: "Thing",
                            genealogy: [
                                {
                                    name: "Thing",
                                    display: "Thing",
                                    pluralDisplay: "Things",
                                    wikidataId: "",
                                    parentName: "",
                                    userCreated: false,
                                    genealogy: [],
                                },
                            ],
                        },
                        {
                            name: "Thing",
                            display: "Thing",
                            pluralDisplay: "Things",
                            wikidataId: "",
                            parentName: "",
                            userCreated: false,
                            genealogy: [],
                        },
                    ],
                },
                description: "American artificial intelligence (AI) research laboratory",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/100px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/320px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/1024px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "American artificial intelligence (AI) research laboratory",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/100px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/320px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg/1024px-Pioneer_Building%2C_San_Francisco_%282019%29_-1.jpg",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "OpenAI",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q21708200",
                    },
                    {
                        name: "twitter",
                        identifier: "OpenAI",
                    },
                    {
                        name: "youtube",
                        identifier: "UCXZCJLdBC09xxGZ6gcdrc6A",
                    },
                    {
                        name: "github",
                        identifier: "openai",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "OpenAI",
        },
        {
            item: {
                name: "Large language model",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    userCreated: false,
                    parentName: null,
                    genealogy: null,
                },
                description: "Language model consisting of a neural network",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/5/57/LLM_emergent_benchmarks.png",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/100px-LLM_emergent_benchmarks.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/320px-LLM_emergent_benchmarks.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/1024px-LLM_emergent_benchmarks.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Language model consisting of a neural network",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/5/57/LLM_emergent_benchmarks.png",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/100px-LLM_emergent_benchmarks.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/320px-LLM_emergent_benchmarks.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/LLM_emergent_benchmarks.png/1024px-LLM_emergent_benchmarks.png",
                        caption:
                            "On a number of natural language benchmarks involving tasks such as question answering, models perform no better than random chance until they reach a certain scale (in this case, measured by training computation), at which point their performance sharply increases. These are examples of emergent abilities.",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Large_language_model",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q115305900",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Large_language_model",
        },
        {
            item: {
                name: "Fine-tuning (machine learning)",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    parentName: null,
                    userCreated: false,
                    genealogy: null,
                },
                description: "Machine learning technique",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Machine learning technique",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Fine-tuning_(machine_learning)",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q117286419",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Fine-tuning_(machine_learning)",
        },
        {
            item: {
                name: "Artificial intelligence",
                isReference: true,
                isSaved: false,
                language: "en",
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Things",
                    wikidataId: "",
                    parentName: "",
                    userCreated: false,
                    genealogy: [],
                },
                description: "Ability of systems to perceive, synthesize, and infer information",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/100px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/320px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/1024px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Ability of systems to perceive, synthesize, and infer information",
                                        bold: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/100px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/320px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Artificial_intelligence_prompt_completion_by_dalle_mini.jpg/1024px-Artificial_intelligence_prompt_completion_by_dalle_mini.jpg",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Artificial_intelligence",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q11660",
                    },
                ],
                tags: [],
            },
            property: null,
            slug: "Artificial_intelligence",
        },
    ],
}
