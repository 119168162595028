import imageExtensions from "image-extensions"
import isUrl from "is-url"

export const ImageBlockData = {
    getUrl(imageBlock) {
        return imageBlock.urlOriginal
    },

    getUrl320(imageBlock) {
        if (imageBlock.url_320) {
            return imageBlock.url_320
        } else {
            return this.getUrl(imageBlock)
        }
    },

    getUrl1024(imageBlock) {
        if (imageBlock.url_1024) {
            return imageBlock.url_1024
        } else {
            return this.getUrl(imageBlock)
        }
    },

    getUrlThumbnail(imageBlock) {
        if (imageBlock.urlThumbnail) {
            return imageBlock.urlThumbnail
        } else {
            return this.getUrl(imageBlock)
        }
    },

    isImageUrl(url) {
        if (!url) return false
        if (!isUrl(url)) return false
        const ext = new URL(url).pathname.split(".").pop()
        return imageExtensions.includes(ext)
    },
}
