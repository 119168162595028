import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import React from "react"

export const HrElement = React.forwardRef<React.ElementRef<typeof PlateElement>, PlateElementProps>(
    ({ className, nodeProps, ...props }, ref) => {
        const { children } = props

        return (
            <PlateElement ref={ref} {...props}>
                <div contentEditable={false}>
                    <hr {...nodeProps} />
                </div>
                {children}
            </PlateElement>
        )
    }
)
HrElement.displayName = "HrElement"
