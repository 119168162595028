import { Box, Typography } from "@mui/material"
import { withObservables } from "@nozbe/watermelondb/react"
import React from "react"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { EditorBlockModel, EditorOrderModel } from "storage/watermelon/models"

interface Props {
    showMentionsOf: string
    editorBlocks: EditorBlockModel[]
    editorOrders: EditorOrderModel[]
}

const MentionedDetailsComp: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const getParagraphText = (paragraph) => {
        let text = ""
        for (let child of paragraph.children) {
            if (child.text !== undefined) {
                text += child.text
            }
        }
        return text
    }

    let mentionedParagraphs = []

    if (props.editorOrders.length === 0) {
        return null
    }

    const orderedEditorBlocks = EditorBlockData.getOrdered(
        props.editorBlocks,
        props.editorOrders[0]
    )

    for (let editorBlock of orderedEditorBlocks) {
        const hasReference = EditorBlockData.hasReference(editorBlock, props.showMentionsOf)

        if (hasReference) {
            let combinedParagraphs = []
            for (let child of editorBlock.children) {
                if (child.type === "paragraph") {
                    const text = getParagraphText(child)

                    // TODO - I am sure there is a better way to structure this.
                    // Only add paragraphs that have text besides from the reference text.
                    if (text !== "") {
                        combinedParagraphs = [...combinedParagraphs, ...child.children]
                    }
                }
            }
            if (combinedParagraphs.length > 0) {
                mentionedParagraphs.push(combinedParagraphs)
            }
        }
    }

    if (mentionedParagraphs.length === 0) {
        return null
    }

    return (
        <Box component={"ul"} fontSize={14} pr={0.5} pl={2.5}>
            {mentionedParagraphs.map((paragraph, index) => {
                return (
                    <Box component="li" key={index} pb={0.5}>
                        {paragraph.map((part: any, index) => {
                            if (part.text) {
                                return <React.Fragment key={index}>{part.text}</React.Fragment>
                            } else if (part.type === "reference") {
                                if (part.connectionId === props.showMentionsOf) {
                                    return (
                                        <Typography
                                            sx={{ display: "inline", fontWeight: 800 }}
                                            variant="body2"
                                            key={index}
                                        >
                                            {part.children[0].text}
                                        </Typography>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={index}>
                                            {part.children[0].text}
                                        </React.Fragment>
                                    )
                                }
                            }
                            return <React.Fragment key={index}></React.Fragment>
                        })}
                    </Box>
                )
            })}
        </Box>
    )
}

const enhance = withObservables(
    ["editorOrders", "editorBlocks"],
    ({ editorOrders, editorBlocks }) => ({
        editorOrders: editorOrders.observe(),
        editorBlocks: editorBlocks.observe(),
    })
)

const EnhancedMentionedDetailsComp = enhance(MentionedDetailsComp)
export default EnhancedMentionedDetailsComp
