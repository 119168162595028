var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { alpha, Avatar, Chip, Grid } from "@mui/material";
import { useCallback } from "react";
import { assets } from "../assets";
const examples = [
    {
        url: "https://www.youtube.com/watch?v=hFL6qRIJZ_Y",
        title: "Podcast",
        icon: assets.PODCAST_ICON.url,
    },
    {
        url: "https://www.youtube.com/watch?v=Jt7hE12n11s",
        title: "Youtube Video",
        icon: assets.VIDEO_ICON.url,
    },
    {
        url: "https://www.nature.com/articles/d41586-023-02296-z",
        title: "Blog Post",
        icon: assets.BLOG_ICON.url,
    },
    {
        url: "https://www.nytimes.com/2023/02/17/business/china-chatgpt-microsoft-openai.html",
        title: "News Article",
        icon: assets.NEWS_ICON.url,
    },
    {
        url: "https://en.wikipedia.org/wiki/Hedonic_treadmill",
        title: "Wikipedia page",
        icon: assets.WIKIPEDIA_ICON.url,
    },
];
export const ExampleLinks = ({ small = true }) => {
    const makeHandleClick = useCallback((url) => () => __awaiter(void 0, void 0, void 0, function* () {
        if (window) {
            // @ts-ignore
            window.open(url, "_blank").focus();
            return;
        }
        document.location.href = url;
    }), []);
    return (_jsx(Grid, Object.assign({ container: true, justifyContent: "center", alignItems: "center", spacing: 1.5 }, { children: examples.map((exampleLink) => (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Chip, { avatar: _jsx(Avatar, { alt: exampleLink.title, src: exampleLink.icon }), onClick: makeHandleClick(exampleLink.url), label: exampleLink.title, sx: styles.chip, size: small ? "small" : undefined }) }), exampleLink.title))) })));
};
const styles = {
    chip: {
        color: alpha("#000000", 0.8),
        fontWeight: 500,
        backgroundColor: alpha("#FFFFFF", 0.3),
        "&:hover": {
            backgroundColor: alpha("#FFFFFF", 0.5),
        },
    },
};
