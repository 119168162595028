export const languages = {
    en: { label: "English", flag: "🇬🇧" },
    de: { label: "German", flag: "🇩🇪" },
    fr: { label: "French", flag: "🇫🇷" },
    es: { label: "Spanish", flag: "🇪🇸" },
    it: { label: "Italian", flag: "🇮🇹" },
    zh: { label: "Chinese", flag: "🇨🇳" },
    ja: { label: "Japanese", flag: "🇯🇵" },
    ko: { label: "Korean", flag: "🇰🇷" },
    pt: { label: "Portuguese", flag: "🇵🇹" },
    ru: { label: "Russian", flag: "🇷🇺" },
};
