import { focusEditor } from "@udecode/plate-common"
import CustomEditor from "components/ItemPage/components/editor/utils/CustomEditor"
import { useEffect } from "react"
import { Transforms } from "slate"
import { MyReferenceElement, useMyEditorState } from "../types"

export const INSERT_SEARCH_REFERENCE_EVENT = "event-insert-search-reference"

export const useConnectionDeleted = () => {
    const editor = useMyEditorState()

    useEffect(() => {
        const handleRemoveConnection = (e) => {
            const connectionId: string = e.detail.connectionId

            setTimeout(() =>
                Transforms.unwrapNodes(editor, {
                    at: [],
                    match: (node: MyReferenceElement) => node.connectionId === connectionId,
                })
            )
        }

        const insertSearchReference = () => {
            focusEditor(editor)
            CustomEditor.insertSearchReference(editor, true)
        }

        document.addEventListener(INSERT_SEARCH_REFERENCE_EVENT, insertSearchReference)
        document.addEventListener("connectionDeleted", handleRemoveConnection)

        return () => {
            document.removeEventListener("connectionDeleted", handleRemoveConnection)
            document.removeEventListener(INSERT_SEARCH_REFERENCE_EVENT, insertSearchReference)
        }
    }, [editor])
}
