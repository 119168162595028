var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sentry } from "../sentry";
export class HttpClientError extends Error {
    constructor(status, statusText, url, details) {
        super(`Error ${status} ${statusText} in ${url}`);
        this.status = status;
        this.statusText = statusText;
        this.url = url;
        this.message = (details === null || details === void 0 ? void 0 : details.message) || "";
        this.details = details || {};
    }
}
export class HttpClient {
    constructor(baseURL, defaultHeaders) {
        this.baseURL = baseURL;
        this.defaultHeaders = defaultHeaders || {};
    }
    initHeaders(headers = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return Object.assign(Object.assign({}, this.defaultHeaders), headers);
        });
    }
    handleResponse(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                const error = yield response.json();
                throw new HttpClientError(response.status, response.statusText, response.url, error);
            }
            return response;
        });
    }
    get(path, headers = { "Content-Type": "application/json" }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${this.baseURL}${path}`;
                const initHeaders = yield this.initHeaders(headers);
                const response = yield fetch(url, { method: "GET", headers: initHeaders });
                return this.handleResponse(response);
            }
            catch (err) {
                sentry.captureException(err);
                throw err;
            }
        });
    }
    post(path, body, headers = { "Content-Type": "application/json" }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${this.baseURL}${path}`;
                const initHeaders = yield this.initHeaders(headers);
                const response = yield fetch(url, {
                    method: "POST",
                    headers: initHeaders,
                    body,
                });
                return this.handleResponse(response);
            }
            catch (err) {
                sentry.captureException(err);
                throw err;
            }
        });
    }
    head(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(url, { method: "HEAD" });
            return Object.fromEntries(response.headers.entries());
        });
    }
}
