import { useTheme } from "@mui/material/styles"
import { FC } from "react"
import { LinkIcon } from "./LinkIcon"

interface Props {
    count?: number
}

export const OutgoingLinkIcon: FC<Props> = ({ count }) => {
    const theme = useTheme()

    return (
        <LinkIcon
            count={count}
            iconSVG={
                <svg
                    width="193"
                    height="193"
                    viewBox="0 0 193 193"
                    fill={theme.palette.action.selected}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_24)">
                        <ellipse
                            cx="69.5054"
                            cy="122.23"
                            rx="69.197"
                            ry="68.1547"
                            transform="rotate(45 69.5054 122.23)"
                        />
                        <path d="M68.7964 1.77008C69.5773 0.500374 71.4227 0.500374 72.2036 1.77008L89.8433 30.4523C90.6628 31.7848 89.704 33.5 88.1397 33.5H52.8603C51.2959 33.5 50.3372 31.7848 51.1567 30.4523L68.7964 1.77008Z" />
                        <rect x="64" y="33" width="13" height="21" />
                        <path d="M157.688 31.0782C159.138 30.7326 160.443 32.0375 160.097 33.4875L152.289 66.242C151.926 67.7637 150.035 68.2986 148.929 67.1924L123.983 42.2462C122.877 41.14 123.411 39.2492 124.933 38.8865L157.688 31.0782Z" />
                        <rect
                            x="132.141"
                            y="50.3774"
                            width="13"
                            height="27.3094"
                            transform="rotate(45 132.141 50.3774)"
                        />
                        <path d="M191.23 119.796C192.5 120.577 192.5 122.423 191.23 123.204L162.548 140.843C161.215 141.663 159.5 140.704 159.5 139.14L159.5 103.86C159.5 102.296 161.215 101.337 162.548 102.157L191.23 119.796Z" />
                        <rect
                            x="160"
                            y="115"
                            width="13"
                            height="23"
                            transform="rotate(90 160 115)"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_24">
                            <rect width="193" height="193" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            }
        />
    )
}
