var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, where, } from "firebase/firestore";
import { uniqBy } from "lodash";
import { firebase } from "../services";
export class ExtensionItemsRepository {
    constructor() {
        this.getExtensionItems = (uid) => __awaiter(this, void 0, void 0, function* () {
            const querySnapshot = yield getDocs(collection(firebase.firestore, `users/${uid}/extension_items`));
            const documents = [];
            querySnapshot.forEach((doc) => {
                documents.push(Object.assign({ id: doc.id }, doc.data()));
            });
            return documents;
        });
        this.getExtensionItemByUrl = (uid, url) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const querySnapshot = yield getDocs(query(collection(firebase.firestore, `users/${uid}/extension_items`), where("url", "==", url)));
            const doc = (_a = querySnapshot.docs) === null || _a === void 0 ? void 0 : _a[0];
            if (doc) {
                return Object.assign({ id: doc.id }, doc.data());
            }
            else {
                return null;
            }
        });
        this.createExtensionItem = (uid, payload) => __awaiter(this, void 0, void 0, function* () {
            if (!uid)
                throw new Error("User id must be provided");
            const extensionItem = Object.assign(Object.assign({}, payload), { createdAt: Date.now() });
            yield addDoc(collection(firebase.firestore, `users/${uid}/extension_items`), extensionItem);
            return extensionItem;
        });
        this.deleteExtensionItem = (uid, id) => __awaiter(this, void 0, void 0, function* () {
            yield deleteDoc(doc(firebase.firestore, `users/${uid}/extension_items`, id));
        });
        this.observeExtensionItems = (uid, callback) => {
            const collectionRef = collection(firebase.firestore, `users/${uid}/extension_items`);
            const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
                const documents = [];
                const addedSnapshots = snapshot.docChanges().filter((change) => {
                    return change.type === "added";
                });
                addedSnapshots.forEach((change) => {
                    documents.push(Object.assign({ id: change.doc.id }, change.doc.data()));
                });
                const uniqueExtensionItems = uniqBy(documents, "id");
                if (!!uniqueExtensionItems.length)
                    callback(uniqueExtensionItems);
            });
            return unsubscribe;
        };
    }
}
