import { PushPin, PushPinOutlined } from "@mui/icons-material"
import { IconButton, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_INCLUDE_REFERENCES } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const ReferencesSwitch = () => {
    const dispatch = useDispatch()
    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )

    const handleToggle = (e) => {
        e.stopPropagation()
        dispatch({ type: SET_DRAWER_INCLUDE_REFERENCES, payload: !includeReferences })
    }

    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2">
                        Show <b>{includeReferences ? "only pinned" : "all"}</b> cards
                    </Typography>
                    {!includeReferences && (
                        <i>
                            All cards will show in the menu below including cards that were created
                            as links that have not been pinned.
                        </i>
                    )}
                </>
            }
        >
            <IconButton sx={{ transform: "rotate(45deg)" }} size="small" onClick={handleToggle}>
                {includeReferences ? <PushPinOutlined /> : <PushPin />}
            </IconButton>
        </Tooltip>
    )
}
