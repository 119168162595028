import { format, formatDistance } from "date-fns"

const PRETTY_DATETIME_TEMPLATE = "yyyy-MM-dd HH:mm:ss"
const DATETIME_TEMPLATE = "yyyy-MM-dd'T'HH-mm-ss"

const formatDateTime = (date: Date) => {
    return format(date, DATETIME_TEMPLATE)
}

const formatPrettyDateTime = (date: Date) => {
    return format(date, PRETTY_DATETIME_TEMPLATE)
}

const formatNow = () => {
    const date = new Date()
    return formatDateTime(date)
}

const formatTimeAgo = (date: Date) => {
    return formatDistance(date, new Date(), { addSuffix: true })
}

export const dates = { formatNow, formatDateTime, formatTimeAgo, formatPrettyDateTime }
