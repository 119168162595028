var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { alpha, Box, Chip, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
const TAG_WIDTH_WITH_SCROLL_IN_PX = 28;
export const NestedTagChip = (_a) => {
    var { size = "small", isMobile = false, searchText, tagNesting } = _a, props = __rest(_a, ["size", "isMobile", "searchText", "tagNesting"]);
    const [shouldWrap, setShouldWrap] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (isMobile || !(ref === null || ref === void 0 ? void 0 : ref.current) || !(ref === null || ref === void 0 ? void 0 : ref.current.parentElement))
            return;
        if (props.onDelete &&
            ref.current.getBoundingClientRect().right + TAG_WIDTH_WITH_SCROLL_IN_PX >
                ref.current.parentElement.getBoundingClientRect().right) {
            setShouldWrap(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [shouldWrap && _jsx(Box, { width: TAG_WIDTH_WITH_SCROLL_IN_PX }), _jsx(Chip, Object.assign({ ref: ref, size: size, sx: isMobile
                    ? styles.chip
                    : Object.assign(Object.assign({}, styles.chip), { "& .MuiChip-deleteIcon": {
                            width: 0,
                            transform: "scale(0)",
                            transition: "all 0.2s ease",
                        }, "&:hover": {
                            "& .MuiChip-deleteIcon": {
                                width: 17,
                                transform: "scale(1)",
                            },
                        } }), variant: props.selected ? "filled" : "outlined", color: props.selected ? "secondary" : "default" }, props, { label: _jsx(Box, Object.assign({ sx: styles.container }, { children: tagNesting.map((tag, index) => {
                        const isLast = index === tagNesting.length - 1;
                        return (_jsxs(Fragment, { children: [_jsx(Typography, Object.assign({ sx: {
                                        fontSize: "12px",
                                        fontWeight: isLast ? 600 : 400,
                                        color: (theme) => isLast
                                            ? theme.palette.text.primary
                                            : alpha(theme.palette.text.primary, 0.7),
                                    } }, { children: tag })), !isLast && (_jsx(Box, Object.assign({ component: "span", sx: {
                                        color: (theme) => alpha(theme.palette.text.primary, 0.2),
                                    } }, { children: "|" })))] }, index));
                    }) })) }))] }));
};
const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "wrap",
        columnGap: 1,
        rowGap: 0,
    },
    chip: {
        height: "auto",
        px: 0.7,
        py: 0.5,
    },
};
