import { Box, Button, Dialog, DialogContent, SxProps, Theme, alpha } from "@mui/material"
import TextField from "@mui/material/TextField"
import { firebase, getTheme } from "@recall/common"
import SignUpProvider from "components/UserPages/SignUpPage/SignUpProvider"
import { HEADER_HEIGHT } from "components/layouts/DarkLayout"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { FirebaseError } from "firebase/app"
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    reauthenticateWithCredential,
    reauthenticateWithPopup,
} from "firebase/auth"
import { useFormik } from "formik"
import { FC, useState } from "react"
import { toast } from "react-toastify"
import * as yup from "yup"

interface LoginFields {
    email: string
    password: string
}

const validationSchema = yup.object({
    email: yup.string().trim().email().required("No email address provided."),
    password: yup.string().required("No password provided."),
})

interface Props {
    callback: () => void
    handleClose: () => void
}

export const ReauthenticateModal: FC<Props> = ({ callback, handleClose }) => {
    const [loading, setLoading] = useState(false)

    const onSubmit = async (values: LoginFields) => {
        setLoading(true)
        try {
            await reauthenticateWithCredential(
                firebase.auth.currentUser,
                EmailAuthProvider.credential(values.email.trim(), values.password)
            )
            callback()
        } catch {
            formik.setErrors({
                email: "Invalid email or password.",
                password: "Invalid email or password.",
            })
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik<LoginFields>({
        enableReinitialize: true,
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema,
        onSubmit,
    })

    const makeReauthenticate = (provider: OAuthProvider | GoogleAuthProvider) => async () => {
        try {
            await reauthenticateWithPopup(firebase.auth.currentUser, provider)
        } catch (err) {
            const error = err as FirebaseError

            if (error.code === "auth/user-mismatch") {
                toast.error("Wrong user")
            }
        }
        callback()
    }

    return (
        <Dialog open onClose={handleClose}>
            <DialogContent>
                <SignUpProvider
                    reauthenticateWithGoogle={makeReauthenticate(new GoogleAuthProvider())}
                    reauthenticateWithApple={makeReauthenticate(new OAuthProvider("apple.com"))}
                />
                <Box mt={1} component="form" onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        sx={styles.textField}
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={
                            formik.touched.email &&
                            formik.errors.email &&
                            String(formik.errors.email)
                        }
                        data-testid="email"
                    />
                    <TextField
                        fullWidth
                        sx={styles.textField}
                        name="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={
                            formik.touched.password &&
                            formik.errors.password && (
                                <>
                                    {formik.errors.password} <br />
                                </>
                            )
                        }
                        data-testid="password"
                    />
                    <BoldButton sx={{ mt: 2, mb: 1 }} loading={loading} fullWidth type="submit">
                        Reauthenticate to delete
                    </BoldButton>
                    <Button
                        sx={{
                            color: alpha("#FFF", 0.8),
                        }}
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const theme = getTheme("light")

const styles: Record<string, SxProps<Theme>> = {
    submit: {
        [theme.breakpoints.down("sm")]: {
            mb: HEADER_HEIGHT,
        },
    },
    textField: {
        margin: (theme) => theme.spacing(1, 0),
    },
    link: {
        color: alpha("#FFF", 0.7),
        textDecorationColor: alpha("#FFF", 0.7),
        "&:hover": {
            color: alpha("#FFF", 0.9),
            textDecorationColor: alpha("#FFF", 0.9),
        },
    },
}
