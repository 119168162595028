import ExpandIcon from "@mui/icons-material/Expand"
import { Tooltip } from "@mui/material"
import { useDatabase, withObservables } from "@nozbe/watermelondb/react"
import { WIKIPEDIA, sentry } from "@recall/common"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { FC, useState } from "react"
import { toast } from "react-toastify"
import { itemAPI } from "storage/api/items/Item"
import { createAssets } from "storage/watermelon/helper/editorBlocks"
import { ItemModel, SourceModel } from "storage/watermelon/models"

export const EXPAND_DIV_ID = "id-expand-button"

interface Props {
    item: ItemModel
    name: string
    sources: SourceModel[]
}

const ExpandButton: FC<Props> = ({ item, name, sources }) => {
    const [loading, setLoading] = useState(false)
    const db = useDatabase()

    const wikipediaSource = sources.find((source: SourceModel) => source.name === WIKIPEDIA)

    if (!wikipediaSource) {
        return null
    }

    const handleOnClick = async () => {
        setLoading(true)

        try {
            const newItem = await itemAPI.getAndSaveWikipedia(
                db,
                wikipediaSource.identifier,
                item.language || "en"
            )
            if (!newItem)
                throw new Error(`Wikipedia source ${wikipediaSource.identifier} not found`)

            await item.merge(newItem)
            await createAssets(db, item)
            document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        } catch (e) {
            sentry.captureException(e)
            toast.error("Could not expand card.")
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Tooltip title={`Expand "${name}" with info from the web.`}>
                <div>
                    <LoadingButton
                        id={EXPAND_DIV_ID}
                        onClick={handleOnClick}
                        variant="contained"
                        color="secondary"
                        size="small"
                        loading={loading}
                        startIcon={<ExpandIcon />}
                        sx={{
                            boxShadow: "none",
                            mr: 0.5,
                            minWidth: "94px",
                        }}
                    >
                        Expand
                    </LoadingButton>
                </div>
            </Tooltip>
        </>
    )
}

const enhance = withObservables(["sources", "item"], ({ sources, item }) => ({
    sources: sources.observe(),
    item,
}))

const EnhancedExpandButton = enhance(ExpandButton)
export default EnhancedExpandButton
