import {
    ClientRectObject,
    getSelectionBoundingClientRect,
    useVirtualFloating,
} from "@udecode/plate-floating"
import { FloatingToolbarState } from "@udecode/plate-floating/dist"

import {
    getSelectionText,
    isSelectionExpanded,
    mergeProps,
    useEventEditorSelectors,
    usePlateEditorState,
} from "@udecode/plate-common"
import { useIsMobile } from "hooks/useIsMobile"
import { useState } from "react"
import { useFocused } from "slate-react"

export const getCustomSelectionBoundingClientRect = (domRange: Range): ClientRectObject => {
    return domRange.getBoundingClientRect()
}

export const useFloatingToolbarState = ({
    floatingOptions,
    hideToolbar,
    ignoreReadOnly,
}: FloatingToolbarState) => {
    const editor = usePlateEditorState()
    const focusedEditorId = useEventEditorSelectors.focus()
    const focused = useFocused()
    const isMobile = useIsMobile()

    const [menuState, setMenuState] = useState({
        isOpen: false,
        getBoundingClientRect: getSelectionBoundingClientRect,
        range: null,
    })
    const [open, setOpen] = useState(false)
    const [waitForCollapsedSelection, setWaitForCollapsedSelection] = useState(false)

    const selectionExpanded = editor && isSelectionExpanded(editor)
    const selectionText = editor && getSelectionText(editor)

    const floating = useVirtualFloating(
        mergeProps(
            {
                getBoundingClientRect: getSelectionBoundingClientRect,
                open,
                onOpenChange: setOpen,
            },
            floatingOptions
        )
    )

    const handleSetMenuOpen = (isOpen: boolean) => {
        if (!isMobile) return

        if (isOpen) {
            const selection = window.getSelection()
            const range = selection.getRangeAt(0).cloneRange()

            setMenuState({
                getBoundingClientRect: () => getCustomSelectionBoundingClientRect(range),
                isOpen,
                range,
            })
        } else {
            setMenuState((state) => ({
                ...state,
                getBoundingClientRect: getSelectionBoundingClientRect,
                isOpen,
            }))
        }
    }

    return {
        isMenuOpen: menuState.isOpen,
        setIsMenuOpen: handleSetMenuOpen,
        editor,
        open,
        setOpen,
        waitForCollapsedSelection,
        setWaitForCollapsedSelection,
        selectionExpanded,
        selectionText,
        focused,
        focusedEditorId,
        ignoreReadOnly,
        hideToolbar,
        floating,
    }
}
