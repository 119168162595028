import { FormatColorFill, FormatColorText } from "@mui/icons-material"
import { IconButton, Menu, Tooltip } from "@mui/material"
import { MARK_COLOR, useColorDropdownMenuState } from "@udecode/plate-font"
import { FC, useState } from "react"
import { DEFAULT_COLORS, DEFAULT_CUSTOM_COLORS } from "../../constants/colors"
import { ColorPicker } from "./ColorPicker"

interface Props {
    nodeType: string
    tooltip: string
}

export const ColorDropDownMenu: FC<Props> = ({ nodeType, tooltip }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const state = useColorDropdownMenuState({
        nodeType,
        colors: DEFAULT_COLORS,
        customColors: DEFAULT_CUSTOM_COLORS,
        closeOnSelect: true,
    })

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}
                    size={"small"}
                    sx={{
                        color: (theme) =>
                            Boolean(anchorEl)
                                ? theme.palette.text.primary
                                : theme.palette.action.active,
                        backgroundColor: (theme) =>
                            Boolean(anchorEl) && theme.palette.action.selected,
                        ":hover": {
                            backgroundColor: (theme) =>
                                Boolean(anchorEl) && theme.palette.action.selected,
                        },
                    }}
                >
                    {nodeType === MARK_COLOR ? (
                        <FormatColorText sx={{ fontSize: "1.4rem" }} />
                    ) : (
                        <FormatColorFill sx={{ fontSize: "1.4rem" }} />
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <ColorPicker
                    color={state.selectedColor || state.color}
                    colors={state.colors}
                    customColors={state.customColors}
                    updateColor={state.updateColorAndClose}
                    updateCustomColor={state.updateColor}
                    clearColor={state.clearColor}
                />
            </Menu>
        </>
    )
}
