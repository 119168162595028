import { useDatabase } from "@nozbe/watermelondb/react"
import { sentry } from "@recall/common"
import { useTabFocus } from "hooks/useTabFocus"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { extensionItemsRepository } from "repositories/extensionItemsRepository"
import { extensionItemService } from "services/extensionItemService"
import { SET_IS_CREATING_EXTENSION_ITEMS } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"

export const useCreateExtensionItems = () => {
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const isInitialSyncFinished = useSelector((state: RootState) => state.app.isInitialSyncFinished)
    const userAuthenticating = useSelector((state: RootState) => state.app.userLoading)
    const dispatch = useDispatch()

    const canCreateItems = uid && isInitialSyncFinished && !userAuthenticating

    const createExtensionItems = useCallback(async () => {
        try {
            const extensionItems = await extensionItemsRepository.getExtensionItems(uid)
            if (extensionItems?.length)
                await extensionItemService.createExtensionItems(extensionItems, uid, db)
        } catch (e) {
            sentry.captureException(e)
        } finally {
            dispatch({ type: SET_IS_CREATING_EXTENSION_ITEMS, payload: false })
        }
    }, [uid, db])

    const handleFocus = useCallback(() => {
        if (canCreateItems) createExtensionItems()
    }, [canCreateItems, createExtensionItems])

    useTabFocus(handleFocus)

    useEffect(() => {
        if (canCreateItems) createExtensionItems()
    }, [canCreateItems, createExtensionItems])
}
