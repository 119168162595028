import { Box, SxProps, Theme, Tooltip } from "@mui/material"
import { useDatabase, withObservables } from "@nozbe/watermelondb/react"
import { NestedTagChip } from "@recall/common"
import { SearchAutocomplete } from "components/shared/inputs/SearchAutocomplete"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { useTagsWithPaths } from "components/shared/tags/hooks/useTagsWithPaths"
import { useIsMobile } from "hooks/useIsMobile"
import { useTagAncestors } from "hooks/useTagAncestors"
import { difference, map } from "lodash"
import { FC, memo, useEffect, useMemo, useState } from "react"
import { ItemModel, TagModel } from "storage/watermelon/models"
import { ROOT_TAG_ID, tagRepository } from "storage/watermelon/repository"
import { TagInputChip } from "./TagInputChip"

type TagInputProps = {
    item: ItemModel
    tags: TagModel[]
    readOnly?: boolean
}

interface TagsWithAncestors {
    tag: TagModel
    ancestors: TagModel[]
}

const TagInputComponent: FC<TagInputProps> = ({ item, tags: existingTags, readOnly = false }) => {
    const isMobile = useIsMobile()
    const db = useDatabase()
    const { allTags, getAllTags } = useTagsWithPaths()
    const [isInputVisible, setIsInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [itemTags, setItemTags] = useState<TagsWithAncestors[]>([])
    const { getTagAncestors, getTagNesting } = useTagAncestors()

    const getTagsByNames = (tagNames: string[]) => {
        return allTags.filter(
            ({ tag }) =>
                tagNames.some(
                    (tagName) => tagName.toLowerCase() === tag.name.toLowerCase().trim()
                ) && tag.id !== ROOT_TAG_ID
        )
    }

    const handleSelectTag = (selectedTags: string[]) => {
        const existingTagNames = map(existingTags, "name")
        const tagsToCreate = difference(selectedTags, existingTagNames)
        const tagNamesToRemove = difference(existingTagNames, selectedTags)

        const tagsToRemove = getTagsByNames(tagNamesToRemove)

        modifyTags(map(tagsToRemove, "tag"), tagsToCreate)
    }

    const modifyTags = async (tagsToRemove: TagModel[], tagsToCreate: string[]) => {
        for (const tag of tagsToRemove) {
            await handleDetach(tag)
        }
        for (const tag of tagsToCreate) {
            await handleCreate(tag)
        }
    }

    const handleCreate = async (tagName: string) => {
        const tagsToCreate = tagName.split("/")

        let parentId = ROOT_TAG_ID
        for (let i = 0; i < tagsToCreate.length; i++) {
            if (!tagsToCreate[i]) continue

            if (i === tagsToCreate.length - 1) {
                await tagRepository.create({ db, name: tagsToCreate[i], item, parentId })
            } else {
                const newTag = await tagRepository.create({
                    db,
                    name: tagsToCreate[i],
                    parentId,
                    isSaved: item.isSaved,
                })
                parentId = newTag.id
            }
        }
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    const handleDetach = async (tag: TagModel) => {
        await tagRepository.detach(db, tag.id, item.id)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    useEffect(() => {
        getItemTags()
        // eslint-disable-next-line
    }, [existingTags])

    useEffect(() => {
        const subscription = tagRepository.observeCount(db).subscribe(getAllTags)

        const refetchTags = () => {
            getAllTags()
            getItemTags()
        }

        document.addEventListener(RERENDER_TAGS_EVENT, refetchTags)

        return () => {
            document.removeEventListener(RERENDER_TAGS_EVENT, refetchTags)
            subscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [])

    const getItemTags = async () => {
        const itemTags = await item.getTags()
        const tagsWithAncestors: TagsWithAncestors[] = []
        for (const tag of itemTags) {
            const ancestors = await getTagAncestors(tag)
            tagsWithAncestors.push({ tag, ancestors })
        }
        setItemTags(tagsWithAncestors)
    }

    const options = useMemo(() => {
        const tags = allTags
            .filter(({ tag }) => tag.id !== ROOT_TAG_ID)
            .map(({ tag, path }) => ({
                value: tag.name,
                label: path,
                id: tag.id,
            }))

        return tags
    }, [allTags, inputValue])

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap">
            {itemTags.map(({ tag, ancestors }, index) => {
                return (
                    <Tooltip
                        key={tag.id + index}
                        title={isMobile && tag.name.length > 15 ? tag.name : undefined}
                    >
                        <Box sx={{ mr: 0.5, mb: 0.5 }}>
                            <NestedTagChip
                                isMobile={isMobile}
                                size="medium"
                                tagNesting={getTagNesting(tag, ancestors)}
                                onDelete={readOnly ? undefined : () => handleDetach(tag)}
                                data-testid="card-input-tag"
                            />
                        </Box>
                    </Tooltip>
                )
            })}
            {!readOnly && (
                <>
                    {!isInputVisible ? (
                        <TagInputChip setIsHidden={setIsInputVisible} />
                    ) : (
                        <Box flexGrow={1} minWidth={200} mb={0.5}>
                            <SearchAutocomplete
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                options={options}
                                optionName="tag"
                                value={itemTags.map(({ tag }) => ({
                                    label: tag.name,
                                    value: tag.name,
                                }))}
                                handleChange={handleSelectTag}
                                sx={styles.autocomplete}
                                textFieldProps={{
                                    placeholder: "Add tag",
                                    variant: "standard",
                                    sx: !itemTags.length ? styles.emptyInput : {},
                                    InputProps: { disableUnderline: true },
                                    onBlur: () => setIsInputVisible(false),
                                }}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    autocomplete: {
        px: 1,
        maxWidth: 350,
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.action.hover,
        transition: (theme: Theme) =>
            theme.transitions.create(["background-color", "transform"], {
                duration: theme.transitions.duration.shorter,
            }),
        "&:focus-within": {
            backgroundColor: (theme) => theme.palette.action.selected,
        },
        "& .MuiAutocomplete-tag": {
            ml: 0,
            mr: 0.5,
        },
    },
    emptyInput: {
        ml: 1,
    },
}

const enhance = withObservables(["item"], ({ item }: { item: ItemModel }) => ({
    tags: item.tags,
    item,
}))

export const TagInput = memo(enhance(TagInputComponent))
