import { Model, Relation } from "@nozbe/watermelondb"
import { date, field, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { ITEMS, ITEM_TAG, TAGS } from "../schema"
import { ItemModel } from "./ItemModel"
import { TagModel } from "./TagModel"

export class ItemTagModel extends Model {
    static table = ITEM_TAG

    @field("is_saved") isSaved: boolean
    @readonly @date("created_at") createdAt: Date

    @relation(ITEMS, "item_id") item: Relation<ItemModel>
    @relation(TAGS, "tag_id") tag: Relation<TagModel>

    prepareSave = () => {
        if (this.isSaved) return null

        return this.prepareUpdate((record) => {
            record.isSaved = true
        })
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }

    prepareDelete = () => {
        // @ts-ignore
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }
}
