import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import { Box } from "@mui/material"
import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import { useOpenInNewTab } from "hooks/useOpenInNewTab"
import { FC } from "react"
import { useSelected } from "slate-react"
import { MyYouTubeTimestampElement } from "../types"

export const YouTubeTimestampElement: FC<PlateElementProps<MyYouTubeTimestampElement[]>> = ({
    className,
    children,
    ...props
}) => {
    const selected = useSelected()
    const { openInNewTab } = useOpenInNewTab()

    const value = props.element?.children?.[0]?.text

    const handleClick = () => {
        const url = props.element.url
        openInNewTab(url)
    }

    if (!value) return null

    return (
        <PlateElement asChild {...props}>
            <Box
                component={"span"}
                onClick={handleClick}
                contentEditable={false}
                sx={{
                    display: "inline-flex",
                    color: (theme) => theme.palette.action.disabled,
                    borderRadius: 100,
                    padding: "2px",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    textDecoration: "none",
                    userSelect: "none",
                    border: (theme) =>
                        `1px solid ${selected ? theme.palette.action.focus : "transparent"}`,
                    "&:hover": {
                        color: (theme) => theme.palette.action.active,
                        textDecoration: "underline",
                        backgroundColor: (theme) => theme.palette.action.disabledBackground,
                    },
                }}
            >
                {children}
                <ArrowOutwardIcon sx={{ fontSize: "0.9rem" }} />
            </Box>
        </PlateElement>
    )
}
