import { Database } from "@nozbe/watermelondb"
import { DataMigrationModel } from "../models/DataMigrationModel"
import { DATA_MIGRATIONS } from "../schema"
import migrations from "./allDataMigrations"

export const getMigrations = async (database: Database) => {
    return await database.collections.get<DataMigrationModel>(DATA_MIGRATIONS).query().fetch()
}

const MIGRATION_TIMESTAMP = "migration-timestamp"

const isReadyToMigrate = () => {
    const lastMigrationTimestamp = localStorage.getItem(MIGRATION_TIMESTAMP)

    if (!lastMigrationTimestamp) return true

    const now = Date.now()
    const migrationAge = now - Number(lastMigrationTimestamp)
    const threshold = 5 * 60 * 1000
    return migrationAge > threshold
}

export const migrate = async (database: Database) => {
    // Migrate maximum once in 5 minutes
    if (!isReadyToMigrate()) return

    const executedMigrations = await getMigrations(database)

    for (let i = 0; i < migrations.length; i++) {
        const currentMigration = migrations[i]
        const found = executedMigrations.find((existing) => existing.name === currentMigration.name)

        if (found) continue

        await currentMigration.run(database)
        await database.write(async (writer) => {
            await database.collections.get<DataMigrationModel>(DATA_MIGRATIONS).create((d) => {
                d.name = currentMigration.name
                d.isSaved = true
            })
        })
        localStorage.setItem(MIGRATION_TIMESTAMP, Date.now().toString())
    }
}
