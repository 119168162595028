import { Model, Q, Query, Relation } from "@nozbe/watermelondb"
import { children, field, lazy, relation, writer } from "@nozbe/watermelondb/decorators"
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model"
import { ROOT_TYPE_NAME, sentry, Type } from "@recall/common"
import { typeRepository } from "../repository"
import { ItemModel } from "./ItemModel"

export class TypeModel extends Model {
    static table = "types"

    static associations = {
        parent: { type: "belongs_to", key: "parent_id" } as BelongsToAssociation,
        items: { type: "has_many", foreignKey: "type_id" } as HasManyAssociation,
    }

    @field("name") name: string
    @field("display") display: string
    @field("plural_display") pluralDisplay: string
    @field("wikidata_id") wikidataId: string
    @field("user_created") userCreated: boolean
    @field("is_saved") isSaved: boolean
    @children("items") items: Query<ItemModel>
    @relation("types", "parent_id") parent: Relation<TypeModel>
    @lazy children = this.collections
        .get("types")
        .query(Q.where("parent_id", Q.eq(this.id))) as Query<TypeModel>
    @lazy savedChildren = this.collections
        .get("types")
        .query(Q.where("parent_id", Q.eq(this.id)), Q.where("is_saved", true)) as Query<TypeModel>

    prepareSave = async () => {
        if (this.isSaved === false) {
            const unsavedGenes = (await this.getParents()).filter(
                (gene: TypeModel) => gene.isSaved === false
            )

            return unsavedGenes.map((gene: TypeModel) =>
                gene.prepareUpdate((record: TypeModel) => {
                    record.isSaved = true
                })
            )
        }

        return []
    }

    toType = (): Type => {
        return {
            id: this.id,
            name: this.name,
            display: this.display,
            userCreated: this.userCreated,
            pluralDisplay: this.pluralDisplay,
            parentId: this._raw["parent_id"],
            wikidataId: this.wikidataId,
        }
    }

    getParents = async () => {
        let genealogy: TypeModel[] = [this]
        let parent = await this.parent.fetch()

        while (true) {
            if (parent) {
                genealogy.push(parent)
                parent = await parent.parent.fetch()
                continue
            }
            break
        }
        return genealogy
    }

    isStale = async () => {
        if (this.name === ROOT_TYPE_NAME) {
            return false
        }

        if (this.userCreated === true) {
            return false
        }

        const count = await this.items.fetchCount()

        if (count > 0) {
            return false
        }

        const children = await typeRepository.getChildTypesRecursive(this.database, this.id, false)

        for (const child of children) {
            const count = await child.items.fetchCount()

            if (count > 0) {
                return false
            }
        }

        return true
    }

    @writer async updateType(
        display: string = null,
        pluralDisplay: string = null,
        parentId: string = null
    ) {
        await this.update((record) => {
            if (display) {
                record.display = display
            }
            if (pluralDisplay) {
                record.pluralDisplay = pluralDisplay
            }
            if (parentId) {
                record.parent.id = parentId
            }
        })
    }

    // TODO - keep an eye on this. Hope is doesn't cause issues.
    @writer async unDelete() {
        await this.update((record) => {
            record._raw._status = "created"
        })
    }

    @writer async delete() {
        const tasks = await this.prepareDelete()
        await this.batch(...tasks)
    }

    prepareDelete = async () => {
        let tasks = []
        let parent: TypeModel

        try {
            parent = await this.parent.fetch()
        } catch (e) {
            sentry.captureException(e)
            parent = await typeRepository.getRoot(this.database)
        }

        const items = await this.items.fetch()
        const childTypes = await this.children.fetch()

        for (const childType of childTypes) {
            const task = childType.prepareUpdate((record: TypeModel) => {
                record.parent.id = parent.id
            })
            tasks.push(task)
        }

        for (const item of items) {
            const task = item.prepareUpdate((record: ItemModel) => {
                record.type.id = parent.id
            })
            tasks.push(task)
        }

        if (this.isSaved) {
            tasks.push(this.prepareMarkAsDeleted())
        } else {
            tasks.push(this.prepareDestroyPermanently())
        }

        return tasks
    }
}
