import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { assets } from "@recall/common"
import { BoldButton } from "components/shared/buttons/BoldButton"
import { FC } from "react"
import { Video } from "./Video"

interface Props {
    handleContinue: () => void
}

export const PinTheExtensionStep: FC<Props> = ({ handleContinue }) => {
    return (
        <>
            <Typography variant="h4" fontWeight={500} textAlign="center">
                Pin The Browser Extension
            </Typography>
            <Box display="flex" gap={2} flexDirection="column">
                <Video videoUrl={assets.PIN_THE_EXTENSION.url} />
            </Box>
            <BoldButton fullWidth onClick={handleContinue} endIcon={<NavigateNextIcon />}>
                I've pinned the extension
            </BoldButton>
        </>
    )
}
