import { Breadcrumbs as MuiBreadcrumbs, SxProps, Theme, Typography } from "@mui/material"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useIsMobile } from "hooks/useIsMobile"
import { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { ItemModel } from "storage/watermelon/models"
import { Breadcrumb } from "./Breadcrumb"
import { BreadcrumbMenu } from "./BreadcrumbMenu"

interface Props {
    item: ItemModel
}

export const Breadcrumbs: FC<Props> = ({ item }) => {
    const breadcrumbs = useSelector((state: RootState) => state.app.breadcrumbs)
    const { addBreadcrumbWithoutRedirect } = useBreadcrumbActions()
    const { getItemPath } = useOpenItem()
    const visibleBreadcrumbs = breadcrumbs.slice(-3)
    const isMobile = useIsMobile()

    const normalizeLabel = (label: string) => {
        const labelMaxLength = isMobile ? 15 : 25

        return label.length > labelMaxLength + 3
            ? label.slice(0, labelMaxLength).trim() + "..."
            : label
    }

    useEffect(() => {
        if (breadcrumbs.some(({ label }) => label === item.name)) return
        addBreadcrumbWithoutRedirect({ label: item.name, path: getItemPath(item.id) })
    }, [item])

    return (
        <MuiBreadcrumbs
            separator={
                <Typography sx={styles.separator} variant="body2">
                    /
                </Typography>
            }
            sx={styles.breadcrumbs}
            aria-label="breadcrumb"
        >
            {breadcrumbs.length > visibleBreadcrumbs.length && (
                <BreadcrumbMenu
                    breadcrumbs={breadcrumbs
                        .slice(0, breadcrumbs.length - visibleBreadcrumbs.length)
                        .map(({ label, path }) => ({ label: normalizeLabel(label), path }))}
                />
            )}
            {visibleBreadcrumbs.map((breadcrumb, index) => (
                <Breadcrumb
                    key={breadcrumb.path}
                    path={breadcrumb.path}
                    label={normalizeLabel(breadcrumb.label)}
                    active={index === visibleBreadcrumbs.length - 1}
                />
            ))}
        </MuiBreadcrumbs>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    separator: {
        opacity: 0.8,
    },
    breadcrumbs: { minHeight: { xs: 40, md: "auto" }, display: "flex", alignItems: "center" },
}
