import { Database, Q } from "@nozbe/watermelondb"
import { SourceModel } from "../models"
import { SOURCES } from "../schema"

const get = async (db: Database, sourceIdentifier: string, sourceName: string = null) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("identifier", sourceIdentifier), Q.where("is_saved", true))

    if (sourceName) query.extend(Q.where("name", sourceName))

    const results = await query.fetch()

    return results?.[0] || null
}

const getByIds = async (db: Database, identifiers: string[]) => {
    const query = db.collections
        .get<SourceModel>(SOURCES)
        .query(Q.where("identifier", Q.oneOf(identifiers)), Q.where("is_saved", true))

    const results = await query.fetch()

    return results
}

const deleteStale = async (db: Database) => {
    await db.write(async (writer) => {
        const tasks = []
        const sources = await db.collections.get<SourceModel>(SOURCES).query().fetch()

        for (const source of sources) {
            try {
                await source.item.fetch()
            } catch (e) {
                const anotherTask = source.prepareDelete()
                tasks.push(anotherTask)
            }
        }

        await writer.batch(...tasks)
    })
}

export const sourceRepository = { get, getByIds, deleteStale }
