import { Database } from "@nozbe/watermelondb"
import { sentry } from "@recall/common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { RERENDER_EDITOR_BLOCKS_EVENT } from "components/ItemPage/components/editor/PlateEditor"
import {
    ELEMENT_CUSTOM_IMAGE,
    duplicateImage,
} from "components/ItemPage/components/editor/plugins/image"
import { FIREBASE_STORAGE_BASE } from "constants/storage"
import { ASSET_TYPES, EditorBlockModel, ItemModel } from "../models"
import { assetRepository } from "../repository/assetRepository"

const isFirebaseStorageImage = (block: any) => {
    if (block.type !== ELEMENT_IMAGE) return false
    const imageUrl = block.url || block.options?.url
    return imageUrl.startsWith(FIREBASE_STORAGE_BASE)
}

const replaceImage = async (db: Database, block: any, item: ItemModel) => {
    const imageUrl = block.url || block.options?.url

    const { url, size } = await duplicateImage(imageUrl)

    if (url === null) return null

    await assetRepository.createAsset(db, {
        item,
        size,
        url,
        type: ASSET_TYPES.IMAGE,
    })

    return url
}

const replaceEditorBlockImageUrls = async (db: Database, editorBlocks, item: ItemModel) => {
    const blocks = []
    for (let block of editorBlocks) {
        if (block.children) {
            block.children = await replaceEditorBlockImageUrls(db, block.children, item)
        }
        if (!isFirebaseStorageImage(block)) {
            if (block.type === ELEMENT_IMAGE || block.type === ELEMENT_CUSTOM_IMAGE) {
                const existingAssets = await assetRepository.getByUrlAndItemId(
                    db,
                    item.image,
                    item.id
                )

                if (!existingAssets?.length) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        // @ts-ignore
                        url:
                            block?.url ||
                            block?.options?.url ||
                            block?.url_320 ||
                            block?.urlOriginal,
                        type: ASSET_TYPES.IMAGE_URL,
                    })
                }
            }
            blocks.push(block)
            continue
        }
        const url = await replaceImage(db, block, item)

        if (!url) {
            continue
        }

        block.url = url
        block.options = block.options || {}
        block.options.url = url

        blocks.push(block)
    }

    return blocks.filter(Boolean)
}

export const createAssets = async (db: Database, item: ItemModel) => {
    const links = await item.getLinkedItems()
    for (const link of [item, ...links]) {
        const editorBlocks = await link.editorBlocks.fetch()
        await replaceImageUrls(db, editorBlocks, link)
    }
}

export const replaceImageUrls = async (
    db: Database,
    editorBlocks: EditorBlockModel[],
    item: ItemModel
) => {
    try {
        for (const editorBlock of editorBlocks) {
            if (isFirebaseStorageImage(editorBlock)) {
                const url = await replaceImage(db, editorBlock, item)
                if (!url) editorBlock.delete()
                await editorBlock.updateUrl(url)
            } else if (
                editorBlock.type === ELEMENT_IMAGE ||
                editorBlock.type === ELEMENT_CUSTOM_IMAGE
            ) {
                const existingAssets = await assetRepository.getByUrlAndItemId(
                    db,
                    item.image,
                    item.id
                )

                if (!existingAssets?.length) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        url: item.image,
                        type: ASSET_TYPES.IMAGE_URL,
                    })
                }

                // @ts-ignore
                if (editorBlock?.children?.length) {
                    const children = await replaceEditorBlockImageUrls(
                        db,
                        editorBlock.children,
                        item
                    )
                    await editorBlock.updateChildren(children)
                }
            }
            // @ts-ignore
            else if (editorBlock?.children?.length) {
                const children = await replaceEditorBlockImageUrls(db, editorBlock.children, item)
                await editorBlock.updateChildren(children)
            }
        }
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
    } catch (error) {
        sentry.captureException(error)
    }
}

export const removeEditorBlockWithUrl = (editorBlocks, targetUrl: string) => {
    return editorBlocks
        .map((block) => {
            if (block.children) {
                block.children = removeEditorBlockWithUrl(block.children, targetUrl)
            }
            return block
        })
        .filter((block) => {
            const hasUrl =
                block.type === ELEMENT_IMAGE &&
                (block.url === targetUrl || block.options?.url === targetUrl)
            return !hasUrl
        })
}
