import { Box, Skeleton } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    marginBottom: 4,
    marginLeft: 8,
    borderRadius: 4,
}))

const LoadingSearchResult: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Box
            sx={{
                display: "flex",
                p: 1,
            }}
        >
            <Skeleton variant="circular" width={60} height={60} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <StyledSkeleton height={30} width="60%" />
                <StyledSkeleton height={10} width="80%" />
                <StyledSkeleton height={10} width="85%" />
            </Box>
        </Box>
    )
}

export default LoadingSearchResult
