import { ChevronRight } from "@mui/icons-material"
import { Box, Collapse, SxProps, Theme, Typography } from "@mui/material"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsMobile } from "hooks/useIsMobile"
import { memo, useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import { TagsTreeCard } from "./TagsTreeCard"
import { UntaggedItemsLoader } from "./UntaggedItemsLoader"
import { useUntaggedItems } from "./hooks/useUntaggedItems"

interface Props {
    isSearched: boolean
    searchText: string
    handleClickItem: (id: string) => void
}

const VISIBLE_ITEMS_STEP = 25

export const TagsTreeUntaggedItems = memo(({ isSearched, searchText, handleClickItem }: Props) => {
    const [isExpanded, setIsExpanded] = useState(isSearched)
    const isMobile = useIsMobile()
    const location = useLocation()
    const [visibleItems, setVisibleItems] = useState(VISIBLE_ITEMS_STEP)
    const { id } = useParams<{ id: string }>()
    const { getItemPath } = useOpenItem()
    const { untaggedItems } = useUntaggedItems({ searchText })

    const handleObserve = useCallback(() => {
        setVisibleItems((prev) => prev + VISIBLE_ITEMS_STEP)
    }, [])

    useEffect(() => {
        if (isSearched) setIsExpanded(true)
    }, [isSearched])

    if (!untaggedItems.length) return null

    return (
        <Box mt={0.5} mb={2}>
            <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => setIsExpanded((prev) => !prev)}
            >
                {isExpanded ? (
                    <ChevronRight
                        sx={{
                            ...styles.collapseIcon,
                            fontSize: isMobile ? "24px !important" : "18px",
                            ml: isMobile ? "-4px" : "0",
                        }}
                    />
                ) : (
                    <ChevronRight
                        sx={{
                            ...styles.expandIcon,
                            fontSize: isMobile ? "24px !important" : "18px",
                            ml: isMobile ? "-4px" : "0",
                        }}
                    />
                )}
                <Typography
                    variant="caption"
                    ml={1}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    sx={{ fontStyle: "italic" }}
                >
                    Untagged cards
                </Typography>
            </Box>
            <Collapse in={isExpanded}>
                <Box ml={2.4} mt={0.2}>
                    {untaggedItems.slice(0, visibleItems).map((searchedItem) => (
                        <TagsTreeCard
                            key={searchedItem.item.id}
                            selected={
                                id === searchedItem.item.id &&
                                location.pathname.includes(getItemPath(id))
                            }
                            searchedItem={searchedItem}
                            searchText={searchText}
                            handleClick={handleClickItem}
                        />
                    ))}
                </Box>
                {untaggedItems.length && untaggedItems.length > visibleItems && (
                    <UntaggedItemsLoader handleLoadMore={handleObserve} />
                )}
            </Collapse>
        </Box>
    )
})

const styles: Record<string, SxProps<Theme>> = {
    expandIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
    collapseIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transform: "rotate(90deg)",
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
}
