import { PlateLeaf, PlateLeafProps } from "@udecode/plate-common"
import { FC } from "react"

export const CodeLeaf: FC<PlateLeafProps> = ({ className, children, ...props }) => {
    return (
        <PlateLeaf asChild {...props}>
            <code>{children}</code>
        </PlateLeaf>
    )
}
