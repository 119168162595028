import { Database } from "@nozbe/watermelondb"
// @ts-ignore
import { hasUnsyncedChanges } from "@nozbe/watermelondb/sync"

const checkUnsyncedChanges = async (db: Database) => {
    return await hasUnsyncedChanges({ database: db })
}

const deleteDatabase = async (db: Database) => {
    await db.write(async () => {
        await db.unsafeResetDatabase()
    })
}

const escapeId = (id: string) => {
    const regex = /[.,$#[\]/"]/g
    return id.replace(regex, "-")
}

const sanitizeSearchTerm = (term) => {
    const specialChars = [
        "\\",
        "^",
        "$",
        "*",
        "+",
        "?",
        ".",
        "(",
        ")",
        "|",
        "{",
        "}",
        "[",
        "]",
        '"',
        "'",
    ]

    return term
        .split("")
        .map((char) => (specialChars.includes(char) ? `\\${char}` : char))
        .join("")
}

export const dbUtils = { escapeId, checkUnsyncedChanges, deleteDatabase, sanitizeSearchTerm }
