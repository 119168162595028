import { Model } from "@nozbe/watermelondb"
import { date, field, readonly, text, writer } from "@nozbe/watermelondb/decorators"
import { DATA_MIGRATIONS } from "../schema"

export class DataMigrationModel extends Model {
    static table = DATA_MIGRATIONS

    @text("name") name!: string
    @field("is_saved") isSaved: boolean

    @readonly @date("created_at") createdAt!: Date
    @readonly @date("updated_at") updatedAt!: Date

    @writer async delete() {
        const task = this.prepareMarkAsDeleted()
        await this.batch(task)
    }
}
