var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { v5 as uuidv5 } from "uuid";
import { COLLECTIONS } from "../constants";
import { firebase } from "../services";
import { RECALL_APP_URL } from "../settings";
const SHARING_BASE_URL = RECALL_APP_URL + "/share";
const getSharingUrl = (itemId) => {
    const sharingId = getSharedIdByItemId(itemId);
    return `${SHARING_BASE_URL}/${sharingId}`;
};
const getSharedIdByItemId = (itemId) => {
    const hash = uuidv5("shared-card", itemId);
    return hash;
};
const shareCard = (uid, itemId) => __awaiter(void 0, void 0, void 0, function* () {
    yield setDoc(doc(firebase.firestore, COLLECTIONS.SHARED_CARDS, getSharedIdByItemId(itemId)), {
        uid,
        itemId,
    });
});
const unshareCard = (itemId) => __awaiter(void 0, void 0, void 0, function* () {
    yield deleteDoc(doc(firebase.firestore, COLLECTIONS.SHARED_CARDS, getSharedIdByItemId(itemId)));
});
const getSharedItem = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const snapshot = yield getDoc(doc(firebase.firestore, COLLECTIONS.SHARED_CARDS, id));
    if (!snapshot.exists())
        return null;
    return snapshot.data();
});
export const sharingRepository = {
    getSharedItem,
    shareCard,
    unshareCard,
    getSharedIdByItemId,
    getSharingUrl,
};
