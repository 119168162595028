import React from "react"

import {
    createPlateEditor,
    CreatePlateEditorOptions,
    createPluginFactory,
    createPlugins,
    createTEditor,
    Decorate,
    DecorateEntry,
    DOMHandler,
    EDescendant,
    EElement,
    EElementEntry,
    EElementOrText,
    EMarks,
    ENode,
    ENodeEntry,
    EText,
    ETextEntry,
    getTEditor,
    InjectComponent,
    InjectProps,
    KeyboardHandler,
    NoInfer,
    OnChange,
    OverrideByKey,
    PlateEditor,
    PlateId,
    PlatePlugin,
    PlatePluginComponent,
    PlatePluginInsertData,
    PlatePluginProps,
    PlateProps,
    PluginOptions,
    SerializeHtml,
    TElement,
    TReactEditor,
    useEditorRef,
    useEditorState,
    usePlateActions,
    usePlateEditorRef,
    usePlateEditorState,
    usePlateSelectors,
    usePlateStates,
    WithOverride,
} from "@udecode/plate-common"

import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"

import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from "@udecode/plate-code-block"

import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import { AutoformatRule } from "@udecode/plate-autoformat"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list"
import { ELEMENT_IMAGE, TImageElement } from "@udecode/plate-media"
import { TText } from "@udecode/slate"
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "./plugins/editor-block"
import { ELEMENT_CUSTOM_IMAGE } from "./plugins/image"
import { ELEMENT_CUSTOM_PARAGRAPH } from "./plugins/paragraph"
import { ELEMENT_REFERENCE } from "./plugins/reference"
import { YOUTUBE_TIMESTAMP } from "./plugins/youtube-timestamp"

/**
 * Text
 */

export type EmptyText = {
    text: ""
}

export type PlainText = {
    text: string
}

export interface RichText extends TText {
    bold?: boolean
    italic?: boolean
    underline?: boolean
    strikethrough?: boolean
    code?: boolean
    kbd?: boolean
    subscript?: boolean
    backgroundColor?: React.CSSProperties["backgroundColor"]
    fontFamily?: React.CSSProperties["fontFamily"]
    color?: React.CSSProperties["color"]
    fontSize?: React.CSSProperties["fontSize"]
    fontWeight?: React.CSSProperties["fontWeight"]
}

export type MyInlineChildren = RichText[]

/**
 * Block props
 */

export interface MyIndentProps {
    indent?: number
}

export interface MyIndentListProps extends MyIndentProps {
    listStart?: number
    listRestart?: number
    listStyleType?: string
}

export interface MyLineHeightProps {
    lineHeight?: React.CSSProperties["lineHeight"]
}

export interface MyAlignProps {
    align?: React.CSSProperties["textAlign"]
}

export interface MyBlockElement extends TElement, MyIndentListProps {
    id?: string
    options?: any
}

/**
 * Blocks
 */

export interface MyParagraphElement extends MyBlockElement {
    type: typeof ELEMENT_PARAGRAPH | typeof ELEMENT_CUSTOM_PARAGRAPH
    children: MyInlineChildren
}

export interface MyH1Element extends MyBlockElement {
    type: typeof ELEMENT_H1
    children: MyInlineChildren
}

export interface MyH2Element extends MyBlockElement {
    type: typeof ELEMENT_H2
    children: MyInlineChildren
}

export interface MyH3Element extends MyBlockElement {
    type: typeof ELEMENT_H3
    children: MyInlineChildren
}

export interface MyH4Element extends MyBlockElement {
    type: typeof ELEMENT_H4
    children: MyInlineChildren
}

export interface MyH5Element extends MyBlockElement {
    type: typeof ELEMENT_H5
    children: MyInlineChildren
}

export interface MyH6Element extends MyBlockElement {
    type: typeof ELEMENT_H6
    children: MyInlineChildren
}

export interface MyBlockquoteElement extends MyBlockElement {
    type: typeof ELEMENT_BLOCKQUOTE
    children: MyInlineChildren
}

export interface MyCodeBlockElement extends MyBlockElement {
    type: typeof ELEMENT_CODE_BLOCK
    children: MyCodeLineElement[]
}

export interface MyCodeLineElement extends TElement, MyBlockElement {
    type: typeof ELEMENT_CODE_LINE
    children: PlainText[]
}

export interface MyHrElement extends MyBlockElement {
    type: typeof ELEMENT_HR
    children: [EmptyText]
}

export interface MyImageElement extends TImageElement, MyBlockElement {
    type: typeof ELEMENT_IMAGE | typeof ELEMENT_CUSTOM_IMAGE
    children: [EmptyText]
}

export interface MyBulletedListElement extends TElement, MyBlockElement {
    type: typeof ELEMENT_UL
    children: MyListItemElement[]
}

export interface MyNumberedListElement extends TElement, MyBlockElement {
    type: typeof ELEMENT_OL
    children: MyListItemElement[]
}

export interface MyListItemElement extends TElement, MyBlockElement {
    type: typeof ELEMENT_LI
    children: MyInlineChildren
}

export interface MyReferenceElement extends MyBlockElement {
    type: typeof ELEMENT_REFERENCE
    children: MyInlineChildren
    connectionId: string
}

export interface MyEditorBlockElement extends MyBlockElement {
    type: typeof ELEMENT_CUSTOM_EDITOR_BLOCK
    children: MyInlineChildren | Exclude<MyRootBlock, MyEditorBlockElement>[]
}

export interface MyYouTubeTimestampElement extends MyBlockElement {
    type: typeof YOUTUBE_TIMESTAMP
    children: MyInlineChildren
    url: string
}

export type MyNestableBlock = MyParagraphElement

export type MyRootBlock =
    | MyParagraphElement
    | MyH1Element
    | MyH2Element
    | MyH3Element
    | MyH4Element
    | MyH5Element
    | MyH6Element
    | MyBlockquoteElement
    | MyCodeBlockElement
    | MyCodeLineElement
    | MyHrElement
    | MyImageElement
    | MyBulletedListElement
    | MyNumberedListElement
    | MyListItemElement
    | MyReferenceElement
    | MyEditorBlockElement
    | MyYouTubeTimestampElement

export type EditorElements = MyRootBlock[]

/**
 * Editor types
 */

export type MyEditor = PlateEditor<EditorElements> & { isDragging?: boolean }
export type MyReactEditor = TReactEditor<EditorElements>
export type MyNode = ENode<EditorElements>
export type MyNodeEntry = ENodeEntry<EditorElements>
export type MyElement = EElement<EditorElements>
export type MyElementEntry = EElementEntry<EditorElements>
export type MyText = EText<EditorElements>
export type MyTextEntry = ETextEntry<EditorElements>
export type MyElementOrText = EElementOrText<EditorElements>
export type MyDescendant = EDescendant<EditorElements>
export type MyMarks = EMarks<EditorElements>
export type MyMark = keyof MyMarks

/**
 * Plate types
 */

export type MyDecorate<P = PluginOptions> = Decorate<P, EditorElements, MyEditor>
export type MyDecorateEntry = DecorateEntry<EditorElements>
export type MyDOMHandler<P = PluginOptions> = DOMHandler<P, EditorElements, MyEditor>
export type MyInjectComponent = InjectComponent<EditorElements>
export type MyInjectProps = InjectProps<EditorElements>
export type MyKeyboardHandler<P = PluginOptions> = KeyboardHandler<P, EditorElements, MyEditor>
export type MyOnChange<P = PluginOptions> = OnChange<P, EditorElements, MyEditor>
export type MyOverrideByKey = OverrideByKey<EditorElements, MyEditor>
export type MyPlatePlugin<P = PluginOptions> = PlatePlugin<P, EditorElements, MyEditor>
export type MyPlatePluginInsertData = PlatePluginInsertData<EditorElements>
export type MyPlatePluginProps = PlatePluginProps<EditorElements>
export type MyPlateProps = PlateProps<EditorElements, MyEditor>
export type MySerializeHtml = SerializeHtml<EditorElements>
export type MyWithOverride<P = PluginOptions> = WithOverride<P, EditorElements, MyEditor>

/**
 * Plate store, Slate context
 */

export const getMyEditor = (editor: MyEditor) => getTEditor<EditorElements, MyEditor>(editor)
export const useMyEditorRef = () => useEditorRef<EditorElements, MyEditor>()
export const useMyEditorState = () => useEditorState<EditorElements, MyEditor>()
export const useMyPlateEditorRef = (id?: PlateId) => usePlateEditorRef<EditorElements, MyEditor>(id)
export const useMyPlateEditorState = (id?: PlateId) =>
    usePlateEditorState<EditorElements, MyEditor>(id)
export const useMyPlateSelectors = (id?: PlateId) => usePlateSelectors<EditorElements, MyEditor>(id)
export const useMyPlateActions = (id?: PlateId) => usePlateActions<EditorElements, MyEditor>(id)
export const useMyPlateStates = (id?: PlateId) => usePlateStates<EditorElements, MyEditor>(id)

/**
 * Utils
 */
export const createMyEditor = () => createTEditor() as MyEditor
export const createMyPlateEditor = (
    options: CreatePlateEditorOptions<EditorElements, MyEditor> = {}
) => createPlateEditor<EditorElements, MyEditor>(options)
export const createMyPluginFactory = <P = PluginOptions>(
    defaultPlugin: PlatePlugin<NoInfer<P>, EditorElements, MyEditor>
) => createPluginFactory(defaultPlugin)
export const createMyPlugins = (
    plugins: MyPlatePlugin[],
    options?: {
        components?: Record<string, PlatePluginComponent>
        overrideByKey?: MyOverrideByKey
    }
) => createPlugins<EditorElements, MyEditor>(plugins, options)

export type MyAutoformatRule = AutoformatRule<EditorElements, MyEditor>
