import { Box, FilterOptionsState, SxProps, Theme } from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { map, uniq, uniqBy } from "lodash"
import { FC, useCallback } from "react"
import { TagChip } from "../chips/TagChip"
export interface Option {
    value: string
    label: string
    create?: boolean
    exist?: boolean
    group?: string
    isPermanentGroup?: boolean
    id?: string
}

interface Props {
    options?: Option[]
    handleChange: (_: string[]) => void
    value: Option[]
    textFieldProps?: TextFieldProps
    sx?: SxProps<Theme>
    isSearchOnly?: boolean
    optionName?: string
    inputValue: string
    setInputValue: (_: string) => void
}

const filter = createFilterOptions<Option>()

export const SearchAutocomplete: FC<Props> = ({
    value = [],
    handleChange,
    options,
    sx,
    textFieldProps = {},
    isSearchOnly = false,
    optionName = "tag",
    inputValue,
    setInputValue,
}) => {
    const onChange = (e, values: Option[] | string[], reason: string) => {
        if (reason === "removeOption" && ["Backspace", "Enter"].includes(e.code)) return

        const newValue = values.map((option: Option | string) =>
            typeof option === "string" ? option : option.label
        )

        handleChange(uniqBy(newValue, (tagName) => tagName.toLowerCase().trim()))
    }

    const filterOptions = useCallback(
        (options: Option[], params: FilterOptionsState<Option>) => {
            let filtered = filter(options, params)

            const groups = map(filtered, "group")
            const groupsCount = uniq(groups).length

            if (groupsCount === 1) {
                filtered = filtered.map(({ group, ...option }) =>
                    option.isPermanentGroup ? { ...option, group } : option
                )
            }

            if (isSearchOnly) return filtered

            const { inputValue } = params
            const isExisting = options.some(
                (option) => inputValue.toLowerCase().trim() === option.label.toLowerCase().trim()
            )

            if (isExisting || inputValue === "") return filtered

            const inputExistInValues = value.some(
                ({ value }) => value.trim().toLowerCase() === inputValue.trim().toLowerCase()
            )

            if (inputExistInValues) {
                return [
                    ...filtered,
                    {
                        value: inputValue,
                        label: inputValue,
                        exist: true,
                    },
                ]
            }

            return [
                ...filtered,
                {
                    value: inputValue,
                    label: inputValue,
                    create: true,
                },
            ]
        },
        // eslint-disable-next-line
        [isSearchOnly]
    )

    const getOption = (option: Option, input: string) => {
        if (option.create) return `Create: ${option.label}`
        if (option.exist) return `Tag already exist`
        return <TagChip sx={styles.tagChip} label={option.label} searchText={input} />
    }

    return (
        <Autocomplete
            multiple
            noOptionsText={`No ${optionName}s`}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue)
            }}
            options={options || []}
            isOptionEqualToValue={(option: Option, value: Option) => option.value === value.value}
            groupBy={(option) => option.group}
            freeSolo={!isSearchOnly}
            filterSelectedOptions
            value={value}
            onChange={onChange}
            ListboxProps={{
                // @ts-ignore
                sx: styles.listbox,
            }}
            sx={sx}
            openOnFocus
            disableClearable
            clearIcon={null}
            popupIcon={null}
            filterOptions={filterOptions}
            renderTags={() => null}
            renderOption={(props, option, state) => (
                <Box
                    component="li"
                    {...props}
                    key={option.id ? option.id : option.label}
                    sx={{
                        p: 1,
                        py: "4px !important",
                        minHeight: "20px !important",
                    }}
                    onClick={option.exist ? undefined : props.onClick}
                >
                    {getOption(option, state.inputValue)}
                </Box>
            )}
            renderInput={(params) => {
                const InputProps = textFieldProps?.InputProps || {}
                return (
                    <TextField
                        autoFocus
                        {...textFieldProps}
                        {...params}
                        InputProps={{ ...params.InputProps, ...InputProps }}
                        data-testid="card-tag-input"
                    />
                )
            }}
        />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    listbox: {
        border: (theme: Theme) => theme.borders.paper,
        borderRadius: 1,
        py: 0,
        mt: 0.5,
        "& .MuiAutocomplete-groupLabel": {
            fontSize: "1rem",
            lineHeight: "2rem",
        },
        "& .MuiAutocomplete-option": {
            pl: "8px !important",
        },
    },
    tagChip: {
        cursor: "pointer",
        direction: "rtl",
    },
}
