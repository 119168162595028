import { IconButton, Stack, SxProps, Theme, Tooltip } from "@mui/material"
import { PortalBody } from "@udecode/plate-common"
import { flip, offset, shift, useFloatingToolbar } from "@udecode/plate-floating"
import { FloatingToolbarState } from "@udecode/plate-floating/dist"
import { useIsMobile } from "hooks/useIsMobile"
import { FC } from "react"
import { MarkButtons } from "./MarkButtons"
import { TextTypeMenu } from "./TextTypeMenu"

import { Bolt } from "@mui/icons-material"
import { useFloatingToolbarState } from "../../hooks/useFloatingToolbarState"
import { useMyEditorRef } from "../../types"
import CustomEditor from "../../utils/CustomEditor"
import { LinkToolbarButton } from "./LinkToolbarButton"

export interface FloatingToolbarProps {
    state?: FloatingToolbarState
}

export const CREATE_LINK_BUTTON_CLICKED_EVENT = "create-link-button-clicked-event"
export const CREATE_LINK_BUTTON_ID = "create-link-button-id"

export const FloatingToolbar: FC<FloatingToolbarProps> = ({ state, ...props }) => {
    const isMobile = useIsMobile()
    const selection = window.getSelection()
    const isTextSelected =
        selection?.anchorNode?.nodeName === "#text" && selection?.type === "Range"

    const editor = useMyEditorRef()

    const floatingToolbarState = useFloatingToolbarState({
        ...state,
        floatingOptions: {
            placement: "top",
            middleware: [
                offset(isMobile ? 55 : 10),
                flip({
                    padding: 12,
                }),
                shift({ padding: 5 }),
            ],
            ...state?.floatingOptions,
        },
    })

    const { ref: floatingRef, props: rootProps, hidden } = useFloatingToolbar(floatingToolbarState)

    const shouldHideToolbar = hidden || !isTextSelected

    const insertReference = () => {
        CustomEditor.insertSearchReferenceText(editor, window.getSelection().toString())
        document.dispatchEvent(new CustomEvent(CREATE_LINK_BUTTON_CLICKED_EVENT))
    }

    if (isMobile && shouldHideToolbar && !floatingToolbarState.isMenuOpen) return null
    if (!isMobile && shouldHideToolbar) return null

    return (
        <PortalBody>
            <Stack
                ref={floatingRef}
                spacing={0.4}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={styles.stack}
                {...rootProps}
                {...props}
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
            >
                <TextTypeMenu
                    isOpen={floatingToolbarState.isMenuOpen}
                    setIsOpen={floatingToolbarState.setIsMenuOpen}
                />
                <MarkButtons isCodeVisible={!isMobile} />
                <LinkToolbarButton />
                <Tooltip title="Create link">
                    <IconButton
                        onClick={insertReference}
                        color="secondary"
                        id={CREATE_LINK_BUTTON_ID}
                    >
                        <Bolt />
                    </IconButton>
                </Tooltip>
            </Stack>
        </PortalBody>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    stack: {
        zIndex: 1201,
        borderRadius: 1,
        px: 0.6,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        py: 0.3,
        backgroundColor: (theme) => theme.palette.background.layout,
        boxShadow: (theme) => theme.shadows[4],
    },
}
