import { Box, Chip, ChipProps, SxProps, Theme } from "@mui/material"
import { useIsMobile } from "hooks/useIsMobile"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { extractSearchText } from "../tags/helpers/extractSearchText"

const TAG_WIDTH_WITH_SCROLL_IN_PX = 28

interface Props extends ChipProps {
    label: string
    size?: "small" | "medium"
    disabled?: boolean
    selected?: boolean
    onDelete?: (event: any) => void
    onClick?: (event: any) => void
    searchText?: string
    sx?: SxProps<Theme>
}

export const TagChip: React.FC<Props> = ({
    size = "small",
    sx = {},
    searchText,
    label,
    ...props
}) => {
    const isMobile = useIsMobile()
    const [shouldWrap, setShouldWrap] = useState(false)

    const tagLabel = useMemo(() => {
        if (!searchText) {
            return <>{label}</>
        }

        const extractedSearch = extractSearchText(label, searchText)

        if (!extractedSearch) {
            return <>{label}</>
        }

        const { beforeSearch, afterSearch, searchedText } = extractedSearch

        return (
            <>
                {beforeSearch}
                <Box component="b" sx={{ color: props.selected ? undefined : "secondary.main" }}>
                    {searchedText}
                </Box>
                {afterSearch}
            </>
        )
    }, [searchText, label, props.selected])

    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (isMobile || !ref?.current || !ref?.current.parentElement) return
        if (
            props.onDelete &&
            ref.current.getBoundingClientRect().right + TAG_WIDTH_WITH_SCROLL_IN_PX >
                ref.current.parentElement.getBoundingClientRect().right
        ) {
            setShouldWrap(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {shouldWrap && <Box width={TAG_WIDTH_WITH_SCROLL_IN_PX} />}
            <Chip
                ref={ref}
                size={size}
                sx={
                    isMobile
                        ? sx
                        : {
                              ...sx,
                              "& .MuiChip-deleteIcon": {
                                  width: 0,
                                  transform: "scale(0)",
                                  transition: "all 0.2s ease",
                              },
                              "&:hover": {
                                  "& .MuiChip-deleteIcon": {
                                      width: 17,
                                      transform: "scale(1)",
                                  },
                              },
                          }
                }
                variant={props.selected ? "filled" : "outlined"}
                color={props.selected ? "primary" : "default"}
                {...props}
                label={tagLabel}
            />
        </>
    )
}
