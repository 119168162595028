import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import React from "react"

export const BlockquoteElement = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    PlateElementProps
>(({ className, children, ...props }, ref) => {
    return (
        <PlateElement asChild ref={ref} {...props}>
            <blockquote>{children}</blockquote>
        </PlateElement>
    )
})
BlockquoteElement.displayName = "BlockquoteElement"
