import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import { FC } from "react"

interface VariantProps {
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

type HeadingElementProps = PlateElementProps & VariantProps

export const HeadingElement: FC<HeadingElementProps> = ({
    className,
    variant = "h1",
    children,
    ...props
}) => {
    const Element = variant!

    return (
        <PlateElement asChild {...props}>
            <Element>{children}</Element>
        </PlateElement>
    )
}
