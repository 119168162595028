var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UsageService } from "./usageService";
export class SummaryUsageService extends UsageService {
    constructor() {
        super(...arguments);
        this.limit = 10;
        this.increaseUsage = (userId) => __awaiter(this, void 0, void 0, function* () {
            const summariesGeneratedCount = yield this.getUsage(userId);
            const user = yield this.userRepository.getUser(userId);
            const newSummariesGeneratedCount = summariesGeneratedCount + 1;
            yield this.userRepository.upsertUser(userId, {
                usage: this.getUserUsage(user, { extension: newSummariesGeneratedCount }),
            });
            return newSummariesGeneratedCount;
        });
        this.isUsageExceeded = (userId) => __awaiter(this, void 0, void 0, function* () {
            const summariesGeneratedCount = yield this.getUsage(userId);
            return summariesGeneratedCount >= this.limit;
        });
        this.getUsage = (userId) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const user = yield this.userRepository.getUser(userId);
            const summariesGeneratedCount = ((_a = user === null || user === void 0 ? void 0 : user.usage) === null || _a === void 0 ? void 0 : _a.extension) || 0;
            return summariesGeneratedCount;
        });
    }
}
