import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { Box, Button, Container, SxProps, Theme, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { Spinner } from "@recall/common"
import { Loader } from "components/layouts/components/Loader/AppLoader"
import { useGenerateQuestions } from "hooks/useGenerateQuestions"
import { orderBy } from "lodash"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { isLoadingQuestionsSelector } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { ItemModel, QuestionModel } from "storage/watermelon/models"
import { questionRepository } from "storage/watermelon/repository/questionRepository"
import { SurfaceBox } from "../surfaces/SurfaceBox"
import { CreateQuestion } from "./CreateQuestion"
import { FormQuestion, QuestionForm } from "./QuestionForm"
import { QuestionsLoader } from "./QuestionsLoader"

interface Props {
    item: ItemModel
    questions: QuestionModel[]
    setQuestions: Dispatch<SetStateAction<QuestionModel[]>>
}

const VISIBLE_QUESTIONS_STEP = 4

export const Questions: FC<Props> = ({ item, questions, setQuestions }) => {
    const isLoadingQuestions = useSelector((state: RootState) =>
        isLoadingQuestionsSelector(state, item.id)
    )
    const [visibleCount, setVisibleCount] = useState(VISIBLE_QUESTIONS_STEP)
    const { handleGenerateMoreQuestions, handleGenerateQuestions, isGenerateFailed } =
        useGenerateQuestions({ item, questions, setQuestions })
    const db = useDatabase()

    useEffect(() => {
        if (!isLoadingQuestions && !questions?.length) getQuestions()
        // eslint-disable-next-line
    }, [isLoadingQuestions])

    const getQuestions = async () => {
        const questions = await questionRepository.getQuestionsByItemId(db, item.id)

        setQuestions(orderBy(questions, ["createdAt", "id"]))
    }

    const handleSubmit = async (question: QuestionModel, data: FormQuestion) => {
        await questionRepository.update({
            db,
            question,
            data: { ...data, answer: null },
        })
    }

    const deleteQuestion = async (question: QuestionModel) => {
        setQuestions((prev) => prev.filter((q) => q.id !== question.id))
    }

    const handleIncreaseVisible = () => {
        setVisibleCount((prev) => prev + VISIBLE_QUESTIONS_STEP)
    }

    if (isLoadingQuestions && questions.length === 0)
        return (
            <Box sx={styles.empty}>
                <Loader loadingText="Generating Questions..." />
            </Box>
        )

    if (!questions.length) {
        return (
            <Container maxWidth="sm" sx={styles.empty}>
                <Typography mb={2}>
                    {isGenerateFailed
                        ? "Failed to generate questions"
                        : "No questions for this card"}
                </Typography>

                <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
                    <Button
                        disabled={isLoadingQuestions}
                        onClick={handleGenerateQuestions}
                        startIcon={<AutoAwesomeIcon />}
                        color="primary"
                        variant="contained"
                    >
                        {isGenerateFailed ? "Try Again" : "Generate AI Questions"}
                    </Button>
                    <CreateQuestion
                        disabled={isLoadingQuestions}
                        refetchQuestions={getQuestions}
                        item={item}
                    />
                </Box>
            </Container>
        )
    }

    const visibleQuestions = questions.slice(0, visibleCount)

    return (
        <Container maxWidth="sm" sx={styles.container}>
            <Typography textAlign="center" variant="h4" fontWeight={500} mt={3} mb={1}>
                Manage Card Questions
            </Typography>
            <Typography textAlign="center" variant="h6" mb={1} color="text.secondary">
                You can edit questions, modify answers, and change the correct answers for each
                question.
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center" mt={1}>
                <Button
                    disabled={isLoadingQuestions}
                    onClick={handleGenerateMoreQuestions}
                    startIcon={isLoadingQuestions ? <Spinner size={20} /> : <AutoAwesomeIcon />}
                    color="primary"
                    variant="contained"
                >
                    Add More AI Questions
                </Button>
                <CreateQuestion
                    disabled={isLoadingQuestions}
                    refetchQuestions={getQuestions}
                    item={item}
                />
            </Box>
            <Box sx={styles.questions}>
                {visibleQuestions.map((question) => (
                    <SurfaceBox key={question.id} sx={styles.questionContainer}>
                        <QuestionForm
                            key={question.id}
                            handleClose={() => {}}
                            handleFormSubmit={handleSubmit}
                            question={question}
                            deleteQuestion={deleteQuestion}
                        />
                    </SurfaceBox>
                ))}
                <QuestionsLoader
                    isLoaded={visibleCount >= questions.length}
                    increaseVisibleQuestions={handleIncreaseVisible}
                />
            </Box>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        pb: 6,
        pt: { xs: 6, sm: 10 },
    },
    questions: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 3,
        width: { xs: "100%", sm: "80%", md: 600 },
    },
    questionContainer: {
        px: 2,
        py: 1,
    },
    empty: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        minHeight: "90vh",
    },
}
