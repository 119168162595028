var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { COLLECTIONS } from "../constants";
import { firebase } from "../services";
import { isYoutubeVideo, sentry } from "../utils";
const getLibraryItemApi = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const libraryItemRef = doc(firebase.firestore, COLLECTIONS.LIB_ITEMS, id);
        const snapshot = yield getDoc(libraryItemRef);
        if (!snapshot.exists())
            return null;
        const libraryItem = snapshot.data();
        return (libraryItem === null || libraryItem === void 0 ? void 0 : libraryItem.itemApi) || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
});
const getLibraryItemByVideoId = (videoId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const libraryCollectionRef = collection(firebase.firestore, COLLECTIONS.LIB_ITEMS);
        const querySnapshot = yield getDocs(query(libraryCollectionRef, where("videoId", "==", videoId)));
        if (querySnapshot.empty)
            return null;
        const libraryItem = querySnapshot.docs[0].data();
        return libraryItem || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
});
const getLibraryItemByUrl = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const libraryCollectionRef = collection(firebase.firestore, COLLECTIONS.LIB_ITEMS);
        const querySnapshot = yield getDocs(query(libraryCollectionRef, where("url", "==", url)));
        if (querySnapshot.empty)
            return null;
        const libraryItem = querySnapshot.docs[0].data();
        return libraryItem || null;
    }
    catch (e) {
        const err = e;
        sentry.captureException(err);
        return null;
    }
});
const getLibraryItemByUrlOrVideoId = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (isYoutubeVideo(url)) {
            const urlParams = new URLSearchParams(new URL(url).search);
            const videoId = urlParams.get("v");
            if (!videoId)
                return null;
            const libraryItem = yield getLibraryItemByVideoId(videoId);
            return libraryItem;
        }
        else {
            const libraryItem = yield getLibraryItemByUrl(url);
            return libraryItem;
        }
    }
    catch (e) {
        sentry.captureException(e, { url });
        return null;
    }
});
export const libraryRepository = {
    getLibraryItemApi,
    getLibraryItemByUrlOrVideoId,
};
