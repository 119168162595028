"use client"

import { Box, MenuItem, Popper } from "@mui/material"
import {
    ComboboxContentItemProps,
    ComboboxContentProps,
    ComboboxProps,
    Data,
    NoData,
    TComboboxItem,
    comboboxActions,
    useActiveComboboxStore,
    useComboboxContent,
    useComboboxContentState,
    useComboboxControls,
    useComboboxItem,
    useComboboxSelectors,
} from "@udecode/plate-combobox"
import { useEventEditorSelectors, usePlateEditorState } from "@udecode/plate-common"
import { getBoundingClientRect } from "@udecode/plate-floating"
import { useEffect } from "react"
import { useFocused } from "slate-react"

export function ComboboxItem<TData extends Data = NoData>({
    combobox,
    index,
    item,
    onRenderItem,
}: ComboboxContentItemProps<TData>) {
    const { props } = useComboboxItem({ item, index, combobox, onRenderItem })

    return <MenuItem selected={props["data-highlighted"]} {...props} />
}

export function ComboboxContent<TData extends Data = NoData>(props: ComboboxContentProps<TData>) {
    const { component: Component, items, combobox, onRenderItem } = props

    const editor = usePlateEditorState()

    const filteredItems = useComboboxSelectors.filteredItems() as TComboboxItem<TData>[]
    const activeComboboxStore = useActiveComboboxStore()!

    const state = useComboboxContentState({ items, combobox })
    const { menuProps, targetRange } = useComboboxContent(state)

    const rect = getBoundingClientRect(editor, targetRange ?? undefined)

    if (!rect) return null

    return (
        <Popper
            open
            anchorEl={{
                getBoundingClientRect: () => {
                    return rect
                },
                nodeType: 1,
            }}
            sx={{ zIndex: 1201 }}
        >
            <Box
                {...menuProps}
                sx={{
                    background: (theme) => theme.palette.background.layout,
                    borderRadius: 1,
                    border: (theme) => `1px solid ${theme.palette.background.paper}`,
                    maxHeight: 288,
                    minWidth: 300,
                    overflowY: "auto",
                }}
            >
                {Component ? Component({ store: activeComboboxStore }) : null}

                {filteredItems.map((item, index) => (
                    <ComboboxItem
                        key={item.key}
                        item={item}
                        combobox={combobox}
                        index={index}
                        onRenderItem={onRenderItem}
                    />
                ))}
            </Box>
        </Popper>
    )
}

export function Combobox<TData extends Data = NoData>({
    id,
    trigger,
    searchPattern,
    onSelectItem,
    controlled,
    maxSuggestions,
    filter,
    sort,
    disabled: _disabled,
    ...props
}: ComboboxProps<TData>) {
    const storeItems = useComboboxSelectors.items()
    const disabled = _disabled ?? (storeItems.length === 0 && !props.items?.length)
    const focusedEditorId = useEventEditorSelectors.focus?.()
    const combobox = useComboboxControls()
    const activeId = useComboboxSelectors.activeId()
    const focused = useFocused()

    const editor = usePlateEditorState()

    useEffect(() => {
        comboboxActions.setComboboxById({
            id,
            trigger,
            searchPattern,
            controlled,
            onSelectItem,
            maxSuggestions,
            filter,
            sort,
        })
    }, [
        id,
        trigger,
        searchPattern,
        controlled,
        onSelectItem,
        maxSuggestions,
        filter,
        sort,
        editor.selection,
    ])

    useEffect(() => {
        if (!focused) {
            comboboxActions.reset()
        }
    }, [focused])

    if (
        !combobox ||
        !editor.selection ||
        focusedEditorId !== editor.id ||
        activeId !== id ||
        disabled
    ) {
        return null
    }

    return <ComboboxContent combobox={combobox} {...props} />
}
