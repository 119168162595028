import { useCodeSyntaxLeaf } from "@udecode/plate-code-block"
import { PlateLeaf, PlateLeafProps } from "@udecode/plate-common"
import { FC } from "react"

export const CodeSyntaxLeaf: FC<PlateLeafProps> = ({ children, ...props }) => {
    const { tokenProps } = useCodeSyntaxLeaf({ leaf: props.leaf })

    return (
        <PlateLeaf {...props}>
            <span {...tokenProps}>{children}</span>
        </PlateLeaf>
    )
}
