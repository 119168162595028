var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { COLLECTIONS } from "../constants";
import { firebase } from "../services";
const update = (params) => __awaiter(void 0, void 0, void 0, function* () {
    yield updateDoc(doc(firebase.firestore, COLLECTIONS.REVIEWS_SCHEDULE, params.id), Object.assign({}, params));
});
const upsert = (uid, params) => __awaiter(void 0, void 0, void 0, function* () {
    yield setDoc(doc(firebase.firestore, COLLECTIONS.REVIEWS_SCHEDULE, params.id), Object.assign({ uid }, params));
});
const remove = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const docRef = doc(firebase.firestore, COLLECTIONS.REVIEWS_SCHEDULE, id);
    const document = yield getDoc(docRef);
    if (document.exists())
        yield deleteDoc(docRef);
});
export const reviewsScheduleRepository = {
    upsert,
    update,
    remove,
};
