import { createAlignPlugin } from "@udecode/plate-alignment"
import { createAutoformatPlugin } from "@udecode/plate-autoformat"
import { createBasicElementsPlugin } from "@udecode/plate-basic-elements"
import { createBasicMarksPlugin } from "@udecode/plate-basic-marks"
import { createExitBreakPlugin } from "@udecode/plate-break"
import { createComboboxPlugin } from "@udecode/plate-combobox"
import { createInsertDataPlugin } from "@udecode/plate-common"
import { createEmojiPlugin } from "@udecode/plate-emoji"
import { createFontBackgroundColorPlugin, createFontColorPlugin } from "@udecode/plate-font"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    KEYS_HEADING,
} from "@udecode/plate-heading"
import { createHighlightPlugin } from "@udecode/plate-highlight"
import { createHorizontalRulePlugin } from "@udecode/plate-horizontal-rule"
import { createIndentPlugin } from "@udecode/plate-indent"
import { createIndentListPlugin } from "@udecode/plate-indent-list"
import { createLinkPlugin } from "@udecode/plate-link"
import { ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED, createImagePlugin } from "@udecode/plate-media"
import { createMentionPlugin } from "@udecode/plate-mention"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { createSelectOnBackspacePlugin } from "@udecode/plate-select"
import { ItemModel } from "storage/watermelon/models"
import { createUIComponents } from "../components"
import { EmojiCombobox } from "../components/EmojiPicker/EmojiCombobox"
import { LinkFloatingToolbar } from "../components/Toolbar/LinkFloatingToolbar"
import { MyPlatePlugin, createMyPlugins } from "../types"
import { autoformatRules } from "./autoformat"
import { ELEMENT_CUSTOM_EDITOR_BLOCK, createCustomEditorBlockPlugin } from "./editor-block"
import { createCustomImagePlugin } from "./image"
import { ELEMENT_CUSTOM_PARAGRAPH, createCustomParagraphPlugin } from "./paragraph"
import { createReferencePlugin } from "./reference"
import { EmojiTriggeringController } from "./withEmoji/EmojiTriggeringController"
import { withEmoji } from "./withEmoji/withEmoji"
import { withImage } from "./withImage/withImage"
import { withPaste } from "./withPaste/withPaste"
import { createYouTubeTimestampPlugin } from "./youtube-timestamp"

interface Props {
    item?: ItemModel
    readOnly?: boolean
    uploadImage?: (dataUrl: string | ArrayBuffer) => Promise<string>
}

const textTypes = [
    ELEMENT_CUSTOM_EDITOR_BLOCK,
    ELEMENT_CUSTOM_PARAGRAPH,
    ELEMENT_PARAGRAPH,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
]

export const createPlatePlugins = ({
    readOnly = false,
    item,
    uploadImage,
}: Props): MyPlatePlugin[] => {
    return createMyPlugins(
        [
            createInsertDataPlugin({ withOverrides: withPaste }),
            createBasicElementsPlugin(),
            createHorizontalRulePlugin(),
            createBasicMarksPlugin(),
            createCustomEditorBlockPlugin(),
            createCustomParagraphPlugin(),
            createAutoformatPlugin({
                options: {
                    rules: autoformatRules as any,
                    enableUndoOnDelete: true,
                },
            }),
            createIndentPlugin({
                inject: {
                    props: {
                        validTypes: textTypes,
                    },
                },
            }),
            createIndentListPlugin({
                inject: {
                    props: {
                        validTypes: textTypes,
                    },
                },
            }),
            createComboboxPlugin(),
            createEmojiPlugin({
                withOverrides: withEmoji,
                options: {
                    emojiTriggeringController: new EmojiTriggeringController(),
                },
                renderAfterEditable: EmojiCombobox,
            }),
            createAlignPlugin({
                inject: {
                    props: {
                        validTypes: textTypes,
                    },
                },
            }),
            createExitBreakPlugin({
                options: {
                    rules: [
                        {
                            hotkey: "mod+enter",
                        },
                        {
                            hotkey: "mod+shift+enter",
                            before: true,
                        },
                        {
                            hotkey: "enter",
                            query: {
                                start: true,
                                end: true,
                                allow: KEYS_HEADING,
                            },
                            relative: true,
                            level: 1,
                        },
                    ],
                },
            }),
            createHighlightPlugin(),
            createReferencePlugin(),
            createImagePlugin({
                options: {
                    disableEmbedInsert: true,
                    uploadImage,
                },
                withOverrides: withImage,
            }),
            createCustomImagePlugin(),
            // createCaptionPlugin({ options: { pluginKeys: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED] } }),
            // createMediaEmbedPlugin(),
            createFontColorPlugin(),
            createFontBackgroundColorPlugin(),
            createSelectOnBackspacePlugin({
                options: {
                    query: {
                        allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
                    },
                },
            }),
            createMentionPlugin({
                options: {
                    insertSpaceAfterMention: true,
                    trigger: "/",
                },
            }),
            createYouTubeTimestampPlugin(),
            createLinkPlugin({
                renderAfterEditable: LinkFloatingToolbar,
            }),
        ],
        { components: createUIComponents({ readOnly, item }) }
    )
}
