import { Check, Colorize } from "@mui/icons-material"
import {
    Box,
    Button,
    Divider,
    Grid,
    SxProps,
    Theme,
    Tooltip,
    Typography,
    alpha,
} from "@mui/material"
import { useColorInput, useColorsCustom, useColorsCustomState } from "@udecode/plate-font"
import React, { FC } from "react"

interface Color {
    name: string
    value: string
    isBrightColor: boolean
}

type ColorPickerProps = {
    color?: string
    colors: Color[]
    customColors: Color[]
    updateColor: (color: string) => void
    updateCustomColor: (color: string) => void
    clearColor: () => void
} & React.HTMLAttributes<HTMLDivElement>

export const ColorPicker = ({
    color,
    colors,
    customColors,
    updateColor,
    updateCustomColor,
    clearColor,
}: ColorPickerProps) => {
    const state = useColorsCustomState({
        color,
        colors,
        customColors,
        updateCustomColor,
    })
    const { inputProps } = useColorsCustom(state)
    const { inputRef, childProps } = useColorInput()

    return (
        <Grid container spacing={1} sx={{ maxWidth: 300, p: 1, py: 0 }}>
            {customColors.map((paletteColor) => (
                <Grid item xs={12 / 10} key={paletteColor.value}>
                    <ColorBox
                        color={paletteColor}
                        currentColor={color}
                        updateColor={updateCustomColor}
                    />
                </Grid>
            ))}
            <Grid item xs={6} justifyContent="flex-end" display="flex" alignItems="center">
                <Typography variant="caption">Custom:</Typography>
                <Box
                    {...childProps}
                    sx={{
                        ...styles.color,
                        background: color,
                        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
                        ml: 0.6,
                    }}
                >
                    <Colorize color="inherit" fontSize="inherit" />
                    <Box
                        overflow="hidden"
                        component="input"
                        ref={inputRef}
                        type="color"
                        value={inputProps.value}
                        onChange={inputProps.onChange}
                        height={0}
                        width={0}
                        border={"none"}
                        p={0}
                    />
                </Box>
            </Grid>
            <Grid xs={12} item>
                <Divider />
            </Grid>
            {colors.map((paletteColor) => (
                <Grid item xs={12 / 10} key={paletteColor.value}>
                    <ColorBox color={paletteColor} currentColor={color} updateColor={updateColor} />
                </Grid>
            ))}
            {color && (
                <Grid item xs={12}>
                    <Button color="inherit" onClick={clearColor}>
                        Clear
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

interface ColorBoxProps {
    color: Color
    currentColor?: string
    updateColor: (color: string) => void
}

const ColorBox: FC<ColorBoxProps> = ({
    color: { name, value, isBrightColor },
    currentColor,
    updateColor,
}) => {
    return (
        <Tooltip title={name}>
            <Box
                onClick={() => updateColor(value)}
                sx={{
                    ...styles.color,
                    color: isBrightColor ? "black" : "white",
                    border: (theme) =>
                        `1px solid ${
                            currentColor === value
                                ? alpha(theme.palette.text.primary, 0.3)
                                : alpha(theme.palette.text.primary, 0.1)
                        }`,
                    backgroundColor: value,
                }}
            >
                {currentColor === value ? <Check color="inherit" fontSize="inherit" /> : null}
            </Box>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    color: {
        width: 24,
        height: 24,
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
