import { TCodeBlockElement } from "@udecode/plate-code-block"
import { PlateElement, PlateElementProps, Value } from "@udecode/plate-common"
import { forwardRef } from "react"

export const CodeBlockElement = forwardRef<
    HTMLDivElement,
    PlateElementProps<Value, TCodeBlockElement>
>(({ className, children, ...props }, ref) => {
    return (
        <PlateElement ref={ref} {...props}>
            <pre>
                <code>{children}</code>
            </pre>
        </PlateElement>
    )
})
CodeBlockElement.displayName = "CodeBlockElement"
