import { useDatabase } from "@nozbe/watermelondb/react"
import { sentry } from "@recall/common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ASSET_TYPES, ItemModel } from "storage/watermelon/models"
import { connectionRepository } from "storage/watermelon/repository"
import { assetRepository } from "storage/watermelon/repository/assetRepository"
import { ELEMENT_REFERENCE } from "../plugins/reference"
import { EditorElements, MyRootBlock } from "../types"

const MAX_DEPTH = 100

export const useCleanUpCopiedBlocks = (item: ItemModel) => {
    const db = useDatabase()

    const processBlock = async (child: any) => {
        if (child?.type === ELEMENT_REFERENCE && child.connectionId) {
            const connection = await connectionRepository.get(db, child.connectionId)
            const newConnection = await connectionRepository.create(db, {
                toId: connection.to.id,
                fromId: item.id,
            })
            child.connectionId = newConnection.id
        }

        if (child?.type === ELEMENT_IMAGE && child.url) {
            const asset = await assetRepository.getByUrlAndItemId(db, child.url, item.id)
            if (asset.length) return

            await assetRepository.createAsset(db, {
                item,
                url: child.url,
                size: 0,
                type: ASSET_TYPES.IMAGE_URL,
            })
        }
    }

    const processCopiedElements = async (children: MyRootBlock["children"], depth = 0) => {
        if (depth > MAX_DEPTH) {
            sentry.captureMessage("Max children depth reached")
            return children
        }
        for (const child of children) {
            if ("children" in child) {
                child.children = await processCopiedElements(child.children as any, depth + 1)
            }
            await processBlock(child)
        }

        return children
    }

    const cleanUpCopiedBlocks = async (blocks: EditorElements, depth = 0) => {
        if (depth > MAX_DEPTH) {
            sentry.captureMessage("Max blocks depth reached")
            return blocks
        }

        for (const block of blocks) {
            if (block.copied) {
                delete block.copied
                delete block.id
                if (block.children)
                    block.children = await processCopiedElements(block.children, depth + 1)
                await processBlock(block)
            }
            if (block.children) {
                await cleanUpCopiedBlocks(block.children as any)
            }
        }

        return blocks
    }

    return { cleanUpCopiedBlocks }
}
