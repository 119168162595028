import { useDatabase } from "@nozbe/watermelondb/react"
import { Question, posthogService, sentry } from "@recall/common"
import { GENERATE_MORE_QUESTIONS_EVENT, GENERATE_QUESTIONS_EVENT } from "constants/events"
import { map } from "lodash"
import { Dispatch, SetStateAction, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { questionsApi } from "services/api"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { questionReviewService } from "services/questionReviewService"
import {
    ADD_LOADING_QUESTION_ITEM_ID,
    REMOVE_LOADING_QUESTION_ITEM_ID,
} from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { ItemModel, QuestionModel } from "storage/watermelon/models"

export const useGenerateQuestions = ({
    item,
    questions,
    setQuestions,
}: {
    setQuestions: Dispatch<SetStateAction<QuestionModel[]>>
    questions: QuestionModel[]
    item: ItemModel
}) => {
    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)
    const [isGenerateFailed, setIsGenerateFailed] = useState(false)

    const dispatch = useDispatch()

    const createQuestions = async (questions: Question[]) => {
        const createdQuestions: QuestionModel[] = []

        for (const question of questions) {
            const options = question.options.map((option) => option.trim())
            const correctAnswer = question.answer.trim()

            if (!options.includes(correctAnswer)) continue

            const createdQuestion = await questionReviewService.createQuestionAndScheduleReview(
                uid,
                {
                    db,
                    item,
                    options,
                    question: question.question.trim(),
                    correctAnswer,
                }
            )

            createdQuestions.push(createdQuestion)
        }

        return createdQuestions
    }

    const handleGenerateQuestions = async () => {
        posthogService.captureEvent(GENERATE_QUESTIONS_EVENT)

        const text = await getItemText()

        const questionsResponse = await generateQuestions(text)

        if (questionsResponse === null) {
            setIsGenerateFailed(true)
            return
        }

        const questions = await createQuestions(questionsResponse)
        setQuestions(questions)
    }

    const handleGenerateMoreQuestions = async () => {
        posthogService.captureEvent(GENERATE_MORE_QUESTIONS_EVENT)

        const text = await getItemText()

        const questionsResponse = await generateQuestions(text)

        if (questionsResponse === null) {
            return
        }

        const createdQuestions = await createQuestions(
            questionsResponse.filter((question) =>
                questions.every((q) => q.question !== question.question)
            )
        )
        setQuestions((prev) => [...prev, ...createdQuestions])
    }

    const getItemText = async () => {
        const editorBlocks = await item.editorBlocks.fetch()
        const editorOrders = await item.editorOrders.fetch()

        const orderedEditorBlocks = EditorBlockData.getOrdered(editorBlocks, editorOrders[0])
        const text = EditorBlockData.getText(orderedEditorBlocks, null)

        return text
    }

    const generateQuestions = async (text: string, retry = 0) => {
        const existingQuestions = questions.length ? map(questions, "question") : undefined
        let data = null
        try {
            dispatch({ type: ADD_LOADING_QUESTION_ITEM_ID, payload: item.id })
            data = await questionsApi.generateQuestions(
                item.name + ". " + text,
                existingQuestions,
                { "Accept-Language": item.language || "en", "Content-Type": "application/json" }
            )
        } catch (err) {
            sentry.captureException(err, { message: "Failed to generate questions" })
        } finally {
            dispatch({ type: REMOVE_LOADING_QUESTION_ITEM_ID, payload: item.id })
        }

        if (data === null && retry < 3) {
            return await generateQuestions(text, retry + 1)
        }

        return data
    }

    return { handleGenerateQuestions, handleGenerateMoreQuestions, isGenerateFailed }
}
