import DeleteIcon from "@mui/icons-material/Delete"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { Box, SxProps, Theme, Tooltip } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { useOpenItem } from "hooks/items/useOpenItem"
import React, { ComponentProps, ReactNode } from "react"
import { ItemModel } from "storage/watermelon/models"
import { connectionRepository } from "storage/watermelon/repository"
import ItemPartialComp from "../ItemPartial/ItemPartialComp"
import { ActionButton } from "../buttons/ActionButton"

interface Props {
    item: ItemModel
    children: React.ReactNode
    connectionId: string
    open: boolean
    actionButtonsStart?: ReactNode
    actionButtonsEnd?: ReactNode
    ItemPartialProps?: Partial<ComponentProps<typeof ItemPartialComp>>
    readOnly?: boolean
    hidePin?: boolean
}

export const ItemPopper: React.FC<React.PropsWithChildren<Props>> = ({
    connectionId,
    item,
    open,
    actionButtonsEnd,
    actionButtonsStart,
    children,
    ItemPartialProps = {},
    readOnly,
    hidePin = false,
}: Props) => {
    const db = useDatabase()
    const { openItem } = useOpenItem()

    const handleDeleteConnection = async () => {
        await connectionRepository.delete(db, [connectionId])
    }

    const handleOpenItem = () => {
        openItem(item)
    }

    return (
        <Tooltip
            open={open}
            PopperProps={{
                sx: styles.popper,
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -2],
                        },
                    },
                ],
            }}
            title={
                <Box sx={styles.title}>
                    {!readOnly && (
                        <Box sx={styles.header}>
                            <Box>
                                <ActionButton
                                    onClick={handleDeleteConnection}
                                    tooltip={"Delete link to this card"}
                                >
                                    <DeleteIcon fontSize={"inherit"} />
                                </ActionButton>
                                {actionButtonsStart && actionButtonsStart}
                            </Box>
                            <Box>
                                <ActionButton onClick={handleOpenItem} tooltip={"Open Card"}>
                                    <OpenInFullIcon fontSize={"inherit"} />
                                </ActionButton>
                                {actionButtonsEnd && actionButtonsEnd}
                            </Box>
                        </Box>
                    )}
                    {item && (
                        <ItemPartialComp
                            item={item}
                            hidePin={hidePin}
                            onClick={readOnly ? undefined : handleOpenItem}
                            mentionCountBadgeProps={{
                                onlyOtherMentionCount: true,
                            }}
                            {...ItemPartialProps}
                            style={{
                                ...ItemPartialProps.style,
                                paper: {
                                    borderRadius: 0,
                                    cursor: readOnly ? "auto" : "pointer",
                                },
                            }}
                        />
                    )}
                </Box>
            }
            arrow
        >
            <span>{children}</span>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    popper: {
        "& .MuiTooltip-arrow": {
            color: (theme: Theme) => theme.palette.background.layout,
        },
        "& .MuiTooltip-arrow::before": {
            border: (theme: Theme) => theme.borders.paper,
        },
        "& .MuiTooltip-tooltip": {
            padding: 0,
            borderRadius: 1,
            border: (theme: Theme) => theme.borders.paper,
            backgroundColor: (theme: Theme) => theme.palette.background.layout,
        },
    },
    title: {
        boxShadow: 3,
        maxWidth: 350,
        minWidth: 200,
        overflow: "hidden",
        borderRadius: 1,
        borderTopLeftRadius: 0,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
}
