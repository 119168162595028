import { Box, SxProps, Theme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { PlateElementProps } from "@udecode/plate-common"
import { useIsMobile } from "hooks/useIsMobile"
import React, { useEffect } from "react"
import { useFocused } from "slate-react"
import { ItemModel } from "storage/watermelon/models"
import { connectionRepository, itemRepository } from "storage/watermelon/repository"
import useSearchItems, { Result } from "../../../../../hooks/useSearchItems"
import { SearchResults } from "../../../../shared/SearchResults/SearchResults"
import { useMyEditorState } from "../types"
import { handleScroll } from "../utils"
import CustomEditor from "../utils/CustomEditor"

export const LINKS_SEARCH_INPUT_ID = "id-link-search-input"
export const LINK_SELECTED_EVENT = "link-selected-event"

interface SearchReferenceElementProps extends PlateElementProps {
    item: ItemModel
}

export const SearchReferenceElement: React.FC<
    React.PropsWithChildren<SearchReferenceElementProps>
> = ({ attributes, children, item, nodeProps }) => {
    const db = useDatabase()
    const editor = useMyEditorState()
    const [ref, setRef] = React.useState(null)
    const isMobile = useIsMobile()
    const focused = useFocused()

    useEffect(() => {
        if (!ref) return

        handleScroll()
    }, [ref, isMobile])

    useEffect(() => {
        if (!focused) handleClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused])

    const handleClose = () => {
        CustomEditor.removeSearchReference(editor)
    }

    const handleSelectResult = async (result: Result) => {
        result = {
            ...result,
            isSaved: true,
        }

        const itemModel = await itemRepository.getOrCreate(db, result)

        const connectionModel = await connectionRepository.create(db, {
            fromId: item.id,
            toId: itemModel.id,
        })

        handleClose()
        CustomEditor.insertReference(editor, connectionModel.id, itemModel.name)
        CustomEditor.moveCursorAfterReference(editor)

        const event = new CustomEvent(LINK_SELECTED_EVENT, { detail: { itemId: itemModel.id } })
        document.dispatchEvent(event)
    }

    const textNode = CustomEditor.getTextNode(editor)

    const searchTerm = textNode?.text || ""
    const [loading, results] = useSearchItems(searchTerm)

    return (
        <span {...nodeProps} ref={(r) => setRef(r)} id={LINKS_SEARCH_INPUT_ID}>
            <Box component="span" sx={styles.chip} {...attributes}>
                {children}
            </Box>
            {ref && (
                <SearchResults
                    value={searchTerm}
                    results={results}
                    loading={loading}
                    open={true}
                    anchorEl={ref}
                    onClose={handleClose}
                    onSelectResult={handleSelectResult}
                    closeOnEmptyBackspace={true}
                    subheaderText="Keep typing to search..."
                />
            )}
        </span>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    chip: {
        whiteSpace: "nowrap",
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
        paddingTop: (theme) => theme.spacing(0.4),
        paddingBottom: (theme) => theme.spacing(0.4),
        paddingRight: (theme) => theme.spacing(1),
        paddingLeft: (theme) => theme.spacing(1),
        borderRadius: (theme) => theme.spacing(1.5),
        outline: "none",
    },
}
