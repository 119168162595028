import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, relation, writer } from "@nozbe/watermelondb/decorators"
import { dispatchConnectionDeleted } from "@recall/common"
import { CONNECTION_PROPERTIES } from "../schema"
import { ConnectionPropertyModel } from "./ConnectionPropertyModel"
import { ItemModel } from "./ItemModel"

export class ConnectionModel extends Model {
    static table = "connections"

    static associations = {
        property: { type: "belongs_to", key: "property_id" } as BelongsToAssociation,
    }

    //@ts-ignore
    @field("is_saved") isSaved: boolean
    //@ts-ignore
    @relation("items", "from_id") from: Relation<ItemModel>
    //@ts-ignore
    @relation("items", "to_id") to: Relation<ItemModel>
    //@ts-ignore
    @relation(CONNECTION_PROPERTIES, "property_id") property: Relation<ConnectionPropertyModel>

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: ConnectionModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    @writer async deleteWithStaleItem(dispatchEvent = true) {
        const tasks = await this.prepareDeleteWithStaleItem(dispatchEvent)
        await this.batch(...tasks)
    }

    prepareDeleteWithStaleItem = async (dispatchEvent = true) => {
        let tasks = []

        let toItem: ItemModel
        let isStale: boolean

        const task = this.prepareDelete(dispatchEvent)

        try {
            toItem = await this.to.fetch()
            isStale = await toItem.isStale(1)
        } catch (e) {
            isStale = false
        }

        if (isStale) {
            tasks = await toItem.prepareDelete()
        }

        tasks.push(task)
        return tasks
    }

    prepareDelete = (dispatchEvent = true) => {
        // @ts-ignore
        if (this._preparedState === null) {
            if (dispatchEvent === true) {
                dispatchConnectionDeleted(this.id)
            }

            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete(dispatchEvent = true) {
        const task = this.prepareDelete(dispatchEvent)

        if (!task) return

        await this.batch(task)
    }

    // prepareMarkAsDeleted = () => {
    //     dispatchConnectionDeleted(this.id)
    //     return super.prepareMarkAsDeleted()
    // }

    // prepareDestroyPermanently = () => {
    //     dispatchConnectionDeleted(this.id)
    //     return super.prepareDestroyPermanently()
    // }
}
