import { alpha, Avatar, Box, Grid, Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { SavedBadge, WIKIPEDIA, getSource } from "@recall/common"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { Result } from "hooks/useSearchItems"
import { truncate } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { ImageBlockData } from "services/editorData/ImageBlockData"
import { wikiService } from "services/wikiService"
import TypographyTruncated from "../../misc/TypographyTruncated"
import { SummarizeSearchResult } from "./SummarizeSearchResult"

interface Props {
    result: Result
    value: string
    selected?: boolean
}

const SearchResultComp: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const [imageUrl, setImageUrl] = useState(null)

    const classes = useStyles()
    const resultRef = useRef(null)

    const description = EditorBlockData.getText(props.result.editorBlocks)
    const imageBlock = EditorBlockData.getFirstImageBlock(props.result.editorBlocks)

    const getSearchResultImage = async () => {
        if (imageBlock) {
            setImageUrl(
                imageBlock?.url || imageBlock?.options?.url || ImageBlockData.getUrl320(imageBlock)
            )
            return
        }

        const wiki_source = getSource(WIKIPEDIA, props.result.sources)
        if (!wiki_source) return

        const imageUrl = await wikiService.getImageUrlForSource(wiki_source.identifier)
        if (!imageUrl) return

        setImageUrl(imageUrl)
    }

    useEffect(() => {
        getSearchResultImage()
        // eslint-disable-next-line
    }, [imageBlock, props.result.sources])

    const matches = match(props.result.name, props.value)
    const parts = parse(props.result.name, matches)

    if (props.selected === true) {
        const currentResultRef = resultRef.current

        if (currentResultRef !== null) {
            currentResultRef.scrollIntoView({
                block: "nearest",
                behavior: "smooth",
                inline: "nearest",
            })
        }
    }

    if (props.result?.isGenerateSummaryOption === true) {
        return <SummarizeSearchResult url={props.result.name} />
    }

    if (props.result?.isCreateOption === true) {
        return (
            <Box
                sx={{
                    py: 0.5,
                    px: 2,
                    fontSize: "1rem",
                }}
                ref={resultRef}
            >
                Create empty card named{" "}
                <b>"{truncate(props.result.name, { length: 35, omission: "..." })}"</b>
            </Box>
        )
    }

    return (
        <Grid
            ref={resultRef}
            container
            alignItems="center"
            className={classes.root}
            wrap={"nowrap"}
        >
            <Grid item>
                <Avatar
                    alt={props.result.name}
                    children={props.result.name[0]}
                    variant="rounded"
                    className={classes.image}
                    src={imageUrl ? imageUrl : undefined}
                />
            </Grid>
            <Grid item xs>
                {parts.map((part, index: number) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                    </span>
                ))}
                {description && (
                    <TypographyTruncated
                        variant="body2"
                        color="textSecondary"
                        className={classes.root}
                        text={description}
                        length={30}
                    />
                )}
            </Grid>
            <Grid item>
                {props.result.isSaved && <SavedBadge isReference={props.result.isReference} />}
            </Grid>
        </Grid>
    )
}

export default SearchResultComp

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontFamily: theme.typography.secondaryFontFamily,
        },
        image: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            marginRight: theme.spacing(1),
            backgroundColor: alpha("#FFFFFF", 0.9),
        },
    })
)
