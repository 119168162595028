import { createPluginFactory } from "@udecode/plate-common"

export const YOUTUBE_TIMESTAMP = "youtube-timestamp"

export const createYouTubeTimestampPlugin = createPluginFactory({
    key: YOUTUBE_TIMESTAMP,
    isElement: true,
    isInline: true,
    isVoid: true,
    isMarkableVoid: true,
})
