import { PushPinOutlined } from "@mui/icons-material"
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import { useDatabase } from "@nozbe/watermelondb/react"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { isCardShared } from "storage/redux/user/selector"
import { ItemModel } from "storage/watermelon/models"
import { tagRepository } from "storage/watermelon/repository"
import { truncateText } from "utils/Strings"
import { MentionCountBadge } from "../badges/MentionCountBadge"
import { SharedBadge } from "../badges/SharedBadge"
import { RERENDER_TAGS_EVENT } from "../tags/hooks/useGroupedTags"
import { DescriptionComp } from "./components/DescriptionComp"
import { PlaceHolderImage } from "./components/FirstImageComp"
import MentionedDetailsComp from "./components/MentionedDetailsComp"
import { Tags } from "./components/Tags"

export const ITEM_PARTIAL_DIV_ID = "id-item-partial-div"

interface Props {
    item: ItemModel
    onClick?: Function
    showMentionsOf?: string
    mentionCountBadgeProps?: {
        onlyOtherMentionCount?: boolean
    }
    style?: {
        image?: SxProps<Theme>
        paper?: SxProps<Theme>
    }
    showTags?: boolean
    hidePin?: boolean
    maxTitleLength?: number
}

const DEFAULT_MAX_TITLE_LENGTH = 20

const ItemPartialComp: React.FC<Props> = ({
    onClick,
    item,
    maxTitleLength = DEFAULT_MAX_TITLE_LENGTH,
    style,
    showTags,
    showMentionsOf,
    mentionCountBadgeProps,
    hidePin,
}) => {
    const db = useDatabase()
    const [isPinned, setIsPinned] = useState(false)
    const isShared = useSelector((state: RootState) => isCardShared(state, item.id))
    const [showPlaceholder, setShowPlaceholder] = useState(false)

    const handleOnClick = async () => {
        if (onClick) {
            await onClick(item)
        }
    }

    const isTruncated = item.name.length > maxTitleLength

    const handlePin = async (e, item: ItemModel) => {
        e.preventDefault()
        e.stopPropagation()
        setIsPinned(true)
        await item.setIsReference(!item.isReference)
        const tags = await item.tags.fetch()
        await tagRepository.updateTagsReferenceState(db, tags, item)
        document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
    }

    return (
        <Paper
            id={ITEM_PARTIAL_DIV_ID}
            elevation={0}
            sx={
                style?.paper
                    ? ({
                          ...styles.paper,
                          ...style.paper,
                      } as SxProps<Theme>)
                    : styles.paper
            }
            onClick={handleOnClick}
        >
            <Box display="flex" justifyContent="space-between" height="100%">
                <Box display="flex" flexDirection="column" width="100%">
                    {item.image && !showPlaceholder ? (
                        <Box
                            component="img"
                            alt={item.name}
                            sx={styles.image}
                            src={item.image}
                            onError={() => {
                                setShowPlaceholder(true)
                            }}
                        />
                    ) : (
                        <PlaceHolderImage sx={styles.image} />
                    )}
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        flex={1}
                        p={1}
                    >
                        <Box>
                            <Tooltip title={isTruncated ? item.name : undefined}>
                                <Typography pb={0.5} sx={styles.name} variant="h6">
                                    {truncateText(item.name, maxTitleLength)}
                                </Typography>
                            </Tooltip>
                            <DescriptionComp description={item.description} />
                        </Box>
                        <Box pt={0.5} display="flex" alignItems="flex-end">
                            {showTags && <Tags tags={item.tags} />}
                        </Box>
                    </Box>
                </Box>
                {showMentionsOf && (
                    <Grid container>
                        <MentionedDetailsComp
                            editorOrders={item.editorOrders}
                            editorBlocks={item.editorBlocks}
                            showMentionsOf={showMentionsOf}
                        />
                    </Grid>
                )}
            </Box>

            <Box sx={{ ...styles.mentions, [hidePin ? "top" : "bottom"]: 2 }}>
                {!hidePin && !isPinned && item.isReference && (
                    <Tooltip
                        title={
                            <Typography variant="body2">
                                <b>Pin</b> this card
                            </Typography>
                        }
                    >
                        <Box sx={styles.pin} onClick={(e) => handlePin(e, item)}>
                            <PushPinOutlined sx={styles.pinIcon} />
                        </Box>
                    </Tooltip>
                )}
                <MentionCountBadge item={item} {...mentionCountBadgeProps} />
            </Box>
            {isShared && (
                <Box sx={{ position: "absolute", top: 8, left: 8 }}>
                    <SharedBadge />
                </Box>
            )}
        </Paper>
    )
}

export default React.memo(ItemPartialComp)

const styles: Record<string, SxProps<Theme>> = {
    content: {
        py: 1,
        px: 1,
    },
    flexGrow: {
        flexGrow: 1,
    },
    name: {
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        lineHeight: 1.2,
        wordBreak: "break-word",
        fontSize: "1rem",
        fontWeight: 500,
    },
    marginRight: {
        mr: (theme) => theme.spacing(3),
    },
    paper: {
        height: "100%",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        border: "1px solid rgba(255,255,255,0.06)",
        backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "rgba(255,255,255,0.06)" : theme.palette.action.hover,
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        "&:hover": {
            opacity: 0.9,
        },
    },
    mentions: {
        display: "flex",
        position: "absolute",
        right: 4,
        columnGap: 0.4,
        height: 30,
    },
    pin: {
        color: (theme) => theme.palette.grey[200],
    },
    pinIcon: {
        p: 0.3,
        transform: "rotate(45deg)",
        border: (theme) => `2px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: "50%",
        background: (theme) => theme.palette.action.disabledBackground,
        color: (theme) => theme.palette.text.secondary,
        transition: "transform 0.2s",
        "&:hover": {
            transform: "scale(1.05)",
            background: (theme) => theme.palette.action.selected,
        },
    },
    image: {
        height: "140px",
        objectFit: "cover",
        width: "100%",
        mb: 0.5,
        backgroundColor: "action.hover",
    },
}
