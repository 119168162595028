import { Box, Divider, SxProps, Theme, alpha } from "@mui/material"

import { Stack } from "@mui/material"
import { MarkButtons } from "./MarkButtons"
import { TextTypeMenu } from "./TextTypeMenu"

import { IconList, IconListNumbers } from "@tabler/icons-react"
import { MARK_BG_COLOR, MARK_COLOR } from "@udecode/plate-font"
import { ListStyleType } from "@udecode/plate-indent-list"
import { FC } from "react"
import { ItemModel } from "storage/watermelon/models"
import { ColorDropDownMenu } from "../ColorPicker/ColorDropDownMenu"
import { EmojiDropDownMenu } from "../EmojiPicker/EmojiDropDownMenu"
import { AlignToolbarButton } from "./AlignToolbarButton"
import { ImageToolbarButton } from "./ImageToolbarButton"
import { IndentListToolbarButton } from "./IndentListToolbarButton"
import { LinkToolbarButton } from "./LinkToolbarButton"

interface Props {
    item: ItemModel
}

export const FixedToolbar: FC<Props> = ({ item }) => {
    return (
        <Box sx={styles.toolbar}>
            <Stack
                spacing={0.4}
                direction="row"
                justifyContent="center"
                alignItems="center"
                onMouseDown={(e) => {
                    e.preventDefault()
                }}
            >
                <TextTypeMenu isOpen={true} setIsOpen={() => {}} />
                <MarkButtons />
                <LinkToolbarButton />
                <ColorDropDownMenu tooltip="Text Color" nodeType={MARK_COLOR} />
                <ColorDropDownMenu tooltip="Highlight Color" nodeType={MARK_BG_COLOR} />
                <Box px={0.5} height={20}>
                    <Divider orientation="vertical" />
                </Box>
                <AlignToolbarButton />
                <IndentListToolbarButton nodeType={ListStyleType.Disc}>
                    <IconList />
                </IndentListToolbarButton>
                <IndentListToolbarButton nodeType={ListStyleType.Decimal}>
                    <IconListNumbers />
                </IndentListToolbarButton>
                <Box px={0.5} height={20}>
                    <Divider orientation="vertical" />
                </Box>
                <ImageToolbarButton item={item} />
                <EmojiDropDownMenu />
            </Stack>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    toolbar: {
        position: "sticky",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1001,
        p: 1,
        borderRadius: 1,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        background: (theme) => alpha(theme.palette.background.layout, 0.8),
        backdropFilter: "blur(2px)",
    },
}
