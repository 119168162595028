import { getMark, usePlateEditorState } from "@udecode/plate-common"
import { usePlateEditorRef } from "@udecode/plate-core"
import { TEditor, Value } from "@udecode/slate"
import { focusEditor } from "@udecode/slate-react"
import { removeMark } from "@udecode/slate-utils"
import { isDefined } from "@udecode/utils"

import { withoutNormalizing } from "@udecode/slate"
import castArray from "lodash/castArray"

export interface ToggleMarkOptions {
    clear?: string | string[]
    key: string
}

export const isMarkActive = <V extends Value>(editor: TEditor<V>, type: string) => {
    const mark = getMark(editor, type)
    return Boolean(isDefined(mark) && mark)
}

export const useMarkToolbarButtonState = ({
    nodeType,
    clear,
}: {
    nodeType: string
    clear?: string | string[]
}) => {
    const editor = usePlateEditorState()
    const pressed = !!editor?.selection && isMarkActive(editor, nodeType)

    return {
        pressed,
        nodeType,
        clear,
    }
}

export const useMarkToolbarButton = (state: ReturnType<typeof useMarkToolbarButtonState>) => {
    const editor = usePlateEditorRef()

    return {
        props: {
            pressed: state.pressed,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                e.stopPropagation()

                toggleMark(editor, { key: state.nodeType, clear: state.clear })
                focusEditor(editor)
            },
        },
    }
}

export const toggleMark = <V extends Value = Value>(
    editor: TEditor<V>,
    { key, clear }: ToggleMarkOptions
) => {
    if (!editor.selection) return

    withoutNormalizing(editor, () => {
        const isActive = isMarkActive(editor, key)

        if (isActive) {
            removeMark(editor, { key })
            return
        }

        if (clear) {
            const clears = castArray<string>(clear)
            removeMark(editor, { key: clears })
        }

        editor.addMark(key, true)
    })
}
