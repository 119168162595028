import { Database } from "@nozbe/watermelondb"
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs"
import migrations from "./migrations"
import {
    AssetModel,
    ConnectionModel,
    ConnectionPropertyModel,
    EditorBlockModel,
    EditorOrderModel,
    ItemModel,
    ItemTagModel,
    QuestionReviewModel,
    SourceModel,
    TagModel,
    TypeModel,
} from "./models"
import { DataMigrationModel } from "./models/DataMigrationModel"
import { QuestionModel } from "./models/QuestionModel"
import { schema } from "./schema"

const adapter = new LokiJSAdapter({
    schema,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    migrations,
    dbName: "recall",
})

export const database = new Database({
    adapter,
    modelClasses: [
        ItemModel,
        TypeModel,
        SourceModel,
        ConnectionModel,
        EditorBlockModel,
        EditorOrderModel,
        ConnectionPropertyModel,
        TagModel,
        ItemTagModel,
        QuestionModel,
        QuestionReviewModel,
        DataMigrationModel,
        AssetModel,
    ],
})
