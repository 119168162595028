import { useTheme } from "@mui/material"
import { ResizeHandle as ResizeHandlePrimitive, ResizeHandleProps } from "@udecode/plate-resizable"
import React, { FC } from "react"

export const ResizeHandle: FC<ResizeHandleProps> = ({ options }) => {
    const theme = useTheme()

    const styles: Record<string, React.CSSProperties> = {
        resizeHandle: {
            width: 4,
            height: 70,
            backgroundColor: theme.palette.text.primary,
            cursor: "col-resize",
            margin: 6,
            borderRadius: 20,
            opacity: 0.9,
        },
    }

    return (
        <ResizeHandlePrimitive
            className="resize-handler"
            style={styles.resizeHandle}
            options={options}
        />
    )
}
