import { Model, Relation } from "@nozbe/watermelondb"
import { field, relation, writer } from "@nozbe/watermelondb/decorators"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { ItemModel } from "./ItemModel"

export class SourceModel extends Model {
    static table = "sources"

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @relation("items", "item_id") item: Relation<ItemModel>
    @field("name") name: string
    @field("identifier") identifier: string

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: SourceModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        // @ts-ignore
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }

    toSource = () => ({
        id: this.id,
        name: this.name,
        identifier: this.identifier,
    })
}
