import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, json, relation, writer } from "@nozbe/watermelondb/decorators"
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "components/ItemPage/components/editor/plugins/editor-block"
import { ELEMENT_CUSTOM_PARAGRAPH } from "components/ItemPage/components/editor/plugins/paragraph"
import { MyRootBlock } from "components/ItemPage/components/editor/types"
import { EditorBlockData } from "services/editorData/EditorBlockData"
import { ItemModel } from "./ItemModel"

const sanitizeEditorBlock = (data) => {
    return data
}

const sanitizeOptions = (data) => {
    return data
}

export class EditorBlockModel extends Model {
    static table = "editor_blocks"

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("type") type: string
    @relation("items", "item_id") item: Relation<ItemModel>
    @json("children", sanitizeEditorBlock) children: object
    @json("options", sanitizeOptions) options: object
    @field("text") text: string

    prepareSave = () => {
        if (this.isSaved === false) {
            const text = EditorBlockData.getFormattedText(this.children)

            return this.prepareUpdate((record: EditorBlockModel) => {
                record.isSaved = true
                record.text = text
            })
        }

        return null
    }

    isOnlyChildParagraph = () => {
        // @ts-ignore
        return this.children?.length === 1 && this.children?.[0]?.type === ELEMENT_CUSTOM_PARAGRAPH
    }

    toEditorBlock = () => {
        let children = this?.children
        const isEditorBlockElement = !this.type || this.type === ELEMENT_CUSTOM_EDITOR_BLOCK

        if (isEditorBlockElement && this.isOnlyChildParagraph()) children = children[0].children

        return {
            id: this.id,
            children,
            type: this.type || ELEMENT_CUSTOM_EDITOR_BLOCK,
            options: this.options,
            ...(this.options || {}),
        } as MyRootBlock
    }

    prepareDelete = () => {
        // @ts-ignore
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }

    @writer async updateUrl(url: string) {
        await this.update((record) => {
            record.options = record.options ? { ...record.options, url } : { url }
        })
    }

    @writer async updateChildren(children) {
        await this.update((record) => {
            record.children = children
        })
    }
}
